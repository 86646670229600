import { take, put, race, fork } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  ADD_GROUP_SUCCESS,
  REMOVE_PRODUCT_FROM_GROUP_SUCCESS,
  ADD_PRODUCT_TO_GROUP_SUCCESS,
  ADD_PRODUCT_TO_GROUP_BULK_SUCCESS,
  getGroup
} from '../actions';

export function* groupAddSaga() {
  yield take(ADD_GROUP_SUCCESS);
  yield put(push('/groups/list'));
}

export function* groupEditSaga() {
  while (true) {
    const { remove, add, addBulk } = yield race({
      remove: take(REMOVE_PRODUCT_FROM_GROUP_SUCCESS),
      add: take(ADD_PRODUCT_TO_GROUP_SUCCESS),
      addBulk: take(ADD_PRODUCT_TO_GROUP_BULK_SUCCESS)
    });
    if (remove) {
      yield put(getGroup({ id: remove.meta.id }));
    }
    if (add) {
      yield put(getGroup({ id: add.meta.id }));
    }
    if (addBulk) {
      yield put(getGroup({ id: addBulk.meta.id }));
    }
  }
}

export default {
  groupAddSaga
};
