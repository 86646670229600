import { take, put, race } from 'redux-saga/effects';
import { getProductsImport, ADD_PRODUCTS_IMPORT_SUCCESS } from './actions';

export function* productsImportSaga() {
  while (true) {
    const { success } = yield race({
      success: take(ADD_PRODUCTS_IMPORT_SUCCESS)
    });
    if (success) {
      yield put(getProductsImport({}));
    }
  }
}

export default productsImportSaga;
