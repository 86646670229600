import { AnyActionType } from 'types/index';

import {
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  EDIT_CATEGORY_SUCCESS,
  UPLOAD_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS,
  SET_MAIN_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS
} from './actions';

const INITIAL_STATE: any = {
  categories: [],
  currentCategory: { image: {} },
  loadingCurrentCategory: false,
  error: null,
  paginator: {},
  loading: false,
  uploadId: undefined,
  metadata: {
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }],
    searchBy: [{ value: 'id', name: 'id' }, { value: 'title', name: 'title' }]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentCategory: INITIAL_STATE.currentCategory,
        loadingCurrentCategory: INITIAL_STATE.loadingCurrentCategory,
        uploadId: INITIAL_STATE.uploadId
      };
    case GET_CATEGORIES_REQUEST:
      return { ...state, categories: [], loading: true };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.content.items,
        error: null
      };
    case GET_CATEGORIES_FAILURE:
      return { ...state, loading: false };
    case GET_CATEGORY_REQUEST:
      return { ...state, loadingCurrentCategory: true };
    case GET_CATEGORY_FAILURE:
      return { ...state, loadingCurrentCategory: false };
    case GET_CATEGORY_SUCCESS:
    case EDIT_CATEGORY_SUCCESS:
      return { ...state, currentCategory: action.payload.content, loadingCurrentCategory: false };
    case UPLOAD_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS:
      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          image: action.payload.image.image
        },
        uploadId: action.payload.image.id
      };
    case SET_MAIN_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS:
      return {
        ...state,
        currentCategory: { ...state.currentCategory, image: action.payload.image.image },
        uploadId: action.payload.image.id
      };
    default:
      return state;
  }
}
