import { AnyActionType } from 'types/index';

import {
  GET_APP_SETTINGS_REQUEST,
  GET_APP_SETTINGS_SUCCESS,
  GET_APP_SETTINGS_FAILURE,
  EDIT_APP_SETTINGS_SUCCESS
} from './actions';

const INITIAL_STATE: any = {
  settings: [],
  loading: false,
  sectionTitles: {
    loyalty: 'Loyalty Settings',
    client_force_update: 'App Settings',
    content_page: 'Content Pages',
    content_email: 'E-Mails'
  },
  tooltips: {
    open_app: 'Number of LP that client gets for daily app open.',
    sign_up: 'Number of LP that client gets for registration.',
    cashback:
      'Ratio (in %) between price paid by the client (in Euro) and the number of LP that clients receives as cashback. For example, if client spends 10 Euros and cashback ratio is 200% - client gets 20 LP for this transaction.',
    open_app_interval:
      'Minimal time period (in hours) between 2 app opens for which client gets LP.',
    invitation_usage:
      'Number of LP that inviter gets when e-mail invited by her - is registered in the app.',
    ios_minimum_build:
      'Minimal supported iOS app version. For example, if this parameter is 83 and client has app with version 1.1.82.0 - she needs to update the app to the version 1.1.83.0 or above to be able to use the app.',
    android_minimum_build:
      'Minimal supported Android app version. For example, if this parameter is 500 and client has app with version 1.2.499.0 - she needs to update the app to the version 1.2.500.0 or above to be able to use the app.',
    product_review: 'Number of LP that user gets when her product review is approved',
    subscribe:
      'Number of LP that user gets when she confirms newsletter subscription - by following link in the e-mail'
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case GET_APP_SETTINGS_REQUEST:
      return { ...state, settings: [], loading: true };
    case GET_APP_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload.content.items
      };
    case GET_APP_SETTINGS_FAILURE:
      return { ...state, loading: false };
    case EDIT_APP_SETTINGS_SUCCESS:
      return { ...state };
    default:
      return state;
  }
}
