import { take, put, race } from 'redux-saga/effects';
import { getReviewsImport, ADD_REVIEWS_IMPORT_SUCCESS } from './actions';

export function* reviewsImportSaga() {
  while (true) {
    const { success } = yield race({
      success: take(ADD_REVIEWS_IMPORT_SUCCESS)
    });
    if (success) {
      yield put(getReviewsImport({}));
    }
  }
}

export default { reviewsImportSaga };
