import { AnyActionType } from 'types/index';

import {
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_STORES_FAILURE,
  GET_STORE_REQUEST,
  GET_STORE_SUCCESS,
  GET_STORE_FAILURE,
  SET_SELECTED_STORES_COLLECTION,
  EDIT_STORE_SUCCESS,
  GET_STORE_IMAGES_SUCCESS,
  UPLOAD_IMAGE_FOR_ADD_STORE_FORM_SUCCESS,
  SET_MAIN_IMAGE_FOR_ADD_STORE_FORM_SUCCESS
} from './actions';

const INITIAL_STATE: any = {
  stores: [],
  currentStore: { images: [] },
  loadingCurrentStore: false,
  error: null,
  paginator: {},
  loading: false,
  storeMetadata: {
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }],
    searchBy: [{ value: 'id', name: 'id' }, { value: 'title', name: 'title' }]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentStore: INITIAL_STATE.currentStore,
        loadingCurrentStore: INITIAL_STATE.loadingCurrentStore
      };
    case GET_STORES_REQUEST:
      return { ...state, stores: [], paginator: {}, loading: true };
    case GET_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        stores: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_STORES_FAILURE:
      return { ...state, loading: false };
    case SET_SELECTED_STORES_COLLECTION:
      return { ...state, selectedCollection: action.payload };
    case GET_STORE_REQUEST:
      return { ...state, loadingCurrentStore: true };
    case GET_STORE_FAILURE:
      return { ...state, loadingCurrentStore: false };
    case GET_STORE_SUCCESS:
    case EDIT_STORE_SUCCESS:
      return { ...state, currentStore: action.payload.content, loadingCurrentStore: false };
    case GET_STORE_IMAGES_SUCCESS:
      return {
        ...state,
        currentStore: { ...state.currentStore, images: action.payload.content.items }
      };
    case UPLOAD_IMAGE_FOR_ADD_STORE_FORM_SUCCESS:
      return {
        ...state,
        currentStore: {
          ...state.currentStore,
          images: state.currentStore.images.concat([action.payload])
        }
      };
    case SET_MAIN_IMAGE_FOR_ADD_STORE_FORM_SUCCESS:
      return {
        ...state,
        currentStore: { ...state.currentStore, mainImage: action.payload }
      };
    default:
      return state;
  }
}
