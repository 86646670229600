import { CallApi } from '@factorymarket/mg-react-components';
import { InvitationsRequestType } from './types';

const { callApiAction } = CallApi;

export const GET_USER_INVITATIONS_REQUEST = 'GET_USER_INVITATIONS_REQUEST';
export const GET_USER_INVITATIONS_SUCCESS = 'GET_USER_INVITATIONS_SUCCESS';
export const GET_USER_INVITATIONS_FAILURE = 'GET_USER_INVITATIONS_FAILURE';

export const getUserInvitations = ({ userId }: { userId: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/invitation/list?json={"userId": ${userId}, "perPage": 499, "page": 1}`,
    types: [
      GET_USER_INVITATIONS_REQUEST,
      GET_USER_INVITATIONS_SUCCESS,
      GET_USER_INVITATIONS_FAILURE
    ]
  });

export const DELETE_USER_INVITATIONS_REQUEST = 'DELETE_USER_INVITATIONS_REQUEST';
export const DELETE_USER_INVITATIONS_SUCCESS = 'DELETE_USER_INVITATIONS_SUCCESS';
export const DELETE_USER_INVITATIONS_FAILURE = 'DELETE_USER_INVITATIONS_FAILURE';

export const deleteUserInvitation = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/user/invitation/${id}`,
    types: [
      DELETE_USER_INVITATIONS_REQUEST,
      {
        type: DELETE_USER_INVITATIONS_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Invitation successfully deleted!' }
        }
      },
      DELETE_USER_INVITATIONS_FAILURE
    ]
  });
