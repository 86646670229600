import { AnyActionType } from 'types/index';

import { GET_REVIEWS_REQUEST, GET_REVIEWS_SUCCESS, GET_REVIEWS_FAILURE } from './actions';

const INITIAL_STATE: any = {
  reviews: [],
  error: null,
  paginator: {},
  loading: false,
  reviewsMetadata: {
    statuses: [
      { value: 1, name: 'active' },
      { value: 2, name: 'inactive' },
      { value: 3, name: 'Not confirmed' }
    ]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case GET_REVIEWS_REQUEST:
      return { ...state, loading: true };
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_REVIEWS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
