import { AnyActionType } from 'types/index';

import {
  // GET_VOUCHERS_IMPORT_REQUEST,
  GET_VOUCHERS_IMPORT_SUCCESS,
  GET_VOUCHER_IMPORT_VALIDATION_ERRORS_REQUEST,
  GET_VOUCHER_IMPORT_VALIDATION_ERRORS_SUCCESS
} from './actions';

const INITIAL_STATE = {
  imports: [],
  errors: undefined,
  paginator: {},
  loading: false,
  metadata: {
    statuses: [
      { value: 0, name: 'New' },
      { value: 1, name: 'In progress' },
      { value: 2, name: 'Done' },
      { value: 3, name: 'Failed' }
    ]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        errors: INITIAL_STATE.errors
      };
    // case GET_VOUCHERS_IMPORT_REQUEST:
    //   return {
    //     ...state,
    //     imports: INITIAL_STATE.imports,
    //     paginator: INITIAL_STATE.paginator,
    //     loading: true
    //   };
    case GET_VOUCHERS_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        imports: action.payload.content.items,
        // errors: undefined,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_VOUCHER_IMPORT_VALIDATION_ERRORS_REQUEST:
      return { ...state, errors: undefined };
    case GET_VOUCHER_IMPORT_VALIDATION_ERRORS_SUCCESS:
      return { ...state, errors: action.payload };

    default:
      return state;
  }
}
