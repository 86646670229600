import { CallApi } from '@factorymarket/mg-react-components';
import { GetTokenType, GetAnonymousTokenType, DeviceType } from 'types/auth';
import { CurrentUserType } from 'types/user';
import { clearTokenHeader } from 'utils/api';

const { callApiAction } = CallApi;

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export function loginUser(payload: any) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload
  };
}

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export function logoutUser(error?: any) {
  clearTokenHeader();
  return {
    type: LOGOUT_USER_SUCCESS,
    meta: error && {
      notification: {
        type: 'error',
        message: error.message,
        needTranslate: error.needTranslate
      }
    }
  };
}

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

export const getToken = ({ formData }: { formData: GetTokenType }) =>
  callApiAction({
    method: 'post',
    url: '/token/auth',
    types: [
      GET_TOKEN_REQUEST,
      GET_TOKEN_SUCCESS,
      {
        type: GET_TOKEN_FAILURE,
        meta: {
          notification: {
            type: 'error',
            message: 'The username or password is incorrect',
            needTranslate: true
          }
        }
      }
    ],
    body: {
      ...formData
    }
  });

export const GET_ANONYMOUS_TOKEN_REQUEST = 'GET_ANONYMOUS_TOKEN_REQUEST';
export const GET_ANONYMOUS_TOKEN_SUCCESS = 'GET_ANONYMOUS_TOKEN_SUCCESS';
export const GET_ANONYMOUS_TOKEN_FAILURE = 'GET_ANONYMOUS_TOKEN_FAILURE';

export const getAnonymousToken = ({ formData }: { formData: GetAnonymousTokenType }) =>
  callApiAction({
    method: 'post',
    url: '/token/anonymous',
    types: [GET_ANONYMOUS_TOKEN_REQUEST, GET_ANONYMOUS_TOKEN_SUCCESS, GET_ANONYMOUS_TOKEN_FAILURE],
    body: {
      ...formData
    }
  });

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const refreshToken = ({ formData }: any) =>
  callApiAction({
    method: 'post',
    url: '/token/refresh',
    types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE],
    body: {
      ...formData
    }
  });

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILRUE';

export const updateUser = ({ id, ...formData }: CurrentUserType) =>
  callApiAction({
    method: 'put',
    url: `/user/${id}`,
    types: [
      UPDATE_USER_REQUEST,
      {
        type: UPDATE_USER_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            message: 'userUpdated',
            needTranslate: true
          }
        }
      },
      UPDATE_USER_FAILURE
    ],
    body: formData
  });

export const ACCESS_DENIED = 'ACCESS_DENIED';

export const accessDenied = (payload: any) => ({
  type: ACCESS_DENIED,
  payload
});

export const SET_DEVICE = 'SET_DEVICE';

export const setDevice = (device: DeviceType) => ({
  type: SET_DEVICE,
  payload: device
});

export const CHANGE_LANG = 'CHANGE_LANG';

export const changeLang = (lang: string) => ({
  type: CHANGE_LANG,
  payload: lang
});
