import { AnyActionType } from 'types/index';

import {
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGN_REQUEST,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  EDIT_CAMPAIGN_REQUEST,
  EDIT_CAMPAIGN_SUCCESS,
  EDIT_CAMPAIGN_FAILURE,
  ADD_CAMPAIGN_REQUEST,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAILURE
} from './actions';

const INITIAL_STATE: any = {
  campaigns: [],
  currentCampaign: {},
  loadingCurrentCampaign: false,
  error: null,
  paginator: {},
  loading: false,
  campaignMetadata: {
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentCampaign: INITIAL_STATE.currentCampaign,
        loadingCurrentCampaign: INITIAL_STATE.loadingCurrentCampaign
      };
    case EDIT_CAMPAIGN_REQUEST:
    case ADD_CAMPAIGN_REQUEST:
      return { ...state, loadingCurrentCampaign: true };
    case EDIT_CAMPAIGN_FAILURE:
    case ADD_CAMPAIGN_FAILURE:
    case ADD_CAMPAIGN_SUCCESS:
      return { ...state, loadingCurrentCampaign: false };
    case GET_CAMPAIGNS_REQUEST:
      return { ...state, campaigns: [], paginator: {}, loading: true };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_CAMPAIGNS_FAILURE:
      return { ...state, loading: false };
    case GET_CAMPAIGN_REQUEST:
      return { ...state, loadingCurrentCampaign: true };
    case GET_CAMPAIGN_FAILURE:
      return { ...state, loadingCurrentCampaign: false };
    case GET_CAMPAIGN_SUCCESS:
    case EDIT_CAMPAIGN_SUCCESS:
      return { ...state, currentCampaign: action.payload.content, loadingCurrentCampaign: false };
    default:
      return state;
  }
}
