import { take, put, race } from 'redux-saga/effects';
import { getProductsAlsoBoughtImport, ADD_PRODUCTS_ALSO_BOUGHT_IMPORT_SUCCESS } from './actions';

export function* productsImportAlsoBoughtSaga() {
  while (true) {
    const { success } = yield race({
      success: take(ADD_PRODUCTS_ALSO_BOUGHT_IMPORT_SUCCESS)
    });
    if (success) {
      yield put(getProductsAlsoBoughtImport({}));
    }
  }
}

export default productsImportAlsoBoughtSaga;
