import axios from 'axios';
import { API_URL_V1 } from 'config/env';

const instance = axios.create({
  baseURL: API_URL_V1
});

instance.defaults.headers['Content-Type'] = 'application/json';

const handleSuccess = (response: any) => response;

const handleError = (error: any) => {
  console.log(error);
  // if (isEnvironment('production')) sentry.errorResponseCapture(error);
  return Promise.reject(error);
};

instance.interceptors.response.use(handleSuccess, handleError);

export default instance;

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearTokenHeader = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const setLangHeader = (langCode: string) => {
  instance.defaults.headers.common['Accept-Language'] = `${langCode};q=1.0'`;
};

export const removeToken = () => {
  delete instance.defaults.headers.common.Authorization;
};
