import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterRootState } from 'connected-react-router';
import { localizeReducer } from 'react-localize-redux';
import { UniversalPageContainer } from 'redux/containers/UniversalPageContainer';

import loginForm from 'views/page/Login/reducer';
import restorePassword from 'views/page/Password/reducer';

import usersReducer from 'views/page/Users/reducer';
import { UsersReducerType } from 'views/page/Users/types';

import userSegmentsReducer from 'views/page/UserSegments/reducer';
import userSegmentsImportReducer from 'views/page/UserSegments/Import/reducer';
import productsReducer from 'views/page/Products/reducer';
import productsImportReducer from 'views/page/Products/Import/reducer';
import productsAlsoBoughtImportReducer from 'views/page/Products/ImportAlsoBought/reducer';
import storesReducer from 'views/page/Stores/reducer';
import CategoriesReducer from 'views/page/Categories/reducer';
import bannersReducer from 'views/page/Banners/reducer';
import notificationsReducer from 'views/page/Notifications/reducer';
import journalReducer from 'views/page/Journal/reducer';
import vouchersReducer from 'views/page/Vouchers/reducer';
import termsReducer from 'views/page/VoucherTerms/reducer';
import pimImportsReducer from 'views/page/PimImport/reducer';
import pimImportMessageReducer from 'views/page/PimImport/Messages/reducer';
import vouchersImportReducer from 'views/page/Vouchers/Import/reducer';
import campaignsReducer from 'views/page/Campaigns/reducer';
import reviewsReducer from 'views/page/Reviews/reducer';
import reviewsImportReducer from 'views/page/Reviews/Import/reducer';
import appSettingsReducer from 'views/page/AppSettings/reducer';
import GroupsReducer from 'views/page/Groups/reducer';
import config from 'redux/modules/config/reducer';
import FormModal from 'redux/containers/FormModal';
import DashboardReducer from 'views/page/Dashboard/reducer';
import auth from './auth';

const { formModalReducer } = FormModal;

export default (history: any) =>
  // TODO write types for all reducers
  combineReducers<RouterRootState | UsersReducerType | any>({
    router: connectRouter(history),
    universalPageReducer: UniversalPageContainer.Reducer,
    auth,
    loginForm,
    restorePassword,
    config,
    usersReducer,
    userSegmentsReducer,
    userSegmentsImportReducer,
    productsReducer,
    productsImportReducer,
    storesReducer,
    CategoriesReducer,
    bannersReducer,
    notificationsReducer,
    journalReducer,
    vouchersReducer,
    termsReducer,
    pimImportsReducer,
    pimImportMessageReducer,
    reviewsReducer,
    reviewsImportReducer,
    appSettingsReducer,
    formModalReducer,
    campaignsReducer,
    GroupsReducer,
    productsAlsoBoughtImportReducer,
    vouchersImportReducer,
    localize: localizeReducer,
    DashboardReducer
  });
