import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GET_USER_VOUCHERS_REQUEST,
  GET_USER_VOUCHERS_SUCCESS,
  GET_USER_VOUCHERS_FAILURE,
  GET_USER_TRANSACTIONS_REQUEST,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAILURE
} from 'views/page/Vouchers/actions';
import { UsersActionType, UsersReducerType } from './types';

import {
  GET_USER_INVITATIONS_SUCCESS,
  GET_USER_INVITATIONS_REQUEST,
  GET_USER_INVITATIONS_FAILURE
} from './Invitation/actions';

import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  SET_SELECTED_USERS_COLLECTION,
  EDIT_USER_SUCCESS
} from './actions';

import {
  GET_USER_EMAILS_REQUEST,
  GET_USER_EMAILS_SUCCESS,
  GET_USER_EMAILS_FAILURE
} from './Email/actions';

const INITIAL_STATE = {
  users: [],
  currentUser: {},
  currentUserVouchers: [],
  currentUserVouchersLoading: false,
  currentUserEmails: [],
  currentUserEmailsLoading: false,
  currentUserTransactions: [],
  currentUserInvitations: [],
  currentUserTransactionsPagination: {},
  currentUserTransactionsLoading: false,
  currentUserInvitationsLoading: false,
  loadingCurrentUser: false,
  paginator: {},
  loading: false,
  selectedCollection: undefined
};

export default function(state = INITIAL_STATE, action: UsersActionType): UsersReducerType {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentUser: INITIAL_STATE.currentUser,
        loadingCurrentUser: INITIAL_STATE.loadingCurrentUser
      };
    case LOCATION_CHANGE:
      return { ...state, selectedCollection: undefined };
    case GET_USERS_REQUEST:
      return { ...state, users: [], paginator: {}, loading: true };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.content.items,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_USERS_FAILURE:
      return { ...state, loading: false };
    case SET_SELECTED_USERS_COLLECTION:
      return { ...state, selectedCollection: action.payload };
    case GET_USER_REQUEST:
    case GET_USER_FAILURE:
      return { ...state, loadingCurrentUser: false };
    case GET_USER_SUCCESS:
    case EDIT_USER_SUCCESS:
      return { ...state, currentUser: action.payload.content.user, loadingCurrentUser: false };
    case GET_USER_VOUCHERS_REQUEST:
      return { ...state, currentUserVouchersLoading: true };
    case GET_USER_VOUCHERS_SUCCESS:
      return {
        ...state,
        currentUserVouchers: action.payload.content.items,
        currentUserVouchersLoading: false
      };
    case GET_USER_EMAILS_REQUEST:
      return { ...state, currentUserEmailsLoading: true };
    case GET_USER_EMAILS_SUCCESS:
      return {
        ...state,
        currentUserEmails: action.payload.content.items,
        currentUserEmailsLoading: false
      };
    case GET_USER_EMAILS_FAILURE:
      return { ...state, currentUserEmailsLoading: false };
    case GET_USER_TRANSACTIONS_REQUEST:
      return { ...state, currentUserTransactionsLoading: true };
    case GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        currentUserTransactionsLoading: false,
        currentUserTransactions: action.payload.content.items,
        currentUserTransactionsPagination: action.payload.content.paginator
      };
    case GET_USER_TRANSACTIONS_FAILURE:
      return { ...state, currentUserTransactionsLoading: false };
    case GET_USER_INVITATIONS_REQUEST:
      return { ...state, currentUserInvitationsLoading: true };
    case GET_USER_INVITATIONS_SUCCESS:
      return {
        ...state,
        currentUserInvitationsLoading: false,
        currentUserInvitations: action.payload.content.items
      };
    case GET_USER_INVITATIONS_FAILURE:
      return { ...state, currentUserInvitationsLoading: false };
    default:
      return state;
  }
}
