import { take, put, race } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { takeLatest } from 'redux-saga';
import {
  ADD_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_MEDIA_SUCCESS,
  ADD_NOTIFICATION_MEDIA_SUCCESS,
  EDIT_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_MEDIA_MEDIA_SUCCESS,
  getNotification,
  getNotifications
} from './actions';

export function* notificationAddSaga() {
  while (true) {
    const { addNotificationSuccess } = yield race({
      addNotificationSuccess: take(ADD_NOTIFICATION_SUCCESS)
    });
    if (addNotificationSuccess) {
      yield put(push(`/notification/edit/${addNotificationSuccess.payload.content.id}`));
    }
  }
}

export function* notificationSaga() {
  while (true) {
    const { editNotificationSuccess } = yield race({
      editNotificationSuccess: take(EDIT_NOTIFICATION_SUCCESS)
    });
    if (editNotificationSuccess) {
      yield put(getNotifications({ filter: editNotificationSuccess.meta.filter }));
    }
  }
}

export function* notificationEditSaga({ id }: { id: string }) {
  while (true) {
    const {
      editNotificationMediaSuccess,
      addNotificationMediaSuccess,
      deleteNotificationMediaSuccess
    } = yield race({
      editNotificationMediaSuccess: take(EDIT_NOTIFICATION_MEDIA_SUCCESS),
      addNotificationMediaSuccess: take(ADD_NOTIFICATION_MEDIA_SUCCESS),
      deleteNotificationMediaSuccess: take(DELETE_NOTIFICATION_MEDIA_MEDIA_SUCCESS)
    });
    if (
      editNotificationMediaSuccess ||
      addNotificationMediaSuccess ||
      deleteNotificationMediaSuccess
    ) {
      yield put(getNotification({ id }));
    }
  }
}

export function* notificationSendSaga() {
  while (true) {
    const { sendNotificationSuccess } = yield race({
      sendNotificationSuccess: take(SEND_NOTIFICATION_SUCCESS)
    });
    if (sendNotificationSuccess) {
      yield put(getNotifications({ filter: sendNotificationSuccess.meta.filter }));
    }
  }
}

export default { notificationAddSaga, notificationEditSaga, notificationSendSaga };
