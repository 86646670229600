import { AnyActionType } from 'types/index';

import {
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAILURE,
  GET_GROUP_REQUEST,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  EDIT_GROUP_SUCCESS
} from './actions';

const INITIAL_STATE: any = {
  groups: [],
  currentGroup: { image: {} },
  loadingCurrentGroup: false,
  error: null,
  paginator: {},
  loading: false,
  uploadId: undefined,
  metadata: {
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }],
    searchBy: [{ value: 'id', name: 'id' }, { value: 'title', name: 'title' }]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentGroup: INITIAL_STATE.currentGroup,
        loadingCurrentGroup: INITIAL_STATE.loadingCurrentGroup,
        uploadId: INITIAL_STATE.uploadId
      };
    case GET_GROUPS_REQUEST:
      return { ...state, groups: [], loading: true };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.payload.content.items,
        error: null
      };
    case GET_GROUPS_FAILURE:
      return { ...state, loading: false };
    case GET_GROUP_REQUEST:
      return { ...state, loadingCurrentGroup: true };
    case GET_GROUP_FAILURE:
      return { ...state, loadingCurrentGroup: false };
    case GET_GROUP_SUCCESS:
    case EDIT_GROUP_SUCCESS:
      return { ...state, currentGroup: action.payload.content, loadingCurrentGroup: false };
    default:
      return state;
  }
}
