import { query } from 'utils/helper';
import { EditCategoryRequestType, CreateCategoryRequestType } from 'types/category';
import { ImageDataType } from 'types/upload';
import { CallApi } from '@factorymarket/mg-react-components';

const { callApiAction } = CallApi;

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const getCategories = ({ filter }: { filter: any }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/category/tree?json=${JSON.stringify(filter)}`,
    types: [GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILURE]
  });

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';

export const getCategory = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/category/${id}`,
    types: [GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILURE]
  });

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE';

export const editCategory = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: EditCategoryRequestType;
  filter?: object;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product/category/${id}`,
    types: [
      {
        type: EDIT_CATEGORY_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_CATEGORY_SUCCESS,
        meta: { notification: { type: 'success', message: 'Category successfully updated!' } }
      },
      EDIT_CATEGORY_FAILURE
    ],
    body: formData
  });

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const addCategory = ({
  formData,
  filter
}: {
  formData: CreateCategoryRequestType;
  filter?: object;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product/category`,
    types: [
      {
        type: ADD_CATEGORY_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: ADD_CATEGORY_SUCCESS,
        meta: { notification: { type: 'success', message: 'Category successfully added!' } }
      },
      ADD_CATEGORY_FAILURE
    ],
    body: formData
  });

// IMAGES

export const UPLOAD_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS =
  'UPLOAD_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS';
export const SET_MAIN_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS =
  'SET_MAIN_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS';

export const uploadImageForAddCategoryForm = ({
  upload,
  main = false
}: {
  upload: ImageDataType;
  main: boolean;
}) => ({
  type: main
    ? SET_MAIN_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS
    : UPLOAD_IMAGE_FOR_ADD_CATEGORY_FORM_SUCCESS,
  payload: upload
});

export const SET_SELECTED_STORES_COLLECTION = 'SET_SELECTED_STORES_COLLECTION';

export const setSelectedStoresCollection = (collection: number[]) => ({
  type: SET_SELECTED_STORES_COLLECTION,
  payload: collection
});
