import {
  CurrentUserType,
  CreateLoyaltyTransactionRequestType,
  CreateBindingUserToEntityRequestType,
  UnbindingUserToEntityRequestType
} from 'types/user';
import { CallApi } from '@factorymarket/mg-react-components';
import { filterToApi, addUserRequestToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const getUsers = ({ filter }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/list?json=${JSON.stringify(filter && filterToApi(filter)).replace(
      /\+/g,
      '%2b'
    )}`,
    types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE]
  });

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const getUser = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/${id}`,
    types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE]
  });

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const deleteUser = ({ id, filter }: { id?: string; filter: uiFilterType }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/user/${id}`,
    types: [
      {
        type: DELETE_USER_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: DELETE_USER_SUCCESS,
        meta: { notification: { type: 'success', message: 'User successfully deleted!' } }
      },
      DELETE_USER_FAILURE
    ]
  });

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const addUser = ({ formData }: { formData: CurrentUserType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/user/add`,
    types: [
      ADD_USER_REQUEST,
      {
        type: ADD_USER_SUCCESS,
        meta: { notification: { type: 'success', message: 'User successfully added!' } }
      },
      ADD_USER_FAILURE
    ],
    body: addUserRequestToApi(formData)
  });

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const editUser = ({
  userId,
  formData,
  filter
}: {
  userId: string;
  formData: CurrentUserType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/user/${userId}`,
    types: [
      {
        type: EDIT_USER_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_USER_SUCCESS,
        meta: { notification: { type: 'success', message: 'User successfully updated!' } }
      },
      EDIT_USER_FAILURE
    ],
    body: formData
  });

export const SET_SELECTED_USERS_COLLECTION = 'SET_SELECTED_USERS_COLLECTION';

export const setSelectedUsersCollection = (collection: number[]) => ({
  type: SET_SELECTED_USERS_COLLECTION,
  payload: collection
});

export const EDIT_USER_POINTS_REQUEST = 'EDIT_USER_POINTS_REQUEST';
export const EDIT_USER_POINTS_SUCCESS = 'EDIT_USER_POINTS_SUCCESS';
export const EDIT_USER_POINTS_FAILURE = 'EDIT_USER_POINTS_FAILURE';

export const editUserPoints = ({ formData }: { formData: CreateLoyaltyTransactionRequestType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/transactions`,
    types: [
      EDIT_USER_POINTS_REQUEST,
      {
        type: EDIT_USER_POINTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Points successfully updated!' } }
      },
      EDIT_USER_POINTS_FAILURE
    ],
    body: formData
  });

export const BIND_USER_SEGMENT_TO_USER_REQUEST = 'BIND_USER_SEGMENT_TO_USER_REQUEST';
export const BIND_USER_SEGMENT_TO_USER_SUCCESS = 'BIND_USER_SEGMENT_TO_USER_SUCCESS';
export const BIND_USER_SEGMENT_TO_USER_FAILURE = 'BIND_USER_SEGMENT_TO_USER_FAILURE';
export const BIND_USER_SEGMENT_TO_USER = 'BIND_USER_SEGMENT_TO_USER';

export const bindUserSegmentToUser = ({
  id,
  formData
}: {
  id: number;
  formData: CreateBindingUserToEntityRequestType;
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/user/${id}/binding`,
        types: [
          BIND_USER_SEGMENT_TO_USER_REQUEST,
          BIND_USER_SEGMENT_TO_USER_SUCCESS,
          BIND_USER_SEGMENT_TO_USER_FAILURE
        ],
        body: formData
      })
    : {
        type: BIND_USER_SEGMENT_TO_USER,
        payload: formData
      };

export const UNBIND_USER_SEGMENT_TO_USER_REQUEST = 'UNBIND_USER_SEGMENT_TO_USER_REQUEST';
export const UNBIND_USER_SEGMENT_TO_USER_SUCCESS = 'UNBIND_USER_SEGMENT_TO_USER_SUCCESS';
export const UNBIND_USER_SEGMENT_TO_USER_FAILURE = 'UNBIND_USER_SEGMENT_TO_USER_FAILURE';
export const UNBIND_USER_SEGMENT_TO_USER = 'UNBIND_USER_SEGMENT_TO_USER';

export const unbindUserSegmentToUser = ({
  id,
  formData
}: {
  id: number;
  formData: UnbindingUserToEntityRequestType;
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/user/${id}/unbinding`,
        types: [
          UNBIND_USER_SEGMENT_TO_USER_REQUEST,
          UNBIND_USER_SEGMENT_TO_USER_SUCCESS,
          UNBIND_USER_SEGMENT_TO_USER_FAILURE
        ],
        body: formData
      })
    : {
        type: UNBIND_USER_SEGMENT_TO_USER,
        payload: formData
      };
