import { LOCATION_CHANGE } from 'connected-react-router';
import { NotificationsActionType, NotificationsReducerType } from './types';

import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  EDIT_NOTIFICATION_SUCCESS,
  SET_SELECTED_NOTIFICATIONS_COLLECTION
} from './actions';

const INITIAL_STATE = {
  notifications: [],
  currentNotification: {},
  loadingCurrentNotification: false,
  paginator: {},
  loading: false,
  selectedCollection: undefined,
  notificationMetadata: {
    rootParams: [
      { value: 'vouchers', name: 'Vouchers' },
      { value: 'webview', name: 'Webview' },
      { value: 'deeplink', name: 'Deeplink' },
      { value: 'feed', name: 'Feed' },
      { value: 'invite_a_friend', name: 'Invite a Friend' },
      { value: 'wishlist', name: 'Wishlist' },
      { value: 'stores', name: 'Stores' },
      { value: 'catalog', name: 'Catalog' },
      { value: 'category', name: 'Category by Id' },
      { value: 'product', name: 'Product by Sku' }
    ],
    frequencies: [
      { value: 'none', name: 'None' },
      { value: 'daily', name: 'Daily' },
      { value: 'weekly', name: 'Weekly' },
      { value: 'monthly', name: 'Monthly' },
      { value: 'yearly', name: 'Yearly' }
    ],
    notificationMediaTypes: [
      { value: 'image', name: 'image' },
      { value: 'bigImage', name: 'bigImage' }
    ]
  }
};

export default function(
  state = INITIAL_STATE,
  action: NotificationsActionType
): NotificationsReducerType {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        currentNotification: INITIAL_STATE.currentNotification,
        selectedCollection: INITIAL_STATE.selectedCollection
      };
    case GET_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.content.items,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false };
    case SET_SELECTED_NOTIFICATIONS_COLLECTION:
      return { ...state, selectedCollection: action.payload };
    case GET_NOTIFICATION_REQUEST:
    case GET_NOTIFICATION_FAILURE:
      return { ...state, loadingCurrentNotification: false };
    case GET_NOTIFICATION_SUCCESS:
    case EDIT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        currentNotification: action.payload.content,
        loadingCurrentNotification: false
      };
    default:
      return state;
  }
}
