import { take, put } from 'redux-saga/effects';
import { DELETE_GROUP_SUCCESS, getGroups } from './actions';

export function* groupsSaga() {
  while (true) {
    const actionData = yield take(DELETE_GROUP_SUCCESS);
    yield put(getGroups({ filter: actionData.meta.filter }));
  }
}

export default {
  groupsSaga
};
