import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  SET_DEVICE,
  CHANGE_LANG
} from 'redux/actions/auth';

const initialState = {
  confirmed: {
    status: null,
    err: null
  },
  lang: 'en',
  authenticated: false,
  user: {
    roles: ['ROLE_GUEST']
  },
  device: undefined
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        user: action.payload.user
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        token: undefined,
        authenticated: false,
        user: {
          roles: ['ROLE_GUEST']
        }
      };
    case SET_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    case CHANGE_LANG:
      return { ...state, lang: action.payload };
    default:
      return state;
  }
}
