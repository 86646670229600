import { UserSegmentAddType, UserSegmentType } from 'types/user-segment';
import { CallApi } from '@factorymarket/mg-react-components';
import { filterToApi, segmentRequestToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_USER_SEGMENTS_REQUEST = 'GET_USER_SEGMENTS_REQUEST';
export const GET_USER_SEGMENTS_SUCCESS = 'GET_USER_SEGMENTS_SUCCESS';
export const GET_USER_SEGMENTS_FAILURE = 'GET_USER_SEGMENTS_FAILURE';

export const getUserSegments = ({ filter }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user-segment/list?json=${JSON.stringify(
      filter && filterToApi(filter)
    ).replace(/\+/g, '%2b')}`,
    types: [GET_USER_SEGMENTS_REQUEST, GET_USER_SEGMENTS_SUCCESS, GET_USER_SEGMENTS_FAILURE]
  });

export const GET_USER_SEGMENT_REQUEST = 'GET_USER_SEGMENT_REQUEST';
export const GET_USER_SEGMENT_SUCCESS = 'GET_USER_SEGMENT_SUCCESS';
export const GET_USER_SEGMENT_FAILURE = 'GET_USER_SEGMENT_FAILURE';

export const getUserSegment = ({ id }: { id: number | string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user-segment/${id}`,
    types: [GET_USER_SEGMENT_REQUEST, GET_USER_SEGMENT_SUCCESS, GET_USER_SEGMENT_FAILURE]
  });

export const DELETE_USER_SEGMENT_REQUEST = 'DELETE_USER_SEGMENT_REQUEST';
export const DELETE_USER_SEGMENT_SUCCESS = 'DELETE_USER_SEGMENT_SUCCESS';
export const DELETE_USER_SEGMENT_FAILURE = 'DELETE_USER_SEGMENT_FAILURE';

export const deleteUserSegment = ({ id, filter }: { id: number; filter: uiFilterType }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/user-segment/${id}`,
    types: [
      DELETE_USER_SEGMENT_REQUEST,
      {
        type: DELETE_USER_SEGMENT_SUCCESS,
        meta: {
          filter,
          notification: { type: 'success', message: 'User segment successfully deleted!' }
        }
      },
      DELETE_USER_SEGMENT_FAILURE
    ]
  });

export const ADD_USER_SEGMENT_REQUEST = 'ADD_USER_SEGMENT_REQUEST';
export const ADD_USER_SEGMENT_SUCCESS = 'ADD_USER_SEGMENT_SUCCESS';
export const ADD_USER_SEGMENT_FAILURE = 'ADD_USER_SEGMENT_FAILURE';

export const addUserSegment = ({ formData }: { formData: UserSegmentAddType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/user-segment`,
    types: [
      ADD_USER_SEGMENT_REQUEST,
      {
        type: ADD_USER_SEGMENT_SUCCESS,
        meta: { notification: { type: 'success', message: 'User segment successfully created!' } }
      },
      ADD_USER_SEGMENT_FAILURE
    ],
    body: segmentRequestToApi(formData)
  });

export const EDIT_USER_SEGMENT_REQUEST = 'EDIT_USER_SEGMENT_REQUEST';
export const EDIT_USER_SEGMENT_SUCCESS = 'EDIT_USER_SEGMENT_SUCCESS';
export const EDIT_USER_SEGMENT_FAILURE = 'EDIT_USER_SEGMENT_FAILURE';

export const editUserSegment = ({ id, formData }: { id: number; formData: UserSegmentType }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/user-segment/${id}`,
    types: [
      EDIT_USER_SEGMENT_REQUEST,
      {
        type: EDIT_USER_SEGMENT_SUCCESS,
        meta: { notification: { type: 'success', message: 'User segment successfully updated!' } }
      },
      EDIT_USER_SEGMENT_FAILURE
    ],
    body: formData
  });
