import { createStore, applyMiddleware, Store } from 'redux';
import persistState from 'redux-localstorage';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'redux/reducers';
import { createLogger } from 'redux-logger';
// import { Router, Route, browserHistory } from 'react-router';
// import { syncHistoryWithStore, routerReducer } from 'react-router-redux';

export type StoreEnchancedInterface = { runSaga: Function; history: any };

export type AppState = ReturnType<typeof rootReducer>;

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunkMiddleware, sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
}

const middleWareEnhancer = applyMiddleware(...middlewares);

const store = createStore(
  rootReducer(history),
  // @ts-ignore
  composeWithDevTools(middleWareEnhancer, persistState(['config', 'auth']))
);

const storeToReturn: Store & StoreEnchancedInterface = {
  ...store,
  runSaga: sagaMiddleware.run,
  history
};

export default storeToReturn;
