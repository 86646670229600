import { CallApi } from '@factorymarket/mg-react-components';
import { filterToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_REVIEWS_IMPORT_REQUEST = 'GET_REVIEWS_IMPORT_REQUEST';
export const GET_REVIEWS_IMPORT_SUCCESS = 'GET_REVIEWS_IMPORT_SUCCESS';
export const GET_REVIEWS_IMPORT_FAILURE = 'GET_REVIEWS_IMPORT_FAILURE';

export const getReviewsImport = ({
  filter = { page: 1, perPage: 30 }
}: {
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/review/import/list?json=${JSON.stringify(
      filter && filterToApi(filter)
    )}`,
    types: [GET_REVIEWS_IMPORT_REQUEST, GET_REVIEWS_IMPORT_SUCCESS, GET_REVIEWS_IMPORT_FAILURE]
  });

export const ADD_REVIEWS_IMPORT_REQUEST = 'ADD_REVIEWS_IMPORT_REQUEST';
export const ADD_REVIEWS_IMPORT_SUCCESS = 'ADD_REVIEWS_IMPORT_SUCCESS';
export const ADD_REVIEWS_IMPORT_FAILURE = 'ADD_REVIEWS_IMPORT_FAILURE';

export const addReviewsImport = ({
  uploadId,
  delimiter
}: {
  uploadId: number;
  delimiter: string;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product/review/import`,
    types: [
      ADD_REVIEWS_IMPORT_REQUEST,
      {
        type: ADD_REVIEWS_IMPORT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Upload success!' } }
      },
      ADD_REVIEWS_IMPORT_FAILURE
    ],
    body: {
      uploadId,
      delimiter
    }
  });
