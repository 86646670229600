import { take, put, race } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {buildQueryToObject} from "utils/helper";
import {
  getPimImports,
  getPimImportErrors,
  GET_PIMIMPORT_SUCCESS, PUT_PIMIMPORT_RESET_REQUEST, PUT_PIMIMPORT_RESET_SUCCESS, GET_PIMIMPORT_ERROR_REQUEST
} from './actions';
import {withRouter} from "react-router";


export function* importsSaga() {
  while (true) {
    const { resetRequest, resetSuccess } = yield race({
      resetSuccess: take(PUT_PIMIMPORT_RESET_SUCCESS),
      resetRequest: take(PUT_PIMIMPORT_RESET_REQUEST)
    });
    if (resetSuccess) {
      yield put(getPimImports({}));
    }
  }
}

export function* importMessageSaga({ level, id }: { level: string, id: number }) {
  const location = yield take('*');
  const params = buildQueryToObject(location.payload.location.search);

  if (location.type === '@@router/LOCATION_CHANGE' && location.payload.action === 'REPLACE' && level === "errors") {
    yield put(getPimImportErrors({ id, level: 1 }));
  }
  if (location.type === '@@router/LOCATION_CHANGE' && location.payload.action === 'REPLACE' && level === "warnings") {
    yield put(getPimImportErrors({ id, level: 2 }));
  }
  if (location.type === '@@router/LOCATION_CHANGE' && (location.payload.action === 'PUSH' || location.payload.action === 'POP')) {
    if (level === "warnings") {
      if (Object.keys(params).length === 0) {
        yield put(getPimImportErrors({ id, level: 2}));
      } else {
        yield put(getPimImportErrors({ id, level: 2, filter: params}));
      }
    }
    if (level === "errors") {
      if (Object.keys(params).length === 0) {
        yield put(getPimImportErrors({ id, level: 1}));
      } else {
        yield put(getPimImportErrors({ id, level: 1, filter: params}));
      }
    }
  }
}

export default { importsSaga, importMessageSaga };
