import { fork, all } from 'redux-saga/effects';
import { router } from 'redux-saga-router';
import baseSaga from 'redux/sagas/baseSaga';
import restorePasswordSaga from 'views/page/Password/saga';
import { usersSaga, userEditSaga, usersAddSaga } from 'views/page/Users/saga';
import productsListSaga from 'views/page/Products/List/saga';
import { productEditSaga, productShowSaga, productAddSaga } from 'views/page/Products/Product/saga';
import { productsImportSaga } from 'views/page/Products/Import/saga';
import { productsImportAlsoBoughtSaga } from 'views/page/Products/ImportAlsoBought/saga';
import storesSaga from 'views/page/Stores/saga';
import { storeEditSaga, storeAddSaga } from 'views/page/Stores/Store/saga';
import { categoryAddSaga } from 'views/page/Categories/Category/saga';
import { groupAddSaga, groupEditSaga } from 'views/page/Groups/Group/saga';
import { groupsSaga } from 'views/page/Groups/saga';
import { bannerAddSaga, bannerEditSaga, bannerSaga } from 'views/page/Banners/saga';
import {
  notificationAddSaga,
  notificationEditSaga,
  notificationSaga
} from 'views/page/Notifications/saga';
import { journalSaga } from 'views/page/Journal/saga';
import { vouchersSaga, voucherEditSaga, voucherAddSaga } from 'views/page/Vouchers/saga';
import { vouchersImportSaga } from 'views/page/Vouchers/Import/saga';
import { productsReviewsSaga } from 'views/page/Reviews/saga';
import { reviewsImportSaga } from 'views/page/Reviews/Import/saga';
import { campaignSaga, campaignEditSaga } from 'views/page/Campaigns/saga';
import { dashboardSaga } from 'views/page/Dashboard/saga';
import { termsSaga, termSaga, termEditSaga } from 'views/page/VoucherTerms/saga';
import {
  userSegmentAddSaga,
  userSegmentEditSaga,
  userSegmentsSaga
} from 'views/page/UserSegments/saga';
import { userSegmentsImportSaga } from 'views/page/UserSegments/Import/saga';
import { importMessageSaga, importsSaga } from 'views/page/PimImport/saga';

const createRootSaga = (history: any) => {
  const routes = {
    '/users': function* usersPageSaga() {
      yield fork(usersSaga);
    },
    '/users/add': function* usersAddPageSaga() {
      yield fork(usersAddSaga);
    },
    '/user/:id': function* userEditPageSaga(data: { id: number }) {
      yield fork(userEditSaga, data);
    },
    '/user-segments': function* userSegmentsPageSaga() {
      yield fork(userSegmentsSaga);
    },
    '/user-segment/import': function* userSegmentImportPageSaga() {
      yield fork(userSegmentsImportSaga);
    },
    '/user-segment/add': function* userSegmentAddPageSaga() {
      yield fork(userSegmentAddSaga);
    },
    '/user-segment/edit/:id': function* userSegmentEditPageSaga(data: { id: number }) {
      yield fork(userSegmentEditSaga, data);
    },
    '/products/list': function* productsListPageSaga() {
      yield fork(productsListSaga);
    },
    '/products/import': function* productsImportPageSaga() {
      yield fork(productsImportSaga);
    },
    '/products/import-also-bought': function* productsImportAlsoBoughtPageSaga() {
      yield fork(productsImportAlsoBoughtSaga);
    },
    '/products/reviews': function* productsReviewsPageSaga() {
      yield fork(productsReviewsSaga);
    },
    '/pimimport': function* pimImportPageSaga() {
      yield fork(importsSaga);
    },
    '/pimmessages/:level/:id': function* pimImportMessagesPageSaga(data: {
      level: string;
      id: number;
    }) {
      yield fork(importMessageSaga, data);
    },
    '/products/review/import': function* productsReviewsImportPageSaga() {
      yield fork(reviewsImportSaga);
    },
    '/product/edit/:id': function* productPageSaga(data: any) {
      yield fork(productEditSaga, data);
    },
    '/product/show/:id': function* productPageSaga(data: any) {
      yield fork(productShowSaga, data);
    },
    '/product/add': function* productPageSaga() {
      yield fork(productAddSaga);
    },
    '/stores': function* storesPageSaga() {
      yield fork(storesSaga);
    },
    '/store/edit/:id': function* storePageSaga(data: any) {
      yield fork(storeEditSaga, data);
    },
    '/store/add': function* storePageSaga() {
      yield fork(storeAddSaga);
    },
    '/category/add': function* categoryAddPageSaga() {
      yield fork(categoryAddSaga);
    },
    '/groups/list': function* groupsPageSaga() {
      yield fork(groupsSaga);
    },
    '/group/add': function* groupAddPageSaga() {
      yield fork(groupAddSaga);
    },
    '/group/edit/:id': function* groupEditPageSaga() {
      yield fork(groupEditSaga);
    },
    '/banners': function* bannerPageSaga() {
      yield fork(bannerSaga);
    },
    '/banner/add': function* bannerAddPageSaga() {
      yield fork(bannerAddSaga);
    },
    '/banner/edit/:id': function* bannerEditPageSaga(data: { id: string }) {
      yield fork(bannerEditSaga, data);
    },
    '/notifications': function* notificationPageSaga() {
      yield fork(notificationSaga);
    },
    '/notification/add': function* notificationAddPageSaga() {
      yield fork(notificationAddSaga);
    },
    '/notification/edit/:id': function* notificationEditPageSaga(data: { id: string }) {
      yield fork(notificationEditSaga, data);
    },
    '/notification/journal': function* journalPageSaga() {
      yield fork(journalSaga);
    },
    '/vouchers': function* vouchersPageSaga() {
      yield fork(vouchersSaga);
    },
    '/vouchers/import': function* vouchersReviewsImportPageSaga() {
      yield fork(vouchersImportSaga);
    },
    '/voucher/edit/:id': function* voucherEditPageSaga(data: { id: string }) {
      yield fork(voucherEditSaga, data);
    },
    '/voucher/add': function* voucherAddPageSaga() {
      yield fork(voucherAddSaga);
    },
    '/terms': function* termsPageSaga() {
      yield fork(termsSaga);
    },
    '/term/add': function* termAddPageSaga() {
      yield fork(termSaga);
    },
    '/term/edt/:id': function* termEditPageSaga(data: { id: string }) {
      yield fork(termEditSaga, data);
    },
    '/restore': function* restorePasswordPageSaga() {
      yield fork(restorePasswordSaga);
    },
    '/campaign/add': function* campaignPageSaga() {
      yield fork(campaignSaga);
    },
    '/campaign/edit/:id': function* campaignEditPageSaga(data: { id: string }) {
      yield fork(campaignEditSaga, data);
    },
    '/': function* dashboardPageSaga() {
      yield fork(dashboardSaga);
    }
  };
  return function* routesSaga() {
    yield fork(router, history, routes);
  };
};

export default function* root(history: any) {
  yield fork(baseSaga, history, {}, all([yield fork(createRootSaga(history))]));
}
