import { CurrentProductType, CurrentVariantType, EanType } from 'types/product';
import { CallApi } from '@factorymarket/mg-react-components';
import { SimilarTypeRequestType } from './types';

const { callApiAction } = CallApi;

export const GET_SIMILAR_PRODUCTS_REQUEST = 'GET_SIMILAR_PRODUCTS_REQUEST';
export const GET_SIMILAR_PRODUCTS_SUCCESS = 'GET_SIMILAR_PRODUCTS_SUCCESS';
export const GET_SIMILAR_PRODUCTS_FAILURE = 'GET_SIMILAR_PRODUCTS_FAILURE';

export const getSimilarProducts = ({ productId }: { productId: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/${productId}/similar?json={"perPage": 499, "page": 1}`,
    types: [
      GET_SIMILAR_PRODUCTS_REQUEST,
      GET_SIMILAR_PRODUCTS_SUCCESS,
      GET_SIMILAR_PRODUCTS_FAILURE
    ]
  });

export const ADD_SIMILAR_PRODUCTS_REQUEST = 'ADD_SIMILAR_PRODUCTS_REQUEST';
export const ADD_SIMILAR_PRODUCTS_SUCCESS = 'ADD_SIMILAR_PRODUCTS_SUCCESS';
export const ADD_SIMILAR_PRODUCTS_FAILURE = 'ADD_SIMILAR_PRODUCTS_FAILURE';

export const addSimilarProduct = ({ formData }: { formData: SimilarTypeRequestType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product/similar`,
    types: [
      ADD_SIMILAR_PRODUCTS_REQUEST,
      {
        type: ADD_SIMILAR_PRODUCTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Similar product added!' } }
      },
      ADD_SIMILAR_PRODUCTS_FAILURE
    ],
    body: formData
  });

export const EDIT_SIMILAR_PRODUCTS_REQUEST = 'EDIT_SIMILAR_PRODUCTS_REQUEST';
export const EDIT_SIMILAR_PRODUCTS_SUCCESS = 'EDIT_SIMILAR_PRODUCTS_SUCCESS';
export const EDIT_SIMILAR_PRODUCTS_FAILURE = 'EDIT_SIMILAR_PRODUCTS_FAILURE';

export const editSimilarProduct = ({
  formData,
  id
}: {
  formData: SimilarTypeRequestType;
  id: number;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product/similar/${id}`,
    types: [
      EDIT_SIMILAR_PRODUCTS_REQUEST,
      {
        type: EDIT_SIMILAR_PRODUCTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Also Bought successfully updated!' } }
      },
      EDIT_SIMILAR_PRODUCTS_FAILURE
    ],
    body: formData
  });

export const DELETE_SIMILAR_PRODUCTS_REQUEST = 'DELETE_SIMILAR_PRODUCTS_REQUEST';
export const DELETE_SIMILAR_PRODUCTS_SUCCESS = 'DELETE_SIMILAR_PRODUCTS_SUCCESS';
export const DELETE_SIMILAR_PRODUCTS_FAILURE = 'DELETE_SIMILAR_PRODUCTS_FAILURE';

export const deleteSimilarProduct = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/product/similar/${id}`,
    types: [
      DELETE_SIMILAR_PRODUCTS_REQUEST,
      {
        type: DELETE_SIMILAR_PRODUCTS_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Similar product successfully deleted!' }
        }
      },
      DELETE_SIMILAR_PRODUCTS_FAILURE
    ]
  });
