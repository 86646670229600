import FormModalContainer from './Container';
import * as formModalActions from './actions';
import formModalSaga from './saga';
import formModalReducer from './reducer';

export default {
  FormModalContainer,
  formModalSaga,
  formModalReducer,
  formModalActions
};
