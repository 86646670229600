import _ from 'lodash';
import queryString from 'query-string';
import { APP_ENV } from 'config/env';

export const query = {
  parse(value: any, toPrimitives = false) {
    if (toPrimitives) {
      const obj: any = queryString.parse(value);
      Object.keys(obj).forEach(item => {
        /* eslint-disable-next-line */
        const value = obj[item];
        if (value === 'false') obj[item] = false;
        if (value === 'true') obj[item] = true;
        if (/^\d+$/.test(value)) obj[item] = +value;
      });
      return obj;
    }
    return queryString.parse(value);
  },
  stringify: queryString.stringify,
  set(history: any, obj: Object = {}, force: boolean = true) {
    const result = !force ? Object.assign(queryString.parse(history.location.search), obj) : obj;

    history.push({
      search: queryString.stringify(result)
    });
  },
  clean(history: any) {
    history.push({
      search: ''
    });
  }
};

export function buildQueryToObject(data: string) {
  const result: any = {};
  data.split('&').forEach(part => {
    const item = part.split('=');
    if (!_.isEmpty(item[0]) && typeof item[1] !== 'undefined') {
      result[item[0].replace('?', '')] = decodeURIComponent(item[1]);
    }
  });
  return result;
}

export function isEnvironment(environment: any): boolean {
  return environment === APP_ENV;
}

export const downloadBlobFile = ({ filename, blob }: { filename: string; blob: any }) => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }));
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 0);
};
