import { CallApi } from '@factorymarket/mg-react-components';

const { callApiAction } = CallApi;

export const GET_DASHBOARDS_REQUEST = 'GET_DASHBOARDS_REQUEST';
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS';
export const GET_DASHBOARDS_FAILURE = 'GET_DASHBOARDS_FAILURE';

export const getDashboards = () =>
  callApiAction({
    method: 'get',
    url: `/employee-area/dashboard`,
    types: [GET_DASHBOARDS_REQUEST, GET_DASHBOARDS_SUCCESS, GET_DASHBOARDS_FAILURE]
  });
