import { put, take, select } from 'redux-saga/effects';

import {
  getAnonymousToken,
  GET_ANONYMOUS_TOKEN_SUCCESS,
  LOGOUT_USER_SUCCESS
} from 'redux/actions/auth';
import { auth } from 'redux/reducers/selectors';
import { setToken } from 'utils/api';
import { DeviceType } from 'types/auth';

export function* anonimousTokenSaga() {
  const { device }: { device: DeviceType } = yield select(auth);
  const { authenticated } = yield select(auth);
  if (!authenticated) {
    yield put(getAnonymousToken({ formData: { device } }));
    const {
      payload: { content }
    } = yield take(GET_ANONYMOUS_TOKEN_SUCCESS);
    setToken(content.accessToken);
  }

  while (true) {
    yield take(LOGOUT_USER_SUCCESS);
    yield put(getAnonymousToken({ formData: { device } }));
    const {
      payload: { content }
    } = yield take(GET_ANONYMOUS_TOKEN_SUCCESS);
    setToken(content.accessToken);
  }
}

export default anonimousTokenSaga;
