import { take, put, race, takeLatest } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'react-router-redux';
import { getUserVouchers, getUserTransactions } from 'views/page/Vouchers/actions';
import { getUserEmails } from './Email/actions';
import {
  getUsers,
  getUser,
  ADD_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  EDIT_USER_REQUEST,
  GET_USER_REQUEST,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  EDIT_USER_POINTS_SUCCESS,
  BIND_USER_SEGMENT_TO_USER_SUCCESS,
  bindUserSegmentToUser
} from './actions';
import { getUserInvitations, DELETE_USER_INVITATIONS_SUCCESS } from './Invitation/actions';
import { editUserRequestActionType } from './types';

export function* usersSaga() {
  let Request;
  while (true) {
    const { editRequest, editSuccess, deleteRequest, deleteSuccess } = yield race({
      editRequest: take(EDIT_USER_REQUEST),
      deleteRequest: take(DELETE_USER_REQUEST),
      editSuccess: take(EDIT_USER_SUCCESS),
      deleteSuccess: take(DELETE_USER_SUCCESS)
    });
    if (editRequest || deleteRequest) {
      Request = editRequest || deleteRequest;
    }
    if (editSuccess || deleteSuccess) {
      yield put(getUsers({ filter: Request.meta.filter }));
    }
  }
}
export function* usersAddSaga() {
  while (true) {
    const { addUserSuccess } = yield race({
      addUserSuccess: take(ADD_USER_SUCCESS)
    });
    if (addUserSuccess) {
      yield put(push(`/user/${addUserSuccess.payload.content.id}`));
    }
  }
}
export function* userEditSaga({ id }: { id: number }) {
  const location = yield take('*');
  if (location.type === '@@router/LOCATION_CHANGE' && location.payload.action === 'REPLACE') {
    yield put(getUser({ id: id.toString() }));
    yield put(getUserVouchers({ userId: id }));
    yield put(getUserTransactions({ userId: id }));
    yield put(getUserInvitations({ userId: id }));
    yield put(getUserEmails({ userId: id }));
  }
  while (true) {
    const {
      request,
      pointSuccess,
      deleteSuccess,
      deleteInvitation,
      bindUserSegmentSuccess
    } = yield race({
      request: take(GET_USER_REQUEST),
      pointSuccess: take(EDIT_USER_POINTS_SUCCESS),
      deleteSuccess: take(DELETE_USER_SUCCESS),
      deleteInvitation: take(DELETE_USER_INVITATIONS_SUCCESS),
      bindUserSegmentSuccess: take(BIND_USER_SEGMENT_TO_USER_SUCCESS)
    });
    if (request) {
      yield put(getUserVouchers({ userId: id }));
      yield put(getUserTransactions({ userId: id }));
      yield put(getUserInvitations({ userId: id }));
      yield put(getUserEmails({ userId: id }));
    }
    if (pointSuccess || bindUserSegmentSuccess) {
      yield put(getUser({ id: id.toString() }));
    }
    if (deleteSuccess) {
      yield put(push('/users'));
    }
    if (deleteInvitation) {
      yield put(getUserInvitations({ userId: id }));
    }
  }
}

export default { usersSaga, userEditSaga, usersAddSaga };
