import { CallApi } from '@factorymarket/mg-react-components';
import { uiFilterType } from './types';
import { filterToApi } from './adapter';

const { callApiAction } = CallApi;

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE';

export const getReviews = ({ filter }: { filter: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/review?json=${JSON.stringify(filterToApi(filter))}`,
    types: [GET_REVIEWS_REQUEST, GET_REVIEWS_SUCCESS, GET_REVIEWS_FAILURE]
  });

export const EDIT_REVIEW_REQUEST = 'EDIT_REVIEW_REQUEST';
export const EDIT_REVIEW_SUCCESS = 'EDIT_REVIEW_SUCCESS';
export const EDIT_REVIEW_FAILURE = 'EDIT_REVIEW_FAILURE';

export const editReview = ({
  id,
  formData,
  filter
}: {
  id: number;
  formData: any;
  filter: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product/review/${id}`,
    types: [
      {
        type: EDIT_REVIEW_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_REVIEW_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Review successfully updated!' }
        }
      },
      EDIT_REVIEW_FAILURE
    ],
    body: formData
  });

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';

export const deleteReview = ({ id, filter }: { id: string; filter: uiFilterType }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/product/review/${id}`,
    types: [
      DELETE_REVIEW_REQUEST,
      {
        type: DELETE_REVIEW_SUCCESS,
        meta: {
          filter
        }
      },
      DELETE_REVIEW_FAILURE
    ]
  });
