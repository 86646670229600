import { fork, call, all } from 'redux-saga/effects';
import api from 'utils/api';
import { CallApi, Meta, Notification } from '@factorymarket/mg-react-components';
import { UniversalPageContainer } from 'redux/containers/UniversalPageContainer';
import apiRootSaga from 'redux/modules/config/saga';
import apiErrorSaga from 'redux/sagas/apiErrorSaga';
import authSagaV2 from 'redux/sagas/authSagaV2';
// import sentrySaga from 'interfaces/common/redux/sagas/sentrySaga';
import metaCallbackSaga from 'redux/sagas/metaCallbackSaga';
import refreshTokenSaga from 'redux/sagas/refreshTokenSaga';
import redirectSaga from 'redux/sagas/redirectSaga';
import langSaga, { checkLangChangeSaga } from 'redux/sagas/langSaga';
import FormModal from 'redux/containers/FormModal';
import anonimousTokenSaga from './anonimousTokenSaga';

// import localStorageSaga from 'redux/sagas/localStorageSaga';
// import { isEnvironment } from 'utils/helper';

const { callApiActionSaga } = CallApi;
const { metaSaga } = Meta;
const { notificationSaga, errorNotificationSaga, metaSagaNotificationsSaga } = Notification;

export default function* baseSaga(history: any, sagasArr: any) {
  // yield fork(localStorageSaga);
  yield call(langSaga);
  yield fork(authSagaV2);
  yield call(apiRootSaga);
  yield fork(redirectSaga, history);
  yield fork(apiErrorSaga);
  yield fork(refreshTokenSaga);
  yield fork(metaSaga, [callApiActionSaga, metaSagaNotificationsSaga, metaCallbackSaga], api);
  yield fork(notificationSaga);
  yield fork(errorNotificationSaga);
  yield fork(UniversalPageContainer.Saga, history);
  yield fork(anonimousTokenSaga);
  yield all(sagasArr);
  yield fork(checkLangChangeSaga);
  yield fork(FormModal.formModalSaga);
}
