import { CallApi } from '@factorymarket/mg-react-components';
import { uiFilterType } from './types';
import { filterToApi } from './adapter';

const { callApiAction } = CallApi;

export const GET_JOURNAL_REQUEST = 'GET_JOURNAL_REQUEST';
export const GET_JOURNAL_SUCCESS = 'GET_JOURNAL_SUCCESS';
export const GET_JOURNAL_FAILURE = 'GET_JOURNAL_FAILURE';

export const getJournal = ({ filter = { page: 1, perPage: 30 } }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/notification/journal?json=${JSON.stringify(filterToApi(filter))}`,
    types: [GET_JOURNAL_REQUEST, GET_JOURNAL_SUCCESS, GET_JOURNAL_FAILURE]
  });
