import { take, put, race, select, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  getUserSegments,
  EDIT_USER_SEGMENT_SUCCESS,
  DELETE_USER_SEGMENT_SUCCESS,
  ADD_USER_SEGMENT_SUCCESS,
  getUserSegment
} from './actions';

export function* userSegmentsSaga() {
  while (true) {
    const deleteRequest = yield take(DELETE_USER_SEGMENT_SUCCESS);
    yield put(getUserSegments({ filter: deleteRequest.meta.filter }));
  }
}

export function* userSegmentEditSaga({ id }: { id: number }) {
  while (true) {
    const editSuccess = yield race({
      editSuccess: yield take(EDIT_USER_SEGMENT_SUCCESS)
    });

    if (editSuccess) {
      yield put(getUserSegment({ id }));
    }
  }
}

export function* userSegmentAddSaga() {
  while (true) {
    const addSuccess = yield race({
      addSuccess: take(ADD_USER_SEGMENT_SUCCESS)
    });

    if (addSuccess) {
      yield put(push('/user-segments'));
    }
  }
}

export default { userSegmentsSaga, userSegmentEditSaga };
