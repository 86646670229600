import { ProductImportErrorType } from 'types/errors';

import { uiFilterType, apiFilterType } from './types';

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const { page, perPage, status, id } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10)
  };
  if (status !== undefined) {
    updatedFilter.status = typeof status === 'number' ? status : parseInt(status, 10);
  }

  if (id !== undefined) {
    updatedFilter.id = typeof id === 'number' ? id : parseInt(id, 10);
  }

  return updatedFilter;
};

export const getErrorsArr = (errors: ProductImportErrorType[]): string[] =>
  errors.map((error: ProductImportErrorType) => `Line: ${error.line} - ${error.messages.join()}`);

export default {
  getErrorsArr
};
