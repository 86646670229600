import { LOCATION_CHANGE } from 'connected-react-router';
import { BannersActionType, BannersReducerType } from './types';

import {
  GET_BANNERS_REQUEST,
  GET_BANNERS_SUCCESS,
  GET_BANNERS_FAILURE,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAILURE,
  EDIT_BANNER_SUCCESS,
  SET_SELECTED_BANNERS_COLLECTION
} from './actions';

const INITIAL_STATE = {
  banners: [],
  currentBanner: {},
  loadingCurrentBanner: false,
  paginator: {},
  loading: false,
  selectedCollection: undefined,
  bannerMetadata: {
    places: [
      {
        value: 'home_header',
        name: 'home_header'
      },
      {
        value: 'home',
        name: 'home'
      },
      {
        value: 'feed',
        name: 'feed'
      },
      {
        value: 'catalogue',
        name: 'catalogue'
      },
      {
        value: 'product_group',
        name: 'product_group'
      }
    ],
    types: [
      {
        value: 'single',
        name: 'single'
      },
      {
        value: 'slider',
        name: 'slider'
      }
    ],
    bannerMediaTypes: [
      {
        value: 'image',
        name: 'image'
      },
      {
        value: 'bigImage',
        name: 'bigImage'
      }
    ],
    actionTargetTypes: [
      {
        value: 'product',
        name: 'product'
      },
      {
        value: 'category',
        name: 'category'
      },
      {
        value: 'flyer',
        name: 'flyer'
      },
      {
        value: 'none',
        name: 'none'
      },
      {
        value: 'webview',
        name: 'webview'
      },
      {
        value: 'product_group',
        name: 'product_group'
      },
      {
        value: 'deeplink',
        name: 'deeplink'
      }
    ]
  }
};

export default function(state = INITIAL_STATE, action: BannersActionType): BannersReducerType {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        currentBanner: INITIAL_STATE.currentBanner,
        selectedCollection: INITIAL_STATE.selectedCollection
      };
    case GET_BANNERS_REQUEST:
      return { ...state, loading: true };
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: action.payload.content.items,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_BANNERS_FAILURE:
      return { ...state, loading: false };
    case SET_SELECTED_BANNERS_COLLECTION:
      return { ...state, selectedCollection: action.payload };
    case GET_BANNER_REQUEST:
    case GET_BANNER_FAILURE:
      return { ...state, loadingCurrentBanner: false };
    case GET_BANNER_SUCCESS:
    case EDIT_BANNER_SUCCESS:
      return { ...state, currentBanner: action.payload.content, loadingCurrentBanner: false };
    default:
      return state;
  }
}
