import { CurrentProductType, CurrentVariantType, EanType } from 'types/product';
import { CallApi } from '@factorymarket/mg-react-components';
import { AlsoBoutRequestType } from './types';

const { callApiAction } = CallApi;

export const GET_ALSO_BOUGHT_PRODUCTS_REQUEST = 'GET_ALSO_BOUGHT_PRODUCTS_REQUEST';
export const GET_ALSO_BOUGHT_PRODUCTS_SUCCESS = 'GET_ALSO_BOUGHT_PRODUCTS_SUCCESS';
export const GET_ALSO_BOUGHT_PRODUCTS_FAILURE = 'GET_ALSO_BOUGHT_PRODUCTS_FAILURE';

export const getAlsoBoughtProducts = ({ productId }: { productId: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/also-bought/${productId}?json={"perPage": 499, "page": 1}`,
    types: [
      GET_ALSO_BOUGHT_PRODUCTS_REQUEST,
      GET_ALSO_BOUGHT_PRODUCTS_SUCCESS,
      GET_ALSO_BOUGHT_PRODUCTS_FAILURE
    ]
  });

export const ADD_ALSO_BOUGHT_PRODUCTS_REQUEST = 'ADD_ALSO_BOUGHT_PRODUCTS_REQUEST';
export const ADD_ALSO_BOUGHT_PRODUCTS_SUCCESS = 'ADD_ALSO_BOUGHT_PRODUCTS_SUCCESS';
export const ADD_ALSO_BOUGHT_PRODUCTS_FAILURE = 'ADD_ALSO_BOUGHT_PRODUCTS_FAILURE';

export const addAlsoBoughtProduct = ({ formData }: { formData: AlsoBoutRequestType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product/also-bought`,
    types: [
      ADD_ALSO_BOUGHT_PRODUCTS_REQUEST,
      {
        type: ADD_ALSO_BOUGHT_PRODUCTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Also Bought added!' } }
      },
      ADD_ALSO_BOUGHT_PRODUCTS_FAILURE
    ],
    body: formData
  });

export const EDIT_ALSO_BOUGHT_PRODUCTS_REQUEST = 'EDIT_ALSO_BOUGHT_PRODUCTS_REQUEST';
export const EDIT_ALSO_BOUGHT_PRODUCTS_SUCCESS = 'EDIT_ALSO_BOUGHT_PRODUCTS_SUCCESS';
export const EDIT_ALSO_BOUGHT_PRODUCTS_FAILURE = 'EDIT_ALSO_BOUGHT_PRODUCTS_FAILURE';

export const editAlsoBoughtProduct = ({
  formData,
  id
}: {
  formData: AlsoBoutRequestType;
  id: number;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product/also-bought/${id}`,
    types: [
      EDIT_ALSO_BOUGHT_PRODUCTS_REQUEST,
      {
        type: EDIT_ALSO_BOUGHT_PRODUCTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Also Bought successfully updated!' } }
      },
      EDIT_ALSO_BOUGHT_PRODUCTS_FAILURE
    ],
    body: formData
  });

export const DELETE_ALSO_BOUGHT_PRODUCTS_REQUEST = 'DELETE_ALSO_BOUGHT_PRODUCTS_REQUEST';
export const DELETE_ALSO_BOUGHT_PRODUCTS_SUCCESS = 'DELETE_ALSO_BOUGHT_PRODUCTS_SUCCESS';
export const DELETE_ALSO_BOUGHT_PRODUCTS_FAILURE = 'DELETE_ALSO_BOUGHT_PRODUCTS_FAILURE';

export const deleteAlsoBoughtProduct = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/product/also-bought/${id}`,
    types: [
      DELETE_ALSO_BOUGHT_PRODUCTS_REQUEST,
      {
        type: DELETE_ALSO_BOUGHT_PRODUCTS_SUCCESS,
        meta: { notification: { type: 'success', message: 'Also Bought successfully deleted!' } }
      },
      DELETE_ALSO_BOUGHT_PRODUCTS_FAILURE
    ]
  });
