import { call, CallEffectNamedFn, Func0 } from 'redux-saga/effects';

type MetaType = {
  callback?: CallEffectNamedFn<{ [x: string]: Func0 }, string>;
};

// Meta middleware for invoke callback function.
export default function* metaCallbackSaga(meta: MetaType) {
  if (!meta.callback) return;
  if (typeof meta.callback !== 'function') throw new Error('Callback must be a function');
  yield call(meta.callback);
}
