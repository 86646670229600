import axios from 'axios';
import { Dispatch, Action } from 'redux';
import { ActionTypeObject } from './types';

export const GET_UNIVERSAL_PAGE_DATA_REQUEST = 'GET_UNIVERSAL_PAGE_DATA_REQUEST';
export const GET_UNIVERSAL_PAGE_DATA_SUCCESS = 'GET_UNIVERSAL_PAGE_DATA_SUCCESS';
export const GET_UNIVERSAL_PAGE_DATA_FAILURE = 'GET_UNIVERSAL_PAGE_DATA_FAILURE';

export function getUniversalPageData(
  resource: { key: any; headers?: object; url: string },
  api = axios
): any {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: GET_UNIVERSAL_PAGE_DATA_REQUEST });
    return api
      .get(resource.url, resource.headers && { headers: resource.headers })
      .then(response => {
        dispatch({
          type: GET_UNIVERSAL_PAGE_DATA_SUCCESS,
          payload: response.data,
          meta: { resource }
        });
      })
      .catch(error => {
        dispatch({ type: GET_UNIVERSAL_PAGE_DATA_FAILURE });
      });
  };
}

export const SET_UNIVERSAL_PAGE_META_SUCCESS = 'SET_UNIVERSAL_PAGE_META_SUCCESS';

export function setUniversalPageMeta(
  resources: { key: any; headers: Object; url: string }[],
  updateData: { types: ActionTypeObject[] | string[]; action: () => void },
  api: any
) {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: SET_UNIVERSAL_PAGE_META_SUCCESS,
      payload: { resources, updateData, api }
    });
  };
}
