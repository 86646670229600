import { take, put } from 'redux-saga/effects';
import { getProducts, EDIT_PRODUCT_SUCCESS, EDIT_PRODUCT_REQUEST } from '../actions';

export function* productsSaga() {
  while (true) {
    const editRequest = yield take(EDIT_PRODUCT_REQUEST);
    yield take(EDIT_PRODUCT_SUCCESS);
    yield put(getProducts({ filter: editRequest.meta.filter }));
  }
}

export default productsSaga;
