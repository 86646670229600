import { take, put, race } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  getTerms,
  ADD_TERM_SUCCESS,
  DELETE_TERM_SUCCESS,
  EDIT_TERM_SUCCESS,
  getTerm
} from './actions';

export function* termSaga() {
  while (true) {
    const { add } = yield race({
      add: take(ADD_TERM_SUCCESS)
    });
    if (add) {
      yield put(push('/terms'));
    }
  }
}

export function* termsSaga() {
  while (true) {
    const deleteRequest = yield take(DELETE_TERM_SUCCESS);
    yield put(getTerms({ filter: deleteRequest.meta.filter }));
  }
}

export function* termEditSaga({ id }: { id: string }) {
  while (true) {
    const { editSuccess } = yield race({
      editSuccess: take(EDIT_TERM_SUCCESS)
    });
    if (editSuccess) {
      yield put(getTerm({ id }));
    }
  }
}
export default { termsSaga, termEditSaga, termSaga };
