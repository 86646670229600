import { SET_CONFIG, LOAD_CONFIG_SUCCESS } from './actions';

const INITIAL_STATE = {
  apiRoot: {},
  asideCollapsed: false
};

type actionType = {
  type: string;
  payload: {
    apiRoot?: string;
  };
};

export default function(state: any = INITIAL_STATE, action: actionType) {
  switch (action.type) {
    case LOAD_CONFIG_SUCCESS:
      return { ...state, apiRoot: action.payload.apiRoot };

    case SET_CONFIG:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
