// @flow
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { mainPagePath } from 'utils/auth';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Header } from '@factorymarket/mg-react-components';
import Aside from 'redux/containers/Aside';
import routerConfig from 'router.config';
import { Layout as AntLayout, Alert } from 'antd';
import { API_URL_V1 } from 'config/env';
import * as authActions from 'redux/actions/auth';
import { CurrentUserType } from 'types/user';
import { browserName } from 'react-device-detect';
import logo from './Mac-Geiz_black.png';

// import { RouterItemType, RouterConfigType } from 'types/router';

const { Content } = AntLayout;

type Props = {
  location: {
    pathname: string;
  };
  children: Object;
  changeLang: Function;
  currentLanguage: string;
  translate: any;
  auth: {
    user: CurrentUserType;
    authenticated: boolean;
  };
  // authActions: {
  logoutUser: (...args: any[]) => any | PromiseLike<any>;
  // }
};

class Layout extends PureComponent<Props> {
  render() {
    const {
      location,
      children,
      auth,
      logoutUser,
      changeLang,
      currentLanguage,
      translate
    } = this.props;

    return (
      <AntLayout style={{ minHeight: '100vh' }}>
        <Header
          logo={logo}
          logoAlt={translate('Header.LogoAlt')}
          auth={auth}
          mainPageForInterface={mainPagePath}
          authActions={{ logoutUser }}
          changeLang={changeLang}
          currentLanguage={currentLanguage}
        />
        <AntLayout>
          {auth.authenticated && (
            // @ts-ignore
            <Aside location={location} theme="light" routerConfig={routerConfig} />
          )}
          <AntLayout>
            {API_URL_V1.search('.tech') >= 0 && (
              <Alert message="Warning: You are in development environment." banner />
            )}
            {API_URL_V1.search('.stage') >= 0 && (
              <Alert message="Warning: You are in stage development environment." banner />
            )}
            {API_URL_V1.search('.ilogs') >= 0 && (
              <Alert message="Warning: You are in test development environment." banner />
            )}
            {API_URL_V1.search('localhost') >= 0 && (
              <Alert message="Warning: You are in local development environment." banner />
            )}
            {API_URL_V1.search('.local') >= 0 && (
              <Alert message="Warning: You are in local development environment." banner />
            )}
            {browserName === 'IE' && (
              <Alert
                message="Your browser is not fully supported. Please use Chrome,Firefox or Safari"
                banner
              />
            )}
            <Content style={{ margin: '15px 16px 0' }}>{children}</Content>
          </AntLayout>
        </AntLayout>
      </AntLayout>
    );
  }
}
// export default withRouter(Layout);

function mapStateToProps(state: any) {
  return {
    auth: state.auth,
    currentLanguage: state.auth.lang,
    translate: getTranslate(state.localize)
  };
}

const connector = connect(
  mapStateToProps,
  dispatch =>
    bindActionCreators(
      { logoutUser: authActions.logoutUser, changeLang: authActions.changeLang },
      dispatch
    )
);

// @ts-ignore
export const LayoutContainer = withRouter(connector(Layout));

export default LayoutContainer;
