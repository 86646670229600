import {
  LoyaltyVoucherAddType,
  LoyaltyVoucherEditType,
  CreateBindingVoucherToEntityRequestType,
  UnbindingVoucherToEntityRequestType
} from 'types/vouchers';
import { CallApi } from '@factorymarket/mg-react-components';
import { voucherRequestToApi, filterToApi, voucherRequestFromApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_VOUCHERS_REQUEST = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHERS_SUCCESS = 'GET_VOUCHERS_SUCCESS';
export const GET_VOUCHERS_FAILURE = 'GET_VOUCHERS_FAILURE';

export const getVouchers = ({ filter }: { filter: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/voucher?json=${JSON.stringify(filterToApi(filter))}`,
    types: [GET_VOUCHERS_REQUEST, GET_VOUCHERS_SUCCESS, GET_VOUCHERS_FAILURE]
  });

export const GET_VOUCHER_TERM_REQUEST = 'GET_VOUCHER_TERM_REQUEST';
export const GET_VOUCHER_TERM_SUCCESS = 'GET_VOUCHER_TERM_SUCCESS';
export const GET_VOUCHER_TERM_FAILURE = 'GET_VOUCHER_TERM_FAILURE';

export const getVoucherTerms = () =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/allterms`,
    types: [GET_VOUCHER_TERM_REQUEST, GET_VOUCHER_TERM_SUCCESS, GET_VOUCHER_TERM_FAILURE]
  });

export const ADD_VOUCHER_REQUEST = 'ADD_VOUCHER_REQUEST';
export const ADD_VOUCHER_SUCCESS = 'ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILURE = 'ADD_VOUCHER_FAILURE';

export const addVoucher = ({ formData }: { formData: LoyaltyVoucherAddType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/voucher`,
    types: [
      ADD_VOUCHER_REQUEST,
      {
        type: ADD_VOUCHER_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Voucher successfully created!' }
        }
      },
      ADD_VOUCHER_FAILURE
    ],
    body: voucherRequestToApi(formData)
  });

export const GET_VOUCHER_REQUEST = 'GET_VOUCHER_REQUEST';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_FAILURE = 'GET_VOUCHER_FAILURE';

export const getVoucher = ({ id }: { id: number | string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/voucher/${id}`,
    types: [
      GET_VOUCHER_REQUEST,
      {
        type: GET_VOUCHER_SUCCESS,
        payload: (data: any) => voucherRequestFromApi(data)
      },
      GET_VOUCHER_FAILURE
    ]
  });

export const EDIT_VOUCHER_REQUEST = 'EDIT_VOUCHER_REQUEST';
export const EDIT_VOUCHER_SUCCESS = 'EDIT_VOUCHER_SUCCESS';
export const EDIT_VOUCHER_FAILURE = 'EDIT_VOUCHER_FAILURE';

export const editVoucher = ({ id, formData }: { id: number; formData: LoyaltyVoucherEditType }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/loyalty/voucher/${id}`,
    types: [
      EDIT_VOUCHER_REQUEST,
      {
        type: EDIT_VOUCHER_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Voucher successfully updated!' }
        }
      },
      EDIT_VOUCHER_FAILURE
    ],
    body: voucherRequestToApi(formData)
  });

export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHER_REQUEST';
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHER_SUCCESS';
export const DELETE_VOUCHER_FAILURE = 'DELETE_VOUCHER_FAILURE';

export const deleteVoucher = ({ id, filter }: { id: number; filter: uiFilterType }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/loyalty/voucher/${id}`,
    types: [
      DELETE_VOUCHER_REQUEST,
      {
        type: DELETE_VOUCHER_SUCCESS,
        meta: {
          filter,
          notification: { type: 'success', message: 'Voucher successfully inactivated!' }
        }
      },
      DELETE_VOUCHER_FAILURE
    ]
  });

export const GET_USER_VOUCHERS_REQUEST = 'GET_USER_VOUCHERS_REQUEST';
export const GET_USER_VOUCHERS_SUCCESS = 'GET_USER_VOUCHERS_SUCCESS';
export const GET_USER_VOUCHERS_FAILURE = 'GET_USER_VOUCHERS_FAILURE';

export const getUserVouchers = ({
  userId,
  filter = { page: 1, perPage: 499 }
}: {
  userId: number;
  filter?: { page?: number; perPage?: number };
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/${userId}/vouchers?json=${JSON.stringify(filter)}`,
    types: [GET_USER_VOUCHERS_REQUEST, GET_USER_VOUCHERS_SUCCESS, GET_USER_VOUCHERS_FAILURE]
  });

export const GET_USER_TRANSACTIONS_REQUEST = 'GET_USER_TRANSACTIONS_REQUEST';
export const GET_USER_TRANSACTIONS_SUCCESS = 'GET_USER_TRANSACTIONS_SUCCESS';
export const GET_USER_TRANSACTIONS_FAILURE = 'GET_USER_TRANSACTIONS_FAILURE';

export const getUserTransactions = ({
  userId,
  filter = { page: 1, perPage: 20 }
}: {
  userId: number;
  filter?: { page?: number; perPage?: number };
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/${userId}/transactions?json=${JSON.stringify(filter)}`,
    types: [
      GET_USER_TRANSACTIONS_REQUEST,
      GET_USER_TRANSACTIONS_SUCCESS,
      GET_USER_TRANSACTIONS_FAILURE
    ]
  });

export const DELETE_VOUCHER_IMAGE_REQUEST = 'DELETE_VOUCHER_IMAGE_REQUEST';
export const DELETE_VOUCHER_IMAGE_SUCCESS = 'DELETE_VOUCHER_IMAGE_SUCCESS';
export const DELETE_VOUCHER_IMAGE_FAILURE = 'DELETE_VOUCHER_IMAGE_FAILURE';

export const deleteVoucherImage = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/loyalty/voucher/${id}/image`,
    types: [
      DELETE_VOUCHER_IMAGE_REQUEST,
      DELETE_VOUCHER_IMAGE_SUCCESS,
      DELETE_VOUCHER_IMAGE_FAILURE
    ]
  });

export const BIND_PRODUCT_TO_VOUCHER_REQUEST = 'BIND_PRODUCT_TO_VOUCHER_REQUEST';
export const BIND_PRODUCT_TO_VOUCHER_SUCCESS = 'BIND_PRODUCT_TO_VOUCHER_SUCCESS';
export const BIND_PRODUCT_TO_VOUCHER_FAILURE = 'BIND_PRODUCT_TO_VOUCHER_FAILURE';
export const BIND_PRODUCT_TO_VOUCHER = 'BIND_PRODUCT_TO_VOUCHER';

export const bindProductToVoucher = ({
  id,
  formData
}: {
  id: number;
  formData: CreateBindingVoucherToEntityRequestType;
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/loyalty/voucher/${id}/binding`,
        types: [
          BIND_PRODUCT_TO_VOUCHER_REQUEST,
          BIND_PRODUCT_TO_VOUCHER_SUCCESS,
          BIND_PRODUCT_TO_VOUCHER_FAILURE
        ],
        body: formData
      })
    : {
        type: BIND_PRODUCT_TO_VOUCHER,
        payload: formData
      };

export const UNBIND_PRODUCT_TO_VOUCHER_REQUEST = 'UNBIND_PRODUCT_TO_VOUCHER_REQUEST';
export const UNBIND_PRODUCT_TO_VOUCHER_SUCCESS = 'UNBIND_PRODUCT_TO_VOUCHER_SUCCESS';
export const UNBIND_PRODUCT_TO_VOUCHER_FAILURE = 'UNBIND_PRODUCT_TO_VOUCHER_FAILURE';
export const UNBIND_PRODUCT_TO_VOUCHER = 'UNBIND_PRODUCT_TO_VOUCHER';

export const unbindProductToVoucher = ({
  id,
  formData
}: {
  id: number;
  formData: {
    entityId: number;
  };
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/loyalty/voucher/${id}/unbinding`,
        types: [
          UNBIND_PRODUCT_TO_VOUCHER_REQUEST,
          UNBIND_PRODUCT_TO_VOUCHER_SUCCESS,
          UNBIND_PRODUCT_TO_VOUCHER_FAILURE
        ],
        body: formData
      })
    : {
        type: UNBIND_PRODUCT_TO_VOUCHER,
        payload: formData
      };

export const BIND_USER_SEGMENT_TO_VOUCHER_REQUEST = 'BIND_USER_SEGMENT_TO_VOUCHER_REQUEST';
export const BIND_USER_SEGMENT_TO_VOUCHER_SUCCESS = 'BIND_USER_SEGMENT_TO_VOUCHER_SUCCESS';
export const BIND_USER_SEGMENT_TO_VOUCHER_FAILURE = 'BIND_USER_SEGMENT_TO_VOUCHER_FAILURE';
export const BIND_USER_SEGMENT_TO_VOUCHER = 'BIND_USER_SEGMENT_TO_VOUCHER';

export const bindUserSegmentToVoucher = ({
  id,
  formData
}: {
  id: number;
  formData: CreateBindingVoucherToEntityRequestType;
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/loyalty/voucher/${id}/binding`,
        types: [
          BIND_USER_SEGMENT_TO_VOUCHER_REQUEST,
          BIND_USER_SEGMENT_TO_VOUCHER_SUCCESS,
          BIND_USER_SEGMENT_TO_VOUCHER_FAILURE
        ],
        body: formData
      })
    : {
        type: BIND_USER_SEGMENT_TO_VOUCHER,
        payload: formData
      };

export const UNBIND_USER_SEGMENT_TO_VOUCHER_REQUEST = 'UNBIND_USER_SEGMENT_TO_VOUCHER_REQUEST';
export const UNBIND_USER_SEGMENT_TO_VOUCHER_SUCCESS = 'UNBIND_USER_SEGMENT_TO_VOUCHER_SUCCESS';
export const UNBIND_USER_SEGMENT_TO_VOUCHER_FAILURE = 'UNBIND_USER_SEGMENT_TO_VOUCHER_FAILURE';
export const UNBIND_USER_SEGMENT_TO_VOUCHER = 'UNBIND_USER_SEGMENT_TO_VOUCHER';

export const unbindUserSegmentToVoucher = ({
  id,
  formData
}: {
  id: number;
  formData: UnbindingVoucherToEntityRequestType;
}) =>
  id
    ? callApiAction({
        method: 'put',
        url: `/employee-area/loyalty/voucher/${id}/unbinding`,
        types: [
          UNBIND_USER_SEGMENT_TO_VOUCHER_REQUEST,
          UNBIND_USER_SEGMENT_TO_VOUCHER_SUCCESS,
          UNBIND_USER_SEGMENT_TO_VOUCHER_FAILURE
        ],
        body: formData
      })
    : {
        type: UNBIND_USER_SEGMENT_TO_VOUCHER,
        payload: formData
      };
