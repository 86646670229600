import moment from 'moment';
import { CampaignType } from 'types/campaigns';

export const campaignRequestToApi = (formData: any): CampaignType => {
  const updatedFormData = {
    ...formData,
    id: formData.id && parseInt(formData.id, 10),
    dateFrom:
      (formData.dateFrom && parseInt(moment(formData.dateFrom).format('X'), 10)) || undefined,
    dateTo: (formData.dateTo && parseInt(moment(formData.dateTo).format('X'), 10)) || undefined
  };
  return updatedFormData;
};

export default { campaignRequestToApi };
