import moment from 'moment';
import { CurrentBannerType, BannerMediaType } from 'types/banner';

export const bannerRequestToApi = (formData: any): CurrentBannerType => {
  const updatedFormData = {
    ...formData,
    id: formData.id && parseInt(formData.id, 10),
    active: formData.active || false,
    dateFrom:
      (formData.dateFrom && parseInt(moment(formData.dateFrom).format('X'), 10)) || undefined,
    dateTo: (formData.dateTo && parseInt(moment(formData.dateTo).format('X'), 10)) || undefined
  };
  return updatedFormData;
};

export const getBannerMediaFromBanner = ({
  mediaArr,
  mediaId
}: {
  mediaArr: BannerMediaType[];
  mediaId: number;
}): BannerMediaType | undefined => {
  let result;
  const searchBannerMedia = (bannerMediaData: { mediaArr: BannerMediaType[]; mediaId: number }) =>
    bannerMediaData.mediaArr.forEach((item: BannerMediaType) => {
      if (item.id === mediaId) {
        result = item;
      } else if (item.children && item.children.length) {
        searchBannerMedia({ mediaArr: item.children, mediaId });
      }
    });
  searchBannerMedia({ mediaArr, mediaId });
  return result;
};

export default { bannerRequestToApi, getBannerMediaFromBanner };
