import { take, put, race, fork, cancel } from 'redux-saga/effects';
import { TOGGLE_MODAL_SUCCESS, SET_MODAL_LOADING, toggleModal } from './actions';

function* formModalRefreshSaga({ actionTypePart }: { actionTypePart: string }) {
  while (true) {
    const { success, failure, request, toggleSuccess } = yield race({
      request: take(`${actionTypePart}_REQUEST`),
      success: take(`${actionTypePart}_SUCCESS`),
      failure: take(`${actionTypePart}_FAILURE`),
      toggleSuccess: take(TOGGLE_MODAL_SUCCESS)
    });
    if (toggleSuccess) {
      if (!toggleSuccess.payload.isOpened) {
        yield cancel();
      }
    }
    if (request) {
      yield put({ type: SET_MODAL_LOADING, payload: { loading: true } });
    }
    if (success) {
      yield put({ type: SET_MODAL_LOADING, payload: { loading: false } });
      yield put(toggleModal({ isOpened: false }));
    }
    if (failure) {
      yield put({ type: SET_MODAL_LOADING, payload: { loading: false } });
    }
  }
}

export function* formModalSaga() {
  while (true) {
    let formModalRefreshSagaI;
    const toggle = yield take(TOGGLE_MODAL_SUCCESS);
    if (formModalRefreshSagaI) {
      yield cancel(formModalRefreshSagaI);
    }

    if (toggle.payload.actionTypePart) {
      const { actionTypePart }: any = toggle.payload;
      formModalRefreshSagaI = yield fork(formModalRefreshSaga, { actionTypePart });
    }
  }
}

export default formModalSaga;
