import { CallApi } from '@factorymarket/mg-react-components';

const { callApiAction } = CallApi;

export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'GET_ME_FAILRUE';

export const getMe = () =>
  callApiAction({
    method: 'get',
    url: '/user/current',
    types: [GET_ME_REQUEST, GET_ME_SUCCESS, GET_ME_FAILURE]
  });

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const registerUser = ({ formData }: { formData: any }) =>
  callApiAction({
    method: 'post',
    url: '/user',
    types: [
      REGISTER_USER_REQUEST,
      {
        type: REGISTER_USER_SUCCESS,
        meta: formData
      },
      REGISTER_USER_FAILURE
    ],
    body: formData
  });

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = ({ formData }: { formData: any }) =>
  callApiAction({
    method: 'put',
    url: '/user/change-password',
    types: [
      CHANGE_PASSWORD_REQUEST,
      {
        type: CHANGE_PASSWORD_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            message: 'passwordChanged',
            needTranslate: true
          }
        }
      },
      CHANGE_PASSWORD_FAILURE
    ],
    body: { ...formData }
  });
