// Common variables
export const APP_ENV = window.ENV.APP_ENV || 'localhost';
export const API_URL = window.ENV.API_URL || 'https://api-test-mg.ilogs.com';
// export const API_URL = window.ENV.API_URL || 'http://mac-geiz.local';
export const API_VERSION = window.ENV.API_VERSION || '1.3.0.0';

export const API_URL_V1 = `${API_URL}/api/${API_VERSION}`;

// Sentry
// export const SENTRY_DSN =
//   window.SENTRY_DSN || 'https://36a6e1fed6eb43428278ea551958205c@sentry.lw.factorymarket.tech/24';
