import { AnyActionType } from 'types/index';

import {
  GET_VOUCHERS_REQUEST,
  GET_VOUCHERS_SUCCESS,
  GET_VOUCHERS_FAILURE,
  GET_VOUCHER_REQUEST,
  GET_VOUCHER_SUCCESS,
  GET_VOUCHER_FAILURE,
  EDIT_VOUCHER_SUCCESS,
  BIND_PRODUCT_TO_VOUCHER,
  UNBIND_PRODUCT_TO_VOUCHER,
  UNBIND_USER_SEGMENT_TO_VOUCHER,
  BIND_USER_SEGMENT_TO_VOUCHER,
  GET_VOUCHER_TERM_REQUEST,
  GET_VOUCHER_TERM_SUCCESS,
  GET_VOUCHER_TERM_FAILURE
} from './actions';

const INITIAL_STATE: any = {
  vouchers: [],
  currentVoucher: { images: [], products: [] },
  loadingCurrentVoucher: false,
  error: null,
  paginator: {},
  loading: false,
  loadingTerms: false,
  terms: [],
  vouchersMetadata: {
    discountApplyTypes: [{ value: 'all', name: 'all' }],
    discountTypes: [{ value: 'percent', name: 'percent' }, { value: 'fixed', name: 'fixed' }],
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }],
    assignedUserTypes: [
      { value: 1, name: 'App' },
      { value: 2, name: 'Offline' },
      { value: 3, name: 'App + Offline' }
    ],
    eanTypes: [{ value: 'ean13', name: 'EAN 13' }, { value: 'ean128', name: 'EAN 128' }]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentVoucher: INITIAL_STATE.currentVoucher,
        loadingCurrentVoucher: INITIAL_STATE.loadingCurrentVoucher
      };
    case BIND_PRODUCT_TO_VOUCHER:
      return {
        ...state,
        currentVoucher: {
          ...state.currentVoucher,
          products: [...state.currentVoucher.products, { id: action.payload.entityId }]
        }
      };
    case UNBIND_PRODUCT_TO_VOUCHER:
      return {
        ...state,
        currentVoucher: {
          ...state.currentVoucher,
          products: state.currentVoucher.products.filter(
            (item: any) => item.id !== action.payload.entityId
          )
        }
      };
    case BIND_USER_SEGMENT_TO_VOUCHER:
      return {
        ...state,
        currentVoucher: {
          ...state.currentVoucher,
          userSegments: [...state.currentVoucher.userSegments, { id: action.payload.entityId }]
        }
      };
    case UNBIND_USER_SEGMENT_TO_VOUCHER:
      return {
        ...state,
        currentVoucher: {
          ...state.currentVoucher,
          userSegments: state.currentVoucher.userSegments.filter(
            (item: any) => item.id !== action.payload.entityId
          )
        }
      };
    case GET_VOUCHER_TERM_REQUEST:
      return { ...state, terms: [], paginator: {}, loadingTerms: true };
    case GET_VOUCHER_TERM_SUCCESS:
      return {
        ...state,
        loadingTerms: false,
        terms: action.payload.content.items,
        error: null
      };
    case GET_VOUCHER_TERM_FAILURE:
      return { ...state, loadingTerms: false };
    case GET_VOUCHERS_REQUEST:
      return { ...state, vouchers: [], paginator: {}, loading: true };
    case GET_VOUCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        vouchers: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_VOUCHERS_FAILURE:
      return { ...state, loading: false };
    case GET_VOUCHER_REQUEST:
      return { ...state, loadingCurrentVoucher: true };
    case GET_VOUCHER_FAILURE:
      return { ...state, loadingCurrentVoucher: false };
    case GET_VOUCHER_SUCCESS:
    case EDIT_VOUCHER_SUCCESS:
      return { ...state, currentVoucher: action.payload.content, loadingCurrentVoucher: false };
    default:
      return state;
  }
}
