export const TOGGLE_MODAL_SUCCESS = 'TOGGLE_MODAL_SUCCESS';

export function toggleModal({
  actionTypePart,
  isOpened,
  id
}: {
  actionTypePart?: string;
  isOpened: boolean;
  id?: string;
}) {
  return {
    type: TOGGLE_MODAL_SUCCESS,
    payload: {
      actionTypePart,
      isOpened,
      id
    }
  };
}

export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';
