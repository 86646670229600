import { API_URL_V1 } from 'config/env';

export const LOAD_CONFIG_SUCCESS = 'LOAD_CONFIG_SUCCESS';

export const loadConfig = () => ({
  type: LOAD_CONFIG_SUCCESS,
  payload: { apiRoot: API_URL_V1 }
});

export const SET_CONFIG = 'SET_CONFIG';

export const setConfig = (config: Object) => ({
  type: SET_CONFIG,
  payload: config
});
