import { CallApi } from '@factorymarket/mg-react-components';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_USER_EMAILS_REQUEST = 'GET_USER_EMAILS_REQUEST';
export const GET_USER_EMAILS_SUCCESS = 'GET_USER_EMAILS_SUCCESS';
export const GET_USER_EMAILS_FAILURE = 'GET_USER_EMAILS_FAILURE';

export const getUserEmails = ({ userId }: { userId: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user/${userId}/email/list?json={"perPage": 499, "page": 1}`,
    types: [GET_USER_EMAILS_REQUEST, GET_USER_EMAILS_SUCCESS, GET_USER_EMAILS_FAILURE]
  });
