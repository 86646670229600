import moment from 'moment';
import { CurrentNotificationType, NotificationMediaType } from 'types/notification';
import React from 'react';

export const notificationRequestToApi = (formData: any): CurrentNotificationType => {
  const updatedFormData = {
    ...formData,
    id: formData.id && parseInt(formData.id, 10),
    active: formData.active || false,
    recStart:
      (formData.recStart && parseInt(moment(formData.recStart).format('X'), 10)) || undefined,
    recEnd: (formData.recEnd && parseInt(moment(formData.recEnd).format('X'), 10)) || undefined
  };
  return updatedFormData;
};

export const getNotificationMediaFromNotification = ({
  mediaArr,
  mediaId
}: {
  mediaArr: NotificationMediaType[];
  mediaId: number;
}): NotificationMediaType | undefined => {
  let result;
  const searchNotificationMedia = (notificationMediaData: {
    mediaArr: NotificationMediaType[];
    mediaId: number;
  }) =>
    notificationMediaData.mediaArr.forEach((item: NotificationMediaType) => {
      if (item.id === mediaId) {
        result = item;
      } // else if (item.children && item.children.length) {
      // searchNotificationMedia({ mediaArr: item.children, mediaId });
      // }
    });
  searchNotificationMedia({ mediaArr, mediaId });

  return result;
};

export default { notificationRequestToApi, getNotificationMediaFromNotification };
