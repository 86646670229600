import { CampaignType } from 'types/campaigns';
import { CallApi } from '@factorymarket/mg-react-components';
import { campaignRequestToApi } from './adapter';

const { callApiAction } = CallApi;

export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAILURE = 'GET_CAMPAIGNS_FAILURE';

export const getCampaigns = ({ filter }: { filter: any }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/campaign?json=${JSON.stringify(filter)}`,
    types: [GET_CAMPAIGNS_REQUEST, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAILURE]
  });

export const GET_CAMPAIGN_REQUEST = 'GET_CAMPAIGN_REQUEST';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'GET_CAMPAIGN_FAILURE';

export const getCampaign = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/campaign/${id}`,
    types: [GET_CAMPAIGN_REQUEST, GET_CAMPAIGN_SUCCESS, GET_CAMPAIGN_FAILURE]
  });

export const EDIT_CAMPAIGN_REQUEST = 'EDIT_CAMPAIGN_REQUEST';
export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAILURE = 'EDIT_CAMPAIGN_FAILURE';

export const editCampaign = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: CampaignType;
  filter?: object;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/loyalty/campaign/${id}`,
    types: [
      {
        type: EDIT_CAMPAIGN_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_CAMPAIGN_SUCCESS,
        meta: { notification: { type: 'success', message: 'Campaign successfully updated!' } }
      },
      EDIT_CAMPAIGN_FAILURE
    ],
    body: campaignRequestToApi(formData)
  });

export const ADD_CAMPAIGN_REQUEST = 'ADD_CAMPAIGN_REQUEST';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAILURE = 'ADD_CAMPAIGN_FAILURE';

export const addCampaign = ({ formData }: { formData: CampaignType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/campaign`,
    types: [
      ADD_CAMPAIGN_REQUEST,
      {
        type: ADD_CAMPAIGN_SUCCESS,
        meta: { notification: { type: 'success', message: 'Campaign successfully added!' } }
      },
      ADD_CAMPAIGN_FAILURE
    ],
    body: campaignRequestToApi(formData)
  });

export const ADD_CAMPAIGN_VOUCHER_REQUEST = 'ADD_CAMPAIGN_VOUCHER_REQUEST';
export const ADD_CAMPAIGN_VOUCHER_SUCCESS = 'ADD_CAMPAIGN_VOUCHER_SUCCESS';
export const ADD_CAMPAIGN_VOUCHER_FAILURE = 'ADD_CAMPAIGN_VOUCHER_FAILURE';

export const addCampaignVoucher = ({ formData, payload }: { formData: any; payload: Function }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/campaign/voucher`,
    types: [
      ADD_CAMPAIGN_VOUCHER_REQUEST,
      {
        type: ADD_CAMPAIGN_VOUCHER_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Voucher successfully added to campaign!' }
        },
        payload: (data: any) => {
          payload();
          return data;
        }
      },
      ADD_CAMPAIGN_VOUCHER_FAILURE
    ],
    body: formData
  });

export const EDIT_CAMPAIGN_VOUCHER_REQUEST = 'EDIT_CAMPAIGN_VOUCHER_REQUEST';
export const EDIT_CAMPAIGN_VOUCHER_SUCCESS = 'EDIT_CAMPAIGN_VOUCHER_SUCCESS';
export const EDIT_CAMPAIGN_VOUCHER_FAILURE = 'EDIT_CAMPAIGN_VOUCHER_FAILURE';

export const editCampaignVoucher = ({
  formData,
  id,
  payload
}: {
  formData: any;
  id: number;
  payload: Function;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/loyalty/campaign/voucher/${id}`,
    types: [
      EDIT_CAMPAIGN_VOUCHER_REQUEST,
      {
        type: EDIT_CAMPAIGN_VOUCHER_SUCCESS,
        meta: { notification: { type: 'success', message: 'Voucher successfully updated!' } },
        payload: (data: any) => {
          payload();
          return data;
        }
      },
      EDIT_CAMPAIGN_VOUCHER_FAILURE
    ],
    body: formData
  });

export const DELETE_CAMPAIGN_VOUCHER_REQUEST = 'DELETE_CAMPAIGN_VOUCHER_REQUEST';
export const DELETE_CAMPAIGN_VOUCHER_SUCCESS = 'DELETE_CAMPAIGN_VOUCHER_SUCCESS';
export const DELETE_CAMPAIGN_VOUCHER_FAILURE = 'DELETE_CAMPAIGN_VOUCHER_FAILURE';

export const deleteCampaignVoucher = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/loyalty/campaign/voucher/${id}`,
    types: [
      DELETE_CAMPAIGN_VOUCHER_REQUEST,
      {
        type: DELETE_CAMPAIGN_VOUCHER_SUCCESS,
        meta: { notification: { type: 'success', message: 'Voucher successfully removed!' } }
      },
      DELETE_CAMPAIGN_VOUCHER_FAILURE
    ]
  });
