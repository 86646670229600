import { select, put, take, call, race } from 'redux-saga/effects';
import {
  logoutUser,
  refreshToken,
  loginUser,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE
} from 'redux/actions/auth';
import { setToken, clearTokenHeader } from 'utils/api';
import { auth } from 'redux/reducers/selectors';
import { reloadLastRequests } from './apiErrorSaga';

export const refreshTokenSagaScope = 'refreshTokenSaga';
export const NEED_REFRESH = `${refreshTokenSagaScope}/NEED_REFRESH`;

export function* reloadTokenSaga() {
  const authObject = yield select(auth);
  clearTokenHeader();
  yield put(
    refreshToken({
      formData: {
        refreshToken: authObject.token.refreshToken
      }
    })
  );

  const { success, failure } = yield race({
    success: take(REFRESH_TOKEN_SUCCESS),
    failure: take(REFRESH_TOKEN_FAILURE)
  });

  if (success) {
    const { user, store } = yield select(auth);
    setToken(success.payload.content.accessToken);
    yield put(loginUser({ user, token: success.payload.content, store }));
    return success;
  }

  if (failure) {
    yield put(logoutUser());
    return new Error(failure);
  }

  return null;
}

export function* tokenSaga() {
  while (true) {
    const action = yield take(NEED_REFRESH);
    const authObject = yield select(auth);
    if (authObject.token && authObject.token.refreshToken) {
      try {
        yield call(reloadTokenSaga);
        if (action.pathogen.type !== REFRESH_TOKEN_FAILURE) yield reloadLastRequests(-5, 0);
      } catch (error) {
        console.log(error);
      }
    } else if (
      action.pathogen &&
      action.pathogen.payload &&
      action.pathogen.payload &&
      (action.pathogen.payload.status === 401 || action.pathogen.payload.status === 403)
    ) {
      yield put(logoutUser());
    }
  }
}

export default tokenSaga;
