import { take, select, put } from 'redux-saga/effects';
import { auth } from 'redux/reducers/selectors';
import { CHANGE_LANG } from 'redux/actions/auth';
import { setLangHeader } from 'utils/api';
import { setActiveLanguage, initialize } from 'react-localize-redux';
import translate from 'i18n/translate.json';
import { renderToStaticMarkup } from 'react-dom/server';

export function* checkLangChangeSaga() {
  while (true) {
    const lang = yield take(CHANGE_LANG);
    setLangHeader(lang.payload);
    yield put(setActiveLanguage(lang.payload));
  }
}

export default function* langSaga() {
  const authObject = yield select(auth);
  const defaultLang = authObject.lang ? authObject.lang : 'de';
  setLangHeader(defaultLang);
  yield put(
    initialize({
      languages: [{ name: 'English', code: 'en' }, { name: 'German', code: 'de' }],
      translation: translate,
      options: {
        renderToStaticMarkup,
        // showMissingTranslationMsg: true,
        onMissingTranslation: () => ''
      }
    })
  );
  yield put(setActiveLanguage(defaultLang));
}
