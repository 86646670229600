import { AnyActionType } from 'types/index';

import {
  GET_PRODUCT_IMPORT_VALIDATION_ERRORS_REQUEST,
  GET_PRODUCT_IMPORT_VALIDATION_ERRORS_SUCCESS
} from 'views/page/Products/Import/actions';
import {
  // GET_REVIEWS_IMPORT_REQUEST,
  GET_REVIEWS_IMPORT_SUCCESS
} from './actions';


const INITIAL_STATE = {
  errors: undefined,
  imports: [],
  paginator: {},
  loading: false,
  metadata: {
    statuses: [
      { value: 0, name: 'New' },
      { value: 1, name: 'In progress' },
      { value: 2, name: 'Done' },
      { value: 3, name: 'Failed' }
    ]
  }
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case GET_REVIEWS_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        imports: action.payload.content.items,
        // errors: undefined,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
      case GET_PRODUCT_IMPORT_VALIDATION_ERRORS_REQUEST:
        return { ...state, errors: undefined };
      case GET_PRODUCT_IMPORT_VALIDATION_ERRORS_SUCCESS:
        return { ...state, errors: action.payload };
  
    default:
      return state;
  }
}
