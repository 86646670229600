import moment from 'moment';
import { LoyaltyVoucherAddType } from 'types/vouchers';
import { CurrentProductType } from 'src/types/product';

export const priceFromServer = (price: number) => (price / 100).toFixed(2);
export const priceToServer = (price: number) => parseInt((price * 100).toFixed(0), 10);

export const voucherRequestToApi = (formData: any): LoyaltyVoucherAddType => ({
  ...formData,
  price: formData.price && priceToServer(formData.price),
  strikedPrice: formData.strikedPrice && priceToServer(formData.strikedPrice),
  dateFrom: formData.dateFrom && parseInt(moment(formData.dateFrom).format('X'), 10),
  dateTo: formData.dateTo && parseInt(moment(formData.dateTo).format('X'), 10)
});

export const voucherRequestFromApi = (formData: any): LoyaltyVoucherAddType => ({
  ...formData,
  content: {
    ...formData.content,
    price: formData.content.price ? priceFromServer(formData.content.price) : null,
    strikedPrice: formData.content.strikedPrice
      ? priceFromServer(formData.content.strikedPrice)
      : null
  }
});

export const filterToApi = (filter: any): any => {
  let publishedOnly;
  if (filter.publishedOnly) {
    publishedOnly = filter.publishedOnly === 'true';
  }
  return {
    ...filter,
    publishedOnly,
    campaignId: filter.campaignId !== undefined ? parseInt(filter.campaignId, 10) : undefined,
    status: filter.status ? parseInt(filter.status, 10) : undefined,
    assignedUserType: filter.assignedUserType ? parseInt(filter.assignedUserType, 10) : undefined,
    segmentId: filter.segmentId !== undefined ? parseInt(filter.segmentId, 10) : undefined
  };
};

export default { voucherRequestToApi, filterToApi };
