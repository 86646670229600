import { AnyAction } from 'redux';
import { TOGGLE_MODAL_SUCCESS, SET_MODAL_LOADING } from './actions';

const DEFAULT_STATE = {
  isOpened: false,
  loading: false,
  id: undefined
};

export default function(state = DEFAULT_STATE, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_MODAL_SUCCESS:
      return { ...state, isOpened: action.payload.isOpened, id: action.payload.id };
    case SET_MODAL_LOADING:
      return { ...state, loading: action.payload.loading };
    default:
      return { ...state };
  }
}
