// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RoleRouteComponent } from '@factorymarket/mg-react-components';
import { mainPagePath } from 'utils/auth';
import { proceedRolesForCurrentRoles } from './normalize';

type Props = {
  auth: {
    authenticated?: boolean;
    user?: {
      roles: [];
    };
  };
  key: string;
  path: string;
  redirectRoute: string;
  exact: boolean;
  component: any;
  roles: Array<Object>;
  accessDenied: Function;
  setNotification: Function;
};

class RoleRouteContainer extends PureComponent<Props> {
  deniedHandle = (props: any) => {
    const { accessDenied, setNotification } = this.props;
    console.warn('--- Access Denied', props);
    accessDenied(props);
    setNotification({
      type: 'error',
      message: "You don't have permission"
    });
  };

  render() {
    const { auth, key, path, roles, component, exact, redirectRoute } = this.props;
    const currentUserRoles =
      auth && auth.authenticated && auth.user && auth.user.roles ? auth.user.roles : ['ROLE_GUEST'];
    return (
      <RoleRouteComponent
        currentRoles={proceedRolesForCurrentRoles(currentUserRoles)}
        key={key}
        path={path}
        roles={roles}
        component={component}
        exact={exact}
        redirectRoute={redirectRoute || mainPagePath}
        // onDenied={() => this.deniedHandle({ currentUserRoles, roles, path })} // update mg-react-components fro fm-operation
      />
    );
  }
}

export default connect(({ auth }: any) => ({ auth }))(RoleRouteContainer);
