import { take, put, race, fork, cancelled } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'react-router-redux';
import variantSaga from '../Variant/saga';
import {
  ADD_PRODUCT_SUCCESS,
  getVariants,
  getProduct,
  getProductValidationErrors,
  GET_PRODUCT_SUCCESS,
  EDIT_VARIANT_SUCCESS,
  ADD_VARIANT_SUCCESS,
  EDIT_VARIANT_IMAGE_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  ADD_IMAGE_TO_VARIANT_SUCCESS
} from '../actions';

import {
  ADD_ALSO_BOUGHT_PRODUCTS_SUCCESS,
  EDIT_ALSO_BOUGHT_PRODUCTS_SUCCESS,
  DELETE_ALSO_BOUGHT_PRODUCTS_SUCCESS,
  getAlsoBoughtProducts
} from '../AlsoBougth/actions';

import {
  ADD_SIMILAR_PRODUCTS_SUCCESS,
  EDIT_SIMILAR_PRODUCTS_SUCCESS,
  DELETE_SIMILAR_PRODUCTS_SUCCESS,
  getSimilarProducts
} from '../Similar/actions';

export function* productErrorSaga({ id }: { id: any }) {
  while (true) {
    const { getProductSuccess, editProductSuccess, addImageSuccess } = yield race({
      getProductSuccess: take(GET_PRODUCT_SUCCESS),
      editProductSuccess: take(EDIT_PRODUCT_SUCCESS),
      addImageSuccess: take(ADD_IMAGE_TO_VARIANT_SUCCESS)
    });
    if (getProductSuccess || editProductSuccess || addImageSuccess) {
      yield put(getProductValidationErrors({ id }));
    }
  }
}

export function* alsoBougthSaga({ productId }: { productId: number }) {
  yield put(getAlsoBoughtProducts({ productId }));
  while (true) {
    const { deleteAlsoBought, editAlsoBought, addAlsoBought } = yield race({
      deleteAlsoBought: take(DELETE_ALSO_BOUGHT_PRODUCTS_SUCCESS),
      editAlsoBought: take(EDIT_ALSO_BOUGHT_PRODUCTS_SUCCESS),
      addAlsoBought: take(ADD_ALSO_BOUGHT_PRODUCTS_SUCCESS)
    });
    if (deleteAlsoBought || editAlsoBought || addAlsoBought) {
      yield put(getAlsoBoughtProducts({ productId }));
    }
  }
}

export function* similarSaga({ productId }: { productId: number }) {
  yield put(getSimilarProducts({ productId }));
  while (true) {
    const { deleteSimilar, editSimilar, addSimilar } = yield race({
      deleteSimilar: take(DELETE_SIMILAR_PRODUCTS_SUCCESS),
      editSimilar: take(EDIT_SIMILAR_PRODUCTS_SUCCESS),
      addSimilar: take(ADD_SIMILAR_PRODUCTS_SUCCESS)
    });
    if (deleteSimilar || editSimilar || addSimilar) {
      yield put(getSimilarProducts({ productId }));
    }
  }
}

export function* productShowSaga({ id }: { id: any }) {
  yield take(LOCATION_CHANGE);
  yield put(getProduct({ id }));
  yield fork(variantSaga, { id });
  yield fork(productErrorSaga, { id });
  yield fork(alsoBougthSaga, { productId: id });
  yield fork(similarSaga, { productId: id });
  yield put(getVariants({ id }));

  while (true) {
    const { editVariant, addVariant, editVariantImage, locationChange } = yield race({
      addVariant: take(ADD_VARIANT_SUCCESS),
      editVariant: take(EDIT_VARIANT_SUCCESS),
      editVariantImage: take(EDIT_VARIANT_IMAGE_SUCCESS),
      locationChange: take(LOCATION_CHANGE)
    });
    if (editVariant || addVariant || editVariantImage) {
      yield put(getProduct({ id }));
      yield put(getVariants({ id }));
    }
    if (locationChange) {
      cancelled();
    }
  }
}

export function* productEditSaga({ id }: { id: any }) {
  yield take(LOCATION_CHANGE);
  yield put(getProduct({ id }));
  yield fork(variantSaga, { id });
  yield fork(productErrorSaga, { id });
  yield fork(alsoBougthSaga, { productId: id });
  yield fork(similarSaga, { productId: id });
  yield put(getVariants({ id }));

  while (true) {
    const { editVariant, addVariant, editVariantImage, locationChange } = yield race({
      addVariant: take(ADD_VARIANT_SUCCESS),
      editVariant: take(EDIT_VARIANT_SUCCESS),
      editVariantImage: take(EDIT_VARIANT_IMAGE_SUCCESS),
      locationChange: take(LOCATION_CHANGE)
    });
    if (editVariant || addVariant || editVariantImage) {
      yield put(getProduct({ id }));
      yield put(getVariants({ id }));
    }
    if (locationChange) {
      cancelled();
    }
  }
}

export function* productAddSaga() {
  while (true) {
    const { addProduct } = yield race({
      addProduct: take(ADD_PRODUCT_SUCCESS)
    });
    if (addProduct) {
      yield put(push(`/product/edit/${addProduct.payload.content.product.id}`));
    }
  }
}
