import { CallApi } from '@factorymarket/mg-react-components';
import { getErrorsArr, filterToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_VOUCHERS_IMPORT_REQUEST = 'GET_VOUCHERS_IMPORT_REQUEST';
export const GET_VOUCHERS_IMPORT_SUCCESS = 'GET_VOUCHERS_IMPORT_SUCCESS';
export const GET_VOUCHERS_IMPORT_FAILURE = 'GET_VOUCHERS_IMPORT_FAILURE';

export const getVouchersImport = ({
  filter = { page: 1, perPage: 30 }
}: {
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/voucher/import/list?json=${JSON.stringify(filter && filterToApi(filter))}`,
    types: [GET_VOUCHERS_IMPORT_REQUEST, GET_VOUCHERS_IMPORT_SUCCESS, GET_VOUCHERS_IMPORT_FAILURE]
  });

export const GET_VOUCHER_IMPORT_VALIDATION_ERRORS_REQUEST = 'GET_PRODUCT_VALIDATION_ERRORS_REQUEST';
export const GET_VOUCHER_IMPORT_VALIDATION_ERRORS_SUCCESS =
  'GET_PRODUCT_IMPORT_VALIDATION_ERRORS_SUCCESS';
export const GET_VOUCHER_IMPORT_VALIDATION_ERRORS_FAILURE =
  'GET_PRODUCT_IMPORT_VALIDATION_ERRORS_FAILURE';

export const getVoucherImportValidationErrors = ({ id, level }: { id: number; level: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/import/${id}/errors?json={"level": ${level}}`,
    types: [
      GET_VOUCHER_IMPORT_VALIDATION_ERRORS_REQUEST,
      {
        type: GET_VOUCHER_IMPORT_VALIDATION_ERRORS_SUCCESS,
        payload: (data: any) => getErrorsArr(data.content.items)
      },
      GET_VOUCHER_IMPORT_VALIDATION_ERRORS_FAILURE
    ]
  });

export const ADD_VOUCHERS_IMPORT_REQUEST = 'ADD_VOUCHERS_IMPORT_REQUEST';
export const ADD_VOUCHERS_IMPORT_SUCCESS = 'ADD_VOUCHERS_IMPORT_SUCCESS';
export const ADD_VOUCHERS_IMPORT_FAILURE = 'ADD_VOUCHERS_IMPORT_FAILURE';

export const addVouchersImport = ({
  uploadId,
  delimiter
}: {
  uploadId: number;
  delimiter: string;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/voucher/import`,
    types: [
      ADD_VOUCHERS_IMPORT_REQUEST,
      {
        type: ADD_VOUCHERS_IMPORT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Upload success!' } }
      },
      ADD_VOUCHERS_IMPORT_FAILURE
    ],
    body: {
      uploadId,
      delimiter
    }
  });
