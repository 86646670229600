import { EditGroupRequestType, CreateGroupRequestType } from 'types/groups';
import { CallApi } from '@factorymarket/mg-react-components';

const { callApiAction } = CallApi;

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const getGroups = ({ filter }: { filter?: any }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product-group/list?json=${JSON.stringify(filter)}`,
    types: [GET_GROUPS_REQUEST, GET_GROUPS_SUCCESS, GET_GROUPS_FAILURE]
  });

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';

export const getGroup = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product-group/${id}`,
    types: [GET_GROUP_REQUEST, GET_GROUP_SUCCESS, GET_GROUP_FAILURE]
  });

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE';

export const editGroup = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: EditGroupRequestType;
  filter?: object;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product-group/${id}`,
    types: [
      {
        type: EDIT_GROUP_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_GROUP_SUCCESS,
        meta: { notification: { type: 'success', message: 'Group successfully updated!' } }
      },
      EDIT_GROUP_FAILURE
    ],
    body: formData
  });

export const ADD_GROUP_REQUEST = 'ADD_GROUP_REQUEST';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';

export const addGroup = ({
  formData,
  filter
}: {
  formData: CreateGroupRequestType;
  filter?: CreateGroupRequestType;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product-group`,
    types: [
      {
        type: ADD_GROUP_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: ADD_GROUP_SUCCESS,
        meta: { notification: { type: 'success', message: 'Group successfully added!' } }
      },
      ADD_GROUP_FAILURE
    ],
    body: formData
  });

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const deleteGroup = ({ id, filter }: { id: number; filter: any }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/product-group/${id}`,
    types: [
      DELETE_GROUP_REQUEST,
      {
        type: DELETE_GROUP_SUCCESS,
        meta: {
          filter
        }
      },
      DELETE_GROUP_FAILURE
    ]
  });

export const ADD_PRODUCT_TO_GROUP_REQUEST = 'ADD_PRODUCT_TO_GROUP_REQUEST';
export const ADD_PRODUCT_TO_GROUP_SUCCESS = 'ADD_PRODUCT_TO_GROUP_SUCCESS';
export const ADD_PRODUCT_TO_GROUP_FAILURE = 'ADD_PRODUCT_TO_GROUP_FAILURE';

export const addProductToGroup = ({ id, productId }: { id: number; productId: number }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product-group/${id}/product-add`,
    types: [
      ADD_PRODUCT_TO_GROUP_REQUEST,
      {
        type: ADD_PRODUCT_TO_GROUP_SUCCESS,
        meta: {
          id,
          notification: { type: 'success', message: 'Product successfully added!' }
        }
      },
      ADD_PRODUCT_TO_GROUP_FAILURE
    ],
    body: { productId }
  });

  export const ADD_PRODUCT_TO_GROUP_BULK_REQUEST = 'ADD_PRODUCT_TO_GROUP_BULK_REQUEST';
export const ADD_PRODUCT_TO_GROUP_BULK_SUCCESS = 'ADD_PRODUCT_TO_GROUP_BULK_SUCCESS';
export const ADD_PRODUCT_TO_GROUP_BULK_FAILURE = 'ADD_PRODUCT_TO_GROUP_BULK_FAILURE';

export const addProductBulkToGroup = ({ id, productIds }: { id: number; productIds: number[] }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product-group/${id}/product-add-bulk`,
    types: [
      ADD_PRODUCT_TO_GROUP_BULK_REQUEST,
      {
        type: ADD_PRODUCT_TO_GROUP_BULK_SUCCESS,
        meta: {
          id,
          notification: { type: 'success', message: 'Products successfully added!' }
        }
      },
      ADD_PRODUCT_TO_GROUP_BULK_FAILURE
    ],
    body: {
      "skuList": productIds
    }
  });

export const REMOVE_PRODUCT_FROM_GROUP_REQUEST = 'REMOVE_PRODUCT_FROM_GROUP_REQUEST';
export const REMOVE_PRODUCT_FROM_GROUP_SUCCESS = 'REMOVE_PRODUCT_FROM_GROUP_SUCCESS';
export const REMOVE_PRODUCT_FROM_GROUP_FAILURE = 'REMOVE_PRODUCT_FROM_GROUP_FAILURE';

export const removeProductFromGroup = ({ id, productId }: { id: number; productId: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/product-group/${id}/product-delete`,
    types: [
      REMOVE_PRODUCT_FROM_GROUP_REQUEST,
      {
        type: REMOVE_PRODUCT_FROM_GROUP_SUCCESS,
        meta: {
          id,
          notification: { type: 'success', message: 'Product successfully removed!' }
        }
      },
      REMOVE_PRODUCT_FROM_GROUP_FAILURE
    ],
    body: { productId }
  });
