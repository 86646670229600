import { AnyActionType } from 'types/index';

import {
  GET_PIMIMPORT_ERROR_REQUEST,
  GET_PIMIMPORT_ERROR_SUCCESS,
  GET_PIMIMPORT_ERROR_FAILURE
} from '../actions';

const INITIAL_STATE: any = {
  errormessages: [],
  error: null,
  paginator: {},
  loading: false
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state
      };
    case GET_PIMIMPORT_ERROR_REQUEST:
      return { ...state, massages: [], paginator: {}, loading: true };
    case GET_PIMIMPORT_ERROR_SUCCESS:
      return {
        ...state,
        loading: false,
        errormessages: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_PIMIMPORT_ERROR_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
