import { AnyActionType } from 'types/index';

import { GET_DASHBOARDS_SUCCESS } from './actions';

const INITIAL_STATE: any = {
  dashboards: undefined
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case GET_DASHBOARDS_SUCCESS:
      return { ...state, dashboards: action.payload.content };
    default:
      return state;
  }
}
