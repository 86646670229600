import { takeEvery, put, all } from 'redux-saga/effects';
import { getErrorMessage } from '@factorymarket/mg-utils';
import { AnyActionType } from 'types/index';
import { NEED_REFRESH } from './refreshTokenSaga';

let requestBuffer: AnyActionType[] = [];

export function* reloadLastRequests(number: number, offset: number) {
  const lastActions = requestBuffer.slice(offset, -number);
  yield all(lastActions.map(item => put(item)));
}

function* apiErrorSaga(action: AnyActionType) {
  if (action.type.includes('_REQUEST') && action.type !== 'REFRESH_TOKEN_REQUEST') {
    // Cut capacity of Array
    if (requestBuffer.length >= 5) requestBuffer.splice(0, 1);

    requestBuffer = [...requestBuffer, action];
  }

  if (action.payload && action.payload.status) {
    const statusCode = action.payload.status;
    const errorMessage = getErrorMessage(action.payload.data);

    switch (statusCode) {
      case 401:
        // use NEED_REFRESH action to to straighten refreshTokenSaga & prevent multiple token request
        yield put({ type: NEED_REFRESH, pathogen: action });
        break;

      case 403:
        yield put({ type: NEED_REFRESH, pathogen: action });
        break;

      default:
        break;
    }
  }
}

function* apiErrorSagaWatcher() {
  yield takeEvery('*', apiErrorSaga);
}

export default apiErrorSagaWatcher;
