import { AnyActionType } from 'types/index';
import {
  GET_USER_SEGMENTS_REQUEST,
  GET_USER_SEGMENTS_SUCCESS,
  GET_USER_SEGMENTS_FAILURE,
  GET_USER_SEGMENT_REQUEST,
  GET_USER_SEGMENT_SUCCESS,
  GET_USER_SEGMENT_FAILURE,
  EDIT_USER_SEGMENT_SUCCESS
} from './actions';

const INITIAL_STATE = {
  userSegments: [],
  currentUserSegment: {},
  loadingCurrentUserSegment: false,
  paginator: {},
  loading: false
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case GET_USER_SEGMENTS_REQUEST:
      return { ...state, userSegments: [], paginator: {}, loading: true };
    case GET_USER_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userSegments: action.payload.content.items,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_USER_SEGMENTS_FAILURE:
      return { ...state, loading: false };
    case GET_USER_SEGMENT_REQUEST:
    case GET_USER_SEGMENT_FAILURE:
      return { ...state, loadingCurrentUserSegment: false };
    case GET_USER_SEGMENT_SUCCESS:
    case EDIT_USER_SEGMENT_SUCCESS:
      return {
        ...state,
        currentUserSegment: action.payload.content,
        loadingCurrentUserSegment: false
      };
    default:
      return state;
  }
}
