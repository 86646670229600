import { CallApi } from '@factorymarket/mg-react-components';
import { CurrentNotificationType, NotificationMediaType } from 'types/notification';
import { notificationRequestToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const getNotifications = ({ filter }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/notification/list?json=${JSON.stringify({
      ...filter,
      id: filter && filter.id && parseInt(filter.id, 10)
    })}`,
    types: [GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAILURE]
  });

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const getNotification = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/notification/${id}`,
    types: [GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE]
  });

export const EDIT_NOTIFICATION_REQUEST = 'EDIT_NOTIFICATION_REQUEST';
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS';
export const EDIT_NOTIFICATION_FAILURE = 'EDIT_NOTIFICATION_FAILURE';

export const editNotification = ({
  notificationId,
  formData,
  filter
}: {
  notificationId: string;
  formData: CurrentNotificationType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/notification/${notificationId}`,
    types: [
      EDIT_NOTIFICATION_REQUEST,
      {
        type: EDIT_NOTIFICATION_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Notification successfully updated!' },
          filter
        }
      },
      EDIT_NOTIFICATION_FAILURE
    ],
    body: notificationRequestToApi(formData)
  });

export const ADD_NOTIFICATION_REQUEST = 'ADD_NOTIFICATION_REQUEST';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILURE = 'ADD_NOTIFICATION_FAILURE';

export const addNotification = ({ formData }: { formData: CurrentNotificationType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/notification`,
    types: [
      ADD_NOTIFICATION_REQUEST,
      {
        type: ADD_NOTIFICATION_SUCCESS,
        meta: { notification: { type: 'success', message: 'Notification successfully added!' } }
      },
      ADD_NOTIFICATION_FAILURE
    ],
    body: notificationRequestToApi(formData)
  });

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export const sendNotification = ({
  notificationId,
  formData,
  filter
}: {
  notificationId: string;
  formData: CurrentNotificationType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/notification/${notificationId}/send`,
    types: [
      SEND_NOTIFICATION_REQUEST,
      {
        type: SEND_NOTIFICATION_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Notification successfully sent!' },
          filter
        }
      },
      SEND_NOTIFICATION_FAILURE
    ],
    body: notificationRequestToApi(formData)
  });

export const SET_SELECTED_NOTIFICATIONS_COLLECTION = 'SET_SELECTED_NOTIFICATIONS_COLLECTION';

export const setSelectedNotificationsCollection = (collection: number[]) => ({
  type: SET_SELECTED_NOTIFICATIONS_COLLECTION,
  payload: collection
});

export const ADD_NOTIFICATION_MEDIA_REQUEST = 'ADD_NOTIFICATION_MEDIA_REQUEST';
export const ADD_NOTIFICATION_MEDIA_SUCCESS = 'ADD_NOTIFICATION_MEDIA_SUCCESS';
export const ADD_NOTIFICATION_MEDIA_FAILURE = 'ADD_NOTIFICATION_MEDIA_FAILURE';

export const addNotificationMedia = ({ formData }: { formData: NotificationMediaType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/notification-media`,
    types: [
      ADD_NOTIFICATION_MEDIA_REQUEST,
      {
        type: ADD_NOTIFICATION_MEDIA_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Notification Media successfully added!' }
        }
      },
      ADD_NOTIFICATION_MEDIA_FAILURE
    ],
    body: notificationRequestToApi(formData)
  });

export const EDIT_NOTIFICATION_MEDIA_REQUEST = 'EDIT_NOTIFICATION_MEDIA_REQUEST';
export const EDIT_NOTIFICATION_MEDIA_SUCCESS = 'EDIT_NOTIFICATION_MEDIA_SUCCESS';
export const EDIT_NOTIFICATION_MEDIA_FAILURE = 'EDIT_NOTIFICATION_MEDIA_FAILURE';

export const editNotificationMedia = ({
  formData,
  id
}: {
  formData: NotificationMediaType;
  id: number;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/notification-media/${id}`,
    types: [
      EDIT_NOTIFICATION_MEDIA_REQUEST,
      {
        type: EDIT_NOTIFICATION_MEDIA_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Notification Media successfully updated!' }
        }
      },
      EDIT_NOTIFICATION_MEDIA_FAILURE
    ],
    body: notificationRequestToApi(formData)
  });

export const DELETE_NOTIFICATION_MEDIA_MEDIA_REQUEST = 'DELETE_NOTIFICATION_MEDIA_MEDIA_REQUEST';
export const DELETE_NOTIFICATION_MEDIA_MEDIA_SUCCESS = 'DELETE_NOTIFICATION_MEDIA_MEDIA_SUCCESS';
export const DELETE_NOTIFICATION_MEDIA_MEDIA_FAILURE = 'DELETE_NOTIFICATION_MEDIA_MEDIA_FAILURE';

export const deleteNotificationMedia = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/notification-media/${id}`,
    types: [
      DELETE_NOTIFICATION_MEDIA_MEDIA_REQUEST,
      DELETE_NOTIFICATION_MEDIA_MEDIA_SUCCESS,
      DELETE_NOTIFICATION_MEDIA_MEDIA_FAILURE
    ]
  });
