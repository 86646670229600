import { CurrentProductType, CurrentVariantType, EanType } from 'types/product';
import { CallApi } from '@factorymarket/mg-react-components';
import { downloadBlobFile } from 'utils/helper';
import { uiFilterType } from './List/types';
import {
  productFromServer,
  productsFromServer,
  variantsFromServer,
  variantToServer,
  filterToApi
} from './adapter';

const { callApiAction } = CallApi;

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const getProducts = ({ filter }: { filter: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/list?json=${JSON.stringify(filterToApi(filter))}`,
    types: [
      GET_PRODUCTS_REQUEST,
      {
        type: GET_PRODUCTS_SUCCESS,
        payload: (data: any) => productsFromServer(data)
      },
      GET_PRODUCTS_FAILURE
    ]
  });

export const GET_PRODUCTS_CSV_REQUEST = 'GET_PRODUCTS_CSV_REQUEST';
export const GET_PRODUCTS_CSV_SUCCESS = 'GET_PRODUCTS_CSV_SUCCESS';
export const GET_PRODUCTS_CSV_FAILURE = 'GET_PRODUCTS_CSV_FAILURE';

export const getProductsCsv = ({ filter, payload }: { filter: uiFilterType; payload: Function }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/list/download?json=${JSON.stringify(filterToApi(filter))}`,
    types: [
      GET_PRODUCTS_CSV_REQUEST,
      {
        type: GET_PRODUCTS_CSV_SUCCESS,
        payload: (blob: Object) => {
          payload();
          downloadBlobFile({ filename: 'products.csv', blob });
        }
      },
      GET_PRODUCTS_CSV_FAILURE
    ]
  });

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const getProduct = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/${id}`,
    types: [
      GET_PRODUCT_REQUEST,
      {
        type: GET_PRODUCT_SUCCESS,
        payload: (data: any) => productFromServer(data)
      },
      GET_PRODUCT_FAILURE
    ]
  });

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE';

export const editProduct = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: CurrentProductType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/product/${id}`,
    types: [
      {
        type: EDIT_PRODUCT_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_PRODUCT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Product successfully updated!' } },
        payload: (data: any) => productFromServer(data)
      },
      EDIT_PRODUCT_FAILURE
    ],
    body: formData
  });

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const addProduct = ({
  formData,
  filter
}: {
  formData: CurrentProductType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/product`,
    types: [
      {
        type: ADD_PRODUCT_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: ADD_PRODUCT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Product successfully added!' } }
      },
      ADD_PRODUCT_FAILURE
    ],
    body: formData
  });

// variant

export const GET_VARIANTS_REQUEST = 'GET_VARIANTS_REQUEST';
export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const GET_VARIANTS_FAILURE = 'GET_VARIANTS_FAILURE';

export const getVariants = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/${id}/variants`,
    types: [
      GET_VARIANTS_REQUEST,
      {
        type: GET_VARIANTS_SUCCESS,
        payload: (data: any) => variantsFromServer(data)
      },
      GET_VARIANTS_FAILURE
    ]
  });

export const EDIT_VARIANT_REQUEST = 'EDIT_VARIANT_REQUEST';
export const EDIT_VARIANT_SUCCESS = 'EDIT_VARIANT_SUCCESS';
export const EDIT_VARIANT_FAILURE = 'EDIT_VARIANT_FAILURE';

export const editVariant = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: CurrentVariantType;
  filter?: object;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/variant/${id}`,
    types: [
      {
        type: EDIT_VARIANT_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_VARIANT_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Variant successfully updated!' }
        },
        payload: (data: any) => data
      },
      EDIT_VARIANT_FAILURE
    ],
    body: variantToServer(formData)
  });

export const ADD_VARIANT_REQUEST = 'ADD_VARIANT_REQUEST';
export const ADD_VARIANT_SUCCESS = 'ADD_VARIANT_SUCCESS';
export const ADD_VARIANT_FAILURE = 'ADD_VARIANT_FAILURE';

export const addVariant = ({
  formData,
  filter
}: {
  formData: CurrentVariantType;
  filter?: object;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/variant`,
    types: [
      {
        type: ADD_VARIANT_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: ADD_VARIANT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Variant successfully added!' } },
        payload: (data: any) => data
      },
      ADD_VARIANT_FAILURE
    ],
    body: variantToServer(formData)
  });

export const SET_SELECTED_PRODUCTS_COLLECTION = 'SET_SELECTED_PRODUCTS_COLLECTION';

export const setSelectedProductsCollection = (collection: number[]) => ({
  type: SET_SELECTED_PRODUCTS_COLLECTION,
  payload: collection
});

// IMAGES

export const ADD_IMAGE_TO_VARIANT_REQUEST = 'ADD_IMAGE_TO_VARIANT_REQUEST';
export const ADD_IMAGE_TO_VARIANT_SUCCESS = 'ADD_IMAGE_TO_VARIANT_SUCCESS';
export const ADD_IMAGE_TO_VARIANT_FAILURE = 'ADD_IMAGE_TO_VARIANT_FAILURE';

export const addImageToVariant = ({ formData, id }: { formData: any; id: number }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/variant/${id}/image`,
    types: [
      ADD_IMAGE_TO_VARIANT_REQUEST,
      {
        type: ADD_IMAGE_TO_VARIANT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Image successfully added!' } }
      },
      ADD_IMAGE_TO_VARIANT_FAILURE
    ],
    body: formData
  });

export const DELETE_IMAGE_FROM_VARIANT_REQUEST = 'DELETE_IMAGE_FROM_VARIANT_REQUEST';
export const DELETE_IMAGE_FROM_VARIANT_SUCCESS = 'DELETE_IMAGE_FROM_VARIANT_SUCCESS';
export const DELETE_IMAGE_FROM_VARIANT_FAILURE = 'DELETE_IMAGE_FROM_VARIANT_FAILURE';

export const deleteVariantImage = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/variant-image/${id}`,
    types: [
      DELETE_IMAGE_FROM_VARIANT_REQUEST,
      DELETE_IMAGE_FROM_VARIANT_SUCCESS,
      DELETE_IMAGE_FROM_VARIANT_FAILURE
    ]
  });

export const GET_VARIANT_IMAGES_REQUEST = 'GET_VARIANT_IMAGES_REQUEST';
export const GET_VARIANT_IMAGES_SUCCESS = 'GET_VARIANT_IMAGES_SUCCESS';
export const GET_VARIANT_IMAGES_FAILURE = 'GET_VARIANT_IMAGES_FAILURE';

export const getVariantImages = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/variant/${id}/image`,
    types: [
      GET_VARIANT_IMAGES_REQUEST,
      {
        type: GET_VARIANT_IMAGES_SUCCESS,
        payload: (data: any) => ({ ...data, variantId: id })
      },
      GET_VARIANT_IMAGES_FAILURE
    ]
  });

export const OPEN_VARIANT_IMAGES = 'OPEN_VARIANT_IMAGES';

export const openVariantImages = (id: number) => ({
  type: OPEN_VARIANT_IMAGES,
  payload: id
});

export const EDIT_VARIANT_IMAGE_REQUEST = 'EDIT_VARIANT_IMAGE_REQUEST';
export const EDIT_VARIANT_IMAGE_SUCCESS = 'EDIT_VARIANT_IMAGE_SUCCESS';
export const EDIT_VARIANT_IMAGE_FAILURE = 'EDIT_VARIANT_IMAGE_FAILURE';

export const editVariantImage = ({ formData, id }: { formData: any; id: number }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/variant-image/${id}`,
    types: [
      EDIT_VARIANT_IMAGE_REQUEST,
      {
        type: EDIT_VARIANT_IMAGE_SUCCESS,
        meta: { notification: { type: 'success', message: 'Image successfully updated!' } }
      },
      EDIT_VARIANT_IMAGE_FAILURE
    ],
    body: formData
  });

// ERRORES

export const GET_PRODUCT_VALIDATION_ERRORS_REQUEST = 'GET_PRODUCT_VALIDATION_ERRORS_REQUEST';
export const GET_PRODUCT_VALIDATION_ERRORS_SUCCESS = 'GET_PRODUCT_VALIDATION_ERRORS_SUCCESS';
export const GET_PRODUCT_VALIDATION_ERRORS_FAILURE = 'GET_PRODUCT_VALIDATION_ERRORS_FAILURE';

export const getProductValidationErrors = ({ id }: { id: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/${id}/validate`,
    types: [
      GET_PRODUCT_VALIDATION_ERRORS_REQUEST,
      GET_PRODUCT_VALIDATION_ERRORS_SUCCESS,
      GET_PRODUCT_VALIDATION_ERRORS_FAILURE
    ]
  });

export const GET_EAN_LIST_REQUEST = 'GET_EAN_LIST_REQUEST';
export const GET_EAN_LIST_SUCCESS = 'GET_EAN_LIST_SUCCESS';
export const GET_EAN_LIST_FAILURE = 'GET_EAN_LIST_FAILURE';

export const getEanList = ({ variantId }: { variantId: number }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/variant/${variantId}/ean`,
    types: [GET_EAN_LIST_REQUEST, GET_EAN_LIST_SUCCESS, GET_EAN_LIST_FAILURE]
  });

export const ADD_EAN_REQUEST = 'ADD_EAN_REQUEST';
export const ADD_EAN_SUCCESS = 'ADD_EAN_SUCCESS';
export const ADD_EAN_FAILURE = 'ADD_EAN_FAILURE';

export const addEan = ({ variantId, formData }: { variantId: number; formData: EanType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/variant/${variantId}/ean`,
    types: [
      ADD_EAN_REQUEST,
      {
        type: ADD_EAN_SUCCESS,
        meta: {
          variantId
        }
      },
      ADD_EAN_FAILURE
    ],
    body: formData
  });

export const DELETE_EAN_REQUEST = 'DELETE_EAN_REQUEST';
export const DELETE_EAN_SUCCESS = 'DELETE_EAN_SUCCESS';
export const DELETE_EAN_FAILURE = 'DELETE_EAN_FAILURE';

export const deleteEan = ({ variantId, ean }: { ean: string; variantId: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/variant/ean/${ean}`,
    types: [
      DELETE_EAN_REQUEST,
      {
        type: DELETE_EAN_SUCCESS,
        meta: {
          variantId
        }
      },
      DELETE_EAN_FAILURE
    ]
  });
