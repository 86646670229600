import { connect } from 'react-redux';
import { Component } from 'react';
import { Dispatch, Action } from 'redux';
import _ from 'lodash';
import * as actions from './actions';
import { ActionTypeObject } from './types';

export interface UniversalPageContainerInterface {
  renderPage: Function;
  setUniversalPageMeta: Function;
  resources?: any; // {key: any, url: string, refreshTypes?: string[], headers?: object, apiAxiosInstanse?: Object}[],
  universalPageReducer: { [key: string]: any };
  action?: any;
  types?: ActionTypeObject[] | string[];
  apiAxiosInstanse: any;
}

class UniversalPageContainer extends Component<UniversalPageContainerInterface> {
  componentDidMount = () => {
    const { setUniversalPageMeta, resources = [], action, types, apiAxiosInstanse } = this.props;
    const updateData = {
      types,
      action
    };
    setUniversalPageMeta(resources, updateData, apiAxiosInstanse);
  };

  componentDidUpdate(prevProps: UniversalPageContainerInterface) {
    const {
      setUniversalPageMeta,
      resources = [],
      action,
      types = [],
      apiAxiosInstanse
    } = this.props;
    const updateData = {
      types,
      action
    };

    if (
      resources &&
      prevProps.resources &&
      Array.isArray(prevProps.resources) &&
      !_.isEqual(resources.sort(), prevProps.resources.sort())
    ) {
      setUniversalPageMeta(resources, updateData, apiAxiosInstanse);
    }
  }

  render() {
    const { renderPage = () => {}, universalPageReducer = {}, resources = [] } = this.props;
    let complete = true;
    resources.forEach((resource: { key: any }) => {
      if (!universalPageReducer[resource.key]) {
        complete = false;
      }
    });
    return renderPage(universalPageReducer, complete);
  }
}

const mapStateToProps = (state: { universalPageReducer: { [key: string]: any } }) => ({
  universalPageReducer: state.universalPageReducer
});

export default connect(
  mapStateToProps,
  {
    setUniversalPageMeta: actions.setUniversalPageMeta
  }
)(UniversalPageContainer);
