import { UserSegmentAddType } from 'types/user-segment';
import { uiFilterType, apiFilterType } from './types';

export const segmentRequestToApi = (formData: any): UserSegmentAddType => formData;

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const { page, perPage, title, id } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10),
    title
  };

  if (id !== undefined) {
    updatedFilter.id = typeof id === 'number' ? id : parseInt(id, 10);
  }

  return updatedFilter;
};

export default { segmentRequestToApi, filterToApi };
