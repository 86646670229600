import { take, put, race, fork, select, call, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { currentStore } from 'redux/reducers/selectors/store';
import {
  ADD_STORE_SUCCESS,
  GET_STORE_SUCCESS,
  EDIT_STORE_SUCCESS,
  ADD_IMAGE_TO_STORE_SUCCESS,
  ADD_IMAGE_TO_STORE_FAILURE,
  EDIT_STORE_IMAGE_SUCCESS,
  DELETE_STORE_IMAGE_SUCCESS,
  getStoreImages,
  getStore,
  addImageToStore,
  editStoreImage
} from '../actions';

export function* imageAddToNewStoreSaga(shopId: any) {
  const { images } = yield select(currentStore);
  yield all(
    images.map((image: any) =>
      put(
        addImageToStore({
          formData: {
            uploadId: image.id,
            sort: 0,
            status: 1,
            main: false
          },
          id: shopId
        })
      )
    )
  );
  return true;
}

export function* setMainImageToNewStoreSaga() {
  const { mainImage } = yield select(currentStore);
  while (true) {
    const { addImage } = yield race({
      addImage: take(ADD_IMAGE_TO_STORE_SUCCESS)
    });
    if (!mainImage) {
      yield put(push(`/stores`));
    }
    if (addImage && mainImage && addImage.meta.uploadId === mainImage.id) {
      yield put(
        editStoreImage({
          formData: {
            uploadId: mainImage.id,
            sort: 0,
            status: 1,
            main: true
          },
          id: addImage.payload.content.id
        })
      );
    }
  }
}

export function* storeImageSaga() {
  while (true) {
    const { getStoreSuccess, editStoreSuccess } = yield race({
      getStoreSuccess: take(GET_STORE_SUCCESS),
      editStoreSuccess: take(EDIT_STORE_SUCCESS)
    });
    if (getStoreSuccess || editStoreSuccess) {
      yield put(
        getStoreImages({
          id:
            (getStoreSuccess && getStoreSuccess.payload.content.id) ||
            editStoreSuccess.payload.content.id
        })
      );
    }
  }
}

export function* storeEditSaga({ id }: { id: any }) {
  yield fork(storeImageSaga);
  while (true) {
    const { addImage, deleteImage } = yield race({
      addImage: take(ADD_IMAGE_TO_STORE_SUCCESS),
      deleteImage: take(DELETE_STORE_IMAGE_SUCCESS)
    });
    if (addImage || deleteImage) {
      yield put(getStore({ id }));
    }
  }
}

export function* storeAddSaga() {
  while (true) {
    const { addStore } = yield race({
      addStore: take(ADD_STORE_SUCCESS)
    });
    if (addStore) {
      const { images } = yield select(currentStore);
      if (!images.length) {
        yield put(push(`/stores`));
      }
      yield call(imageAddToNewStoreSaga, addStore.payload.content.id);
      yield fork(setMainImageToNewStoreSaga);
      const { editImageSuccess, addFailure } = yield race({
        editImageSuccess: take(EDIT_STORE_IMAGE_SUCCESS),
        addFailure: take(ADD_IMAGE_TO_STORE_FAILURE)
      });
      if (editImageSuccess) {
        yield put(push(`/stores`));
      }
      if (addFailure) {
        const { mainImage } = yield select(currentStore);
        if (addFailure.meta.uploadId === mainImage.id) {
          yield put(push(`/store/edit/${addStore.payload.content.id}`));
        } else {
          yield put(push(`/stores`));
        }
      }
    }
  }
}
