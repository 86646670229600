import { AnyActionType } from 'types/index';

import {
  GET_PIMIMPORTS_REQUEST,
  GET_PIMIMPORTS_SUCCESS,
  GET_PIMIMPORTS_FAILURE,
  GET_PIMIMPORT_REQUEST,
  GET_PIMIMPORT_SUCCESS,
  GET_PIMIMPORT_FAILURE,
  GET_PIMIMPORT_ERROR_REQUEST,
  GET_PIMIMPORT_ERROR_SUCCESS,
  GET_PIMIMPORT_ERROR_FAILURE
} from './actions';

const INITIAL_STATE: any = {
  imports: [],
  error: null,
  paginator: {},
  loading: false
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state
      };
    case GET_PIMIMPORTS_REQUEST:
      return { ...state, imports: [], paginator: {}, loading: true };
    case GET_PIMIMPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        imports: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_PIMIMPORTS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
