import { take, put, race } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_VOUCHER_SUCCESS,
  EDIT_CAMPAIGN_VOUCHER_SUCCESS,
  DELETE_CAMPAIGN_VOUCHER_SUCCESS,
  getCampaign
} from './actions';

export function* campaignSaga() {
  while (true) {
    const { add } = yield race({
      add: take(ADD_CAMPAIGN_SUCCESS)
    });
    if (add) {
      yield put(push('/campaigns'));
    }
  }
}

export function* campaignEditSaga({ id }: { id: string }) {
  while (true) {
    const { add, edit, remove } = yield race({
      add: take(ADD_CAMPAIGN_VOUCHER_SUCCESS),
      edit: take(EDIT_CAMPAIGN_VOUCHER_SUCCESS),
      remove: take(DELETE_CAMPAIGN_VOUCHER_SUCCESS)
    });
    if (add || edit || remove) {
      yield put(getCampaign({ id }));
    }
  }
}

export default campaignSaga;
