import { take, put, race } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { takeLatest } from 'redux-saga';
import {
  ADD_BANNER_SUCCESS,
  EDIT_BANNER_MEDIA_SUCCESS,
  ADD_BANNER_MEDIA_SUCCESS,
  EDIT_BANNER_SUCCESS,
  DELETE_BANNER_MEDIA_MEDIA_SUCCESS,
  getBanner,
  getBanners
} from './actions';

export function* bannerAddSaga() {
  while (true) {
    const { addBannerSuccess } = yield race({
      addBannerSuccess: take(ADD_BANNER_SUCCESS)
    });
    if (addBannerSuccess) {
      yield put(push(`/banner/edit/${addBannerSuccess.payload.content.id}`));
    }
  }
}

export function* bannerSaga() {
  while (true) {
    const { editBannerSuccess } = yield race({
      editBannerSuccess: take(EDIT_BANNER_SUCCESS)
    });
    if (editBannerSuccess) {
      yield put(getBanners({ filter: editBannerSuccess.meta.filter }));
    }
  }
}

export function* bannerEditSaga({ id }: { id: string }) {
  while (true) {
    const { editBannerMediaSuccess, addBannerMediaSuccess, deleteBannerMediaSuccess } = yield race({
      editBannerMediaSuccess: take(EDIT_BANNER_MEDIA_SUCCESS),
      addBannerMediaSuccess: take(ADD_BANNER_MEDIA_SUCCESS),
      deleteBannerMediaSuccess: take(DELETE_BANNER_MEDIA_MEDIA_SUCCESS)
    });
    if (editBannerMediaSuccess || addBannerMediaSuccess || deleteBannerMediaSuccess) {
      yield put(getBanner({ id }));
    }
  }
}

export default { bannerAddSaga, bannerEditSaga };
