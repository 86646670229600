import { CallApi } from '@factorymarket/mg-react-components';
import { RestorePasswordFormDataType, CreateNewPasswordFormDataType } from './types';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

const { callApiAction } = CallApi;

export const forgotPassword = ({ formData }: { formData: RestorePasswordFormDataType }) =>
  callApiAction({
    method: 'post',
    url: '/password/forgot',
    types: [
      FORGOT_PASSWORD_REQUEST,
      {
        type: FORGOT_PASSWORD_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            message: 'Check email for credentials'
          }
        }
      },
      FORGOT_PASSWORD_FAILURE
    ],
    body: {
      ...formData
    }
  });

export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAILURE = 'RESTORE_PASSWORD_FAILURE';

export const restorePassword = ({ formData }: { formData: CreateNewPasswordFormDataType }) =>
  callApiAction({
    method: 'post',
    url: '/password/restore',
    types: [
      RESTORE_PASSWORD_REQUEST,
      {
        type: RESTORE_PASSWORD_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            message: 'Password was changed!'
          }
        }
      },
      RESTORE_PASSWORD_FAILURE
    ],
    body: {
      ...formData
    }
  });

export const SET_FORGOT_PASSWORD_DATA = 'SET_FORGOT_PASSWORD_DATA';

export const setRestorePasswordData = ({
  restoreToken,
  restoreEmail
}: {
  restoreToken: string | undefined;
  restoreEmail: string | undefined;
}) => ({
  type: SET_FORGOT_PASSWORD_DATA,
  payload: { restoreToken, restoreEmail }
});
