import { take, put, race } from 'redux-saga/effects';
import { getUserSegmentsImport, ADD_USER_SEGMENTS_IMPORT_SUCCESS } from './actions';

export function* userSegmentsImportSaga() {
  while (true) {
    const { success } = yield race({
      success: take(ADD_USER_SEGMENTS_IMPORT_SUCCESS)
    });
    if (success) {
      yield put(getUserSegmentsImport({}));
    }
  }
}

export default userSegmentsImportSaga;
