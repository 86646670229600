import React, { lazy, Suspense, ReactNode, PureComponent } from 'react';
import { Route, Switch, withRouter } from 'react-router'; // react-router v4
import { bindActionCreators } from 'redux';
import { generateRoleRouteComponents } from 'utils/routeHelpers';
import { ConnectedRouter } from 'connected-react-router';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider, connect } from 'react-redux';
import store from 'redux/store';
import routerConfig from 'router.config';
import RoleRouteContainer from 'redux/containers/RoleRouteContainer';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Layout from 'views/layout';
import { Spin } from 'antd';
import './style.css';

import saga from 'redux/sagas/index';

store.runSaga(saga, store.history, store.dispatch);

// Create Loadable components for pages
const CustomLoadableComponents: any = {};

const setLoader = (config: any) => {
  Object.entries(config).forEach(([name, value]: any) => {
    if (!value.childrens) {
      if (value.location) {
        CustomLoadableComponents[name] = lazy(() => import(`views/page/${value.location}`));
      }
    } else {
      setLoader(value.childrens);
    }
  });
};

setLoader(routerConfig);

const App = () => (
  <LocalizeProvider>
    <Provider store={store as any}>
      <ConnectedRouter history={store.history}>
        <DragDropContextProvider backend={HTML5Backend}>
          <AppContentContainer />
        </DragDropContextProvider>
      </ConnectedRouter>
    </Provider>
  </LocalizeProvider>
);

export default App;

class AppContent extends PureComponent {
  constructor(props: any) {
    super(props);
    this.RoutsComponents = generateRoleRouteComponents({
      config: routerConfig,
      RoleRouteContainer,
      CustomLoadableComponents
    });
  }

  RoutsComponents: ReactNode;

  render() {
    return (
      <Layout>
        <Switch>
          {this.RoutsComponents}
          <Route
            component={() => (
              <Suspense fallback={<Spin />}>
                <CustomLoadableComponents.NoMatch />
              </Suspense>
            )}
          />
        </Switch>
      </Layout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    auth: state.auth
  };
}

const connector = connect(
  mapStateToProps,
  dispatch => bindActionCreators({}, dispatch)
);
// @ts-ignore
export const AppContentContainer = withRouter(connector(AppContent));
