import { CallApi } from '@factorymarket/mg-react-components';
import { getErrorsArr, filterToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_USER_SEGMENTS_IMPORT_REQUEST = 'GET_USER_SEGMENTS_IMPORT_REQUEST';
export const GET_USER_SEGMENTS_IMPORT_SUCCESS = 'GET_USER_SEGMENTS_IMPORT_SUCCESS';
export const GET_USER_SEGMENTS_IMPORT_FAILURE = 'GET_USER_SEGMENTS_IMPORT_FAILURE';

export const getUserSegmentsImport = ({
  filter = { page: 1, perPage: 30 }
}: {
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/user-segment/import/list?json=${JSON.stringify(
      filter && filterToApi(filter)
    )}`,
    types: [
      GET_USER_SEGMENTS_IMPORT_REQUEST,
      GET_USER_SEGMENTS_IMPORT_SUCCESS,
      GET_USER_SEGMENTS_IMPORT_FAILURE
    ]
  });

export const GET_USER_SEGMENT_IMPORT_VALIDATION_ERRORS_REQUEST =
  'GET_PRODUCT_VALIDATION_ERRORS_REQUEST';
export const GET_USER_SEGMENT_IMPORT_VALIDATION_ERRORS_SUCCESS =
  'GET_PRODUCT_IMPORT_VALIDATION_ERRORS_SUCCESS';
export const GET_VUSER_SEGMENT_IMPORT_VALIDATION_ERRORS_FAILURE =
  'GET_PRODUCT_IMPORT_VALIDATION_ERRORS_FAILURE';

export const getUserSegmentImportValidationErrors = ({
  id,
  level
}: {
  id: number;
  level: number;
}) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/product/import/${id}/errors?json={"level": ${level}}`,
    types: [
      GET_USER_SEGMENT_IMPORT_VALIDATION_ERRORS_REQUEST,
      {
        type: GET_USER_SEGMENT_IMPORT_VALIDATION_ERRORS_SUCCESS,
        payload: (data: any) => getErrorsArr(data.content.items)
      },
      GET_VUSER_SEGMENT_IMPORT_VALIDATION_ERRORS_FAILURE
    ]
  });

export const ADD_USER_SEGMENTS_IMPORT_REQUEST = 'ADD_USER_SEGMENTS_IMPORT_REQUEST';
export const ADD_USER_SEGMENTS_IMPORT_SUCCESS = 'ADD_USER_SEGMENTS_IMPORT_SUCCESS';
export const ADD_USER_SEGMENTS_IMPORT_FAILURE = 'ADD_USER_SEGMENTS_IMPORT_FAILURE';

export const addUserSegmentsImport = ({
  uploadId,
  delimiter
}: {
  uploadId: number;
  delimiter: string;
}) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/user-segment/import`,
    types: [
      ADD_USER_SEGMENTS_IMPORT_REQUEST,
      {
        type: ADD_USER_SEGMENTS_IMPORT_SUCCESS,
        meta: { notification: { type: 'success', message: 'Upload success!' } }
      },
      ADD_USER_SEGMENTS_IMPORT_FAILURE
    ],
    body: {
      uploadId,
      delimiter
    }
  });
