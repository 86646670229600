import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import * as configActions from 'redux/modules/config/actions';
import { CurrentUserType } from 'types/user';
import { RouterItemType, RouterConfigType } from 'types/router';

import './style.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

type Props = {
  auth: {
    user: CurrentUserType;
    authenticated: boolean;
  };
  location: {
    pathname: string;
  };
  config: {
    asideCollapsed: boolean;
  };
  setConfig: Function;
  routerConfig: RouterConfigType;
  theme: 'dark' | 'light';
};

class Aside extends Component<Props> {
  generateNavigation = (collection: any) => {
    const { auth } = this.props;
    return collection.map((item: RouterItemType[]) => {
      if (item[1].showAside && !item[1].childrens) {
        return (
          <Menu.Item key={item[1].path}>
            {item[1].path ? (
              <Link to={item[1].path}>
                {item[1].icon && <Icon type={item[1].icon} />}
                <span>{item[0]}</span>
              </Link>
            ) : (
              <span>{item[0]}</span>
            )}
          </Menu.Item>
        );
      }
      if (item[1].showAside && item[1].childrens) {
        return (
          <SubMenu
            key={`${item[0]}`}
            title={(
              <>
                {item[1].icon && <Icon type={item[1].icon} />}
                <span>{item[0]}</span>
              </>
)}
          >
            {auth.authenticated && this.generateNavigation(Object.entries(item[1].childrens))}
          </SubMenu>
        );
      }
      return undefined;
    });
  };

  getCurrentLocation = (navigation: any): { selectedKey?: string[]; openKey?: string[] } => {
    const { location } = this.props;
    const result: { selectedKey?: string[]; openKey?: string[] } = {};
    const recursiveSearch = (collection: any, parent: any) =>
      collection.forEach((item: RouterItemType[]) => {
        if (item[1].childrens) return recursiveSearch(Object.entries(item[1].childrens), item);
        if (item[1].path === location.pathname) {
          result.selectedKey = [item[1].path];
          if (parent) result.openKey = [parent[0]];
        }
        return null;
      });

    recursiveSearch(Object.entries(navigation), undefined);

    return result;
  };

  collapseHandle = (collapsed: boolean) => {
    const { setConfig } = this.props;
    setConfig({ asideCollapsed: collapsed });
  };

  render() {
    const {
      config: { asideCollapsed },
      theme,
      routerConfig
    } = this.props;
    const currentLocation = this.getCurrentLocation(routerConfig);
    return (
      <Sider
        theme={theme}
        width="250"
        collapsible
        collapsed={asideCollapsed}
        onCollapse={this.collapseHandle}
      >
        <Menu
          theme={theme}
          defaultSelectedKeys={currentLocation.selectedKey}
          defaultOpenKeys={currentLocation.openKey}
          selectedKeys={currentLocation.selectedKey}
          mode="inline"
        >
          {this.generateNavigation(Object.entries(routerConfig))}
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = ({ config, auth }: { config: any; auth: any }) => ({
  config,
  auth
});

export default connect(
  mapStateToProps,
  // @ts-ignore
  dispatch => bindActionCreators(configActions, dispatch)
)(Aside);
