import { put, take, takeEvery, select, fork, call } from 'redux-saga/effects';
import uniqueString from 'unique-string';

import {
  loginUser,
  logoutUser,
  setDevice,
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  ACCESS_DENIED
} from 'redux/actions/auth';
import { LOAD_CONFIG_SUCCESS } from 'redux/modules/config/actions';
import { getMe, GET_ME_SUCCESS } from 'redux/actions/users';
import { auth } from 'redux/reducers/selectors';
import api, { setToken } from 'utils/api';
import { roles } from 'utils/auth';
import { query } from 'utils/helper';
import { Notification } from '@factorymarket/mg-react-components';
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileVendor,
  mobileModel,
  isMobile
} from 'react-device-detect';
import { DeviceType } from 'types/auth';

const { setNotification } = Notification;

export function* checkHasAccessToInterface(props: any) {
  const { authenticated } = yield select(auth);
  const { user } = props;

  let hasAllowingRole = false;

  if (user.roles) {
    hasAllowingRole = user.roles.find((role: string) => roles.admin.includes(role));
  }

  if (hasAllowingRole) return true;
  if (!authenticated) return false;

  // You don't have permission to the interface, so get out!
  if (!hasAllowingRole && authenticated) {
    yield put(logoutUser());
    return false;
  }

  return true;
}

// saga for login from login form
function* loginSaga(tokenObject: any) {
  setToken(tokenObject.payload.content.accessToken);
  yield put(getMe());
  const { payload } = yield take(GET_ME_SUCCESS);
  const userObject = { ...payload.content.user };
  const hasAccess = yield call(checkHasAccessToInterface, { user: userObject });
  const loginData = { user: userObject, token: tokenObject.payload.content };
  if (hasAccess) {
    yield put(loginUser(loginData));
  } else {
    yield put(
      setNotification({
        type: 'error',
        message: "You don't have necessary role"
      })
    );
  }
}

// saga for login from saved token & user & store
export function* autoLoginSaga() {
  const { token, user, store } = yield select(auth);
  const hasAccess = yield call(checkHasAccessToInterface, { user });
  if (hasAccess) {
    setToken(token.accessToken);
    yield put(loginUser({ user, token, store }));
  }
}

// get token from refreshToken
function* getNewToken() {
  try {
    const { data } = yield call([api, 'get'], 'login/newtoken');
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

// saga for login from query params
export function* firstAuthSaga() {
  const location = yield take('@@router/LOCATION_CHANGE');
  const { accessToken } = query.parse(location.payload.search);
  if (accessToken) {
    yield put({ type: GET_TOKEN_REQUEST });
    yield put(logoutUser());
    setToken(accessToken);
    const newToken = yield call(getNewToken);
    yield put({ type: GET_TOKEN_SUCCESS, payload: newToken });
  }
}

export function* checkDeviceSaga() {
  const { device }: { device?: DeviceType } = yield select(auth);
  const deviceName = isMobile ? `${mobileVendor} ${mobileModel}` : 'desktop';
  if (!device) {
    const localDevice = {
      id: uniqueString(),
      name: `${browserName} ${browserVersion}`,
      os: `${osName} ${osVersion} ${deviceName}`
    };
    yield put(setDevice(localDevice));
  }
}

export function* authSagaV2() {
  yield fork(firstAuthSaga);
  yield call(checkDeviceSaga);
  yield takeEvery(LOAD_CONFIG_SUCCESS, autoLoginSaga);
  yield takeEvery(GET_TOKEN_SUCCESS, loginSaga);
  yield takeEvery(ACCESS_DENIED, checkHasAccessToInterface);
}

export default authSagaV2;
