import { take, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

export function* redirectSaga(history: any) {
  yield take('@@router/LOCATION_CHANGE');
  const pathToRedirect = history.location.pathname;
  yield take('AUTH_SUCCESS');
  yield take('@@router/LOCATION_CHANGE');
  yield put(push(pathToRedirect));
}

export default redirectSaga;
