import { take, put, race, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  getVariantImages,
  GET_VARIANTS_SUCCESS,
  ADD_IMAGE_TO_VARIANT_SUCCESS,
  DELETE_IMAGE_FROM_VARIANT_SUCCESS,
  getVariants,
  ADD_EAN_SUCCESS,
  DELETE_EAN_SUCCESS,
  getEanList
} from '../actions';

export function* variantSaga({ id }: { id: string }) {
  while (true) {
    const { getVariantsSuccess, addImage, addEan, deleteEan, removeImage } = yield race({
      getVariantsSuccess: take(GET_VARIANTS_SUCCESS),
      addImage: take(ADD_IMAGE_TO_VARIANT_SUCCESS),
      removeImage: take(DELETE_IMAGE_FROM_VARIANT_SUCCESS),
      addEan: take(ADD_EAN_SUCCESS),
      deleteEan: take(DELETE_EAN_SUCCESS)
    });
    if (getVariantsSuccess) {
      yield all(
        getVariantsSuccess.payload.content.items.map((variant: any, key: any) =>
          put(getVariantImages({ id: getVariantsSuccess.payload.content.items[key].id }))
        )
      );
    }
    if (addImage || removeImage) {
      yield put(getVariants({ id }));
    }
    if (addEan || deleteEan) {
      const variantId = (addEan && addEan.meta.variantId) || deleteEan.meta.variantId;
      yield put(getEanList({ variantId }));
    }
  }
}

export default variantSaga;
