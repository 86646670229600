import { take, put, fork } from 'redux-saga/effects';
import { query } from 'utils/helper';
import { push } from 'react-router-redux';
import { setRestorePasswordData, RESTORE_PASSWORD_SUCCESS } from './actions';

export function* completeRestoreSaga() {
  while (true) {
    yield take(RESTORE_PASSWORD_SUCCESS);
    yield put(push('/login'));
  }
}

export function* restorePasswordSaga() {
  yield fork(completeRestoreSaga);
  const location = yield take('@@router/LOCATION_CHANGE');
  const { token, email } = query.parse(location.payload.location.search);
  if (token && email) {
    yield put(
      setRestorePasswordData({
        restoreToken: decodeURIComponent(token),
        restoreEmail: decodeURIComponent(email)
      })
    );
  } else {
    yield put(setRestorePasswordData({ restoreToken: undefined, restoreEmail: undefined }));
    yield put(push('/'));
  }
}

export default restorePasswordSaga;
