import { put, select, take, fork, all } from 'redux-saga/effects';
import { buildQueryToObject } from 'utils/helper';

import { getUniversalPageData, SET_UNIVERSAL_PAGE_META_SUCCESS } from './actions';
import { universalPageReducer } from './selector';

export function* checkActionTypeSaga(
  history: { location: { search: string } },
  type: string,
  action: Function
) {
  while (true) {
    yield take(type);
    const currentParams = buildQueryToObject(history.location.search);
    const obj = {
      filter: currentParams && Object.keys(currentParams).length > 0 ? currentParams : ''
    };
    if (action) {
      yield put(action(obj));
    }
  }
}

export function* updateMainResourceSaga(
  history: { location: { search: string } },
  updateData: { types: string[]; action: () => void }
) {
  yield all(
    updateData.types.map((type: string) =>
      fork(checkActionTypeSaga, history, type, updateData.action)
    )
  );
}

export function* updateResourceSaga(
  history: { location: { search: string } },
  resources: {
    key: any;
    url: string;
    refreshTypes?: string[];
    headers?: object;
    apiAxiosInstanse?: Object;
  }[],
  api: any
) {
  yield all(
    resources.map(resource => {
      if (resource.refreshTypes && resource.refreshTypes.length > 0) {
        return resource.refreshTypes.map(type =>
          fork(checkActionTypeSaga, history, type, () =>
            getUniversalPageData(
              resource,
              resource.apiAxiosInstanse ? resource.apiAxiosInstanse : api
            )
          )
        );
      }
      return undefined;
    })
  );
}

function* universalPageSaga(history: { location: { search: string } }) {
  while (true) {
    const pageData = yield take(SET_UNIVERSAL_PAGE_META_SUCCESS);
    const universalPage = yield select(universalPageReducer);
    const resourcesForDownloading = pageData.payload.resources.filter(
      (resource: { key: any; headers?: object; url: string }) =>
        !(universalPage && universalPage[resource.key])
    );
    if (pageData.payload.updateData && pageData.payload.updateData.action) {
      const { action } = pageData.payload.updateData;
      const currentParams = buildQueryToObject(history.location.search);
      const obj = {
        filter: currentParams && Object.keys(currentParams).length > 0 ? currentParams : ''
      };
      yield put(action(obj));
    }
    if (
      pageData.payload.updateData &&
      pageData.payload.updateData.action &&
      pageData.payload.updateData.types &&
      pageData.payload.updateData.types.length > 0
    ) {
      yield fork(updateMainResourceSaga, history, pageData.payload.updateData);
    }
    yield fork(updateResourceSaga, history, pageData.payload.resources, pageData.payload.api);
    yield all(
      resourcesForDownloading.map(
        (resource: { key: any; headers?: object; url: string; apiAxiosInstanse?: Object }) =>
          put(
            getUniversalPageData(
              resource,
              resource.apiAxiosInstanse ? resource.apiAxiosInstanse : pageData.payload.api
            )
          )
      )
    );
  }
}

export default universalPageSaga;
