import moment from 'moment';
import { CurrentUserType } from 'types/user';
import { uiFilterType, apiFilterType } from './types';

export const addUserRequestToApi = (formData: any): CurrentUserType => {
  const updatedFormData = {
    ...formData,
    birthday: (formData.birthday && moment(formData.birthday).format('YYYY-MM-DD')) || undefined
  };
  return updatedFormData;
};

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const {
    page,
    perPage,
    status,
    userType,
    email,
    firstName,
    lastName,
    role,
    id,
    pointsFrom,
    pointsTo,
    ean,
    segmentId
  } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10),
    email,
    firstName,
    lastName,
    role,
    ean
  };

  if (pointsFrom !== undefined) {
    updatedFilter.pointsFrom =
      typeof pointsFrom === 'number' ? pointsFrom : parseInt(pointsFrom, 10);
  }

  if (pointsTo !== undefined) {
    updatedFilter.pointsTo = typeof pointsTo === 'number' ? pointsTo : parseInt(pointsTo, 10);
  }

  if (status !== undefined) {
    updatedFilter.status = typeof status === 'number' ? status : parseInt(status, 10);
  }

  if (userType !== undefined) {
    updatedFilter.userType = typeof userType === 'number' ? userType : parseInt(userType, 10);
  }

  if (id !== undefined) {
    updatedFilter.id = typeof id === 'number' ? id : parseInt(id, 10);
  }

  if (segmentId !== undefined) {
    updatedFilter.segmentId = typeof segmentId === 'number' ? segmentId : parseInt(segmentId, 10);
  }

  return updatedFilter;
};

export default {
  filterToApi
};
