import React, { Suspense } from 'react';
import api from 'utils/api';
import { Spin } from 'antd';

import { ResourcesType } from 'types/resource';

export const processResources = (resources: ResourcesType) => {
  const updatedResouces = resources.map(item => {
    const updatedItem = { ...item };
    if (item.apiAxiosInstanse) {
      updatedItem.apiAxiosInstanse = api;
    }
    return updatedItem;
  });
  return updatedResouces;
};

export const generateRoleRouteComponents = ({
  config,
  RoleRouteContainer,
  CustomLoadableComponents
}: {
  config: any;
  RoleRouteContainer: any;
  CustomLoadableComponents: any;
}): any =>
  Object.entries(config).map(
    ([name, { path, roles, exact, redirectRoute, childrens, location, resources }]: any): any => {
      if (!childrens) {
        if (location) {
          return (
            <RoleRouteContainer
              key={name}
              path={path}
              roles={roles}
              component={(props: any) => {
                const TagName = CustomLoadableComponents[name];
                return (
                  <Suspense fallback={<Spin />}>
                    <TagName
                      resources={(resources && processResources(resources)) || []}
                      {...props}
                    />
                  </Suspense>
                );
              }}
              exact={exact}
              redirectRoute={redirectRoute}
            />
          );
        }
      }
      return generateRoleRouteComponents({
        config: childrens,
        RoleRouteContainer,
        CustomLoadableComponents
      });
    }
  );
