import { uiFilterType, apiFilterType } from './types';

export const checkScheduleStruct = (value: any) => {
  let isValid = false;
  if (value && Object.keys(value)) {
    Object.keys(value).forEach((item: string) => {
      if (value[item] && value[item].length) {
        isValid = true;
      }
    });
  }
  if (
    value &&
    value.exceptions &&
    Object.keys(value.exceptions) &&
    Object.keys(value.exceptions).length
  ) {
    isValid = true;
  }
  return isValid;
};
//   message: 'Please input Working hours'
// }

export const storeStatusToArray = (status: number): number[] => [status];

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const { page, perPage, status, isDefault, address, mgShopId } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10),
    address
  };
  if (status !== undefined) {
    updatedFilter.status = typeof status === 'number' ? [status] : [parseInt(status, 10)];
  }
  if (isDefault !== undefined) {
    updatedFilter.isDefault = typeof isDefault === 'boolean' ? isDefault : isDefault === 'true';
  }
  if(mgShopId !== undefined){
    updatedFilter.mgShopId = typeof mgShopId === 'number' ? mgShopId : parseInt(mgShopId, 10);
  }
  return updatedFilter;
};

export default {
  checkScheduleStruct,
  filterToApi
};
