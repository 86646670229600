import { uiFilterType, apiFilterType } from './types';

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const { page, perPage } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10)
  };

  return updatedFilter;
};

export default { filterToApi };
