import { take, put, race, select, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { GET_USER_REQUEST } from 'src/views/page/Users/actions';
import { SET_UNIVERSAL_PAGE_META_SUCCESS } from 'redux/containers/UniversalPageContainer/actions';
import {
  getVouchers,
  getVoucher,
  DELETE_VOUCHER_SUCCESS,
  EDIT_VOUCHER_SUCCESS,
  DELETE_VOUCHER_IMAGE_SUCCESS,
  ADD_VOUCHER_SUCCESS,
  BIND_PRODUCT_TO_VOUCHER_SUCCESS,
  BIND_USER_SEGMENT_TO_VOUCHER_SUCCESS,
  bindProductToVoucher,
  bindUserSegmentToVoucher,
  GET_VOUCHER_REQUEST,
  getVoucherTerms
} from './actions';

import { CurrentVoucherProducts, CurrentVoucherUserSegments } from './selector';

export function* vouchersSaga() {
  while (true) {
    const deleteRequest = yield take(DELETE_VOUCHER_SUCCESS);
    yield put(getVouchers({ filter: deleteRequest.meta.filter }));
  }
}

export function* voucherEditSaga({ id }: { id: string }) {
  while (true) {
    const {
      request,
      editSuccess,
      deleteImageSuccess,
      bindProductSuccess,
      bindUserSegmentSuccess
    } = yield race({
      request: take(GET_VOUCHER_REQUEST),
      editSuccess: take(EDIT_VOUCHER_SUCCESS),
      deleteImageSuccess: take(DELETE_VOUCHER_IMAGE_SUCCESS),
      bindProductSuccess: take(BIND_PRODUCT_TO_VOUCHER_SUCCESS),
      bindUserSegmentSuccess: take(BIND_USER_SEGMENT_TO_VOUCHER_SUCCESS)
    });
    if (request) {
      yield put(getVoucherTerms());
    }
    if (editSuccess || deleteImageSuccess || bindProductSuccess || bindUserSegmentSuccess) {
      yield put(getVoucher({ id }));
    }
  }
}

export function* voucherAddSaga() {
  while (true) {
    const { addSuccess, request } = yield race({
      addSuccess: take(ADD_VOUCHER_SUCCESS),
      request: take(SET_UNIVERSAL_PAGE_META_SUCCESS)
    });
    if (request) {
      yield put(getVoucherTerms());
    }
    if (addSuccess) {
      const products = yield select(CurrentVoucherProducts);
      if (products && products.length) {
        yield all(
          products.map((product: any) =>
            put(
              bindProductToVoucher({
                id: addSuccess.payload.content.id,
                formData: { entityType: 'product', entityId: product.id }
              })
            )
          )
        );
      }
      const userSegments = yield select(CurrentVoucherUserSegments);
      if (userSegments && userSegments.length) {
        yield all(
          userSegments.map((userSegment: any) =>
            put(
              bindUserSegmentToVoucher({
                id: addSuccess.payload.content.id,
                formData: { entityType: 'userSegment', entityId: userSegment.id }
              })
            )
          )
        );
      }
      yield put(push('/vouchers/list'));
    }
  }
}

export default { vouchersSaga, voucherEditSaga };
