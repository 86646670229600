import { AnyActionType } from 'types/index';

import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  SET_SELECTED_PRODUCTS_COLLECTION,
  EDIT_PRODUCT_SUCCESS,
  GET_VARIANTS_REQUEST,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAILURE,
  EDIT_VARIANT_REQUEST,
  EDIT_VARIANT_SUCCESS,
  EDIT_VARIANT_FAILURE,
  GET_VARIANT_IMAGES_SUCCESS,
  GET_PRODUCT_VALIDATION_ERRORS_REQUEST,
  GET_PRODUCT_VALIDATION_ERRORS_SUCCESS,
  GET_EAN_LIST_SUCCESS,
  GET_EAN_LIST_REQUEST
} from './actions';

import {
  GET_ALSO_BOUGHT_PRODUCTS_REQUEST,
  GET_ALSO_BOUGHT_PRODUCTS_SUCCESS,
  GET_ALSO_BOUGHT_PRODUCTS_FAILURE
} from './AlsoBougth/actions';

import { GET_SIMILAR_PRODUCTS_SUCCESS } from './Similar/actions';

const INITIAL_STATE = {
  products: [],
  currentProduct: {},
  currentProductAlsoBoughtProducts: [],
  currentProductSimilarProducts: [],
  currentProductVariants: [],
  variantEanList: [],
  loadingCurrentProductVariants: false,
  loadingCurrentProduct: false,
  currentProductVariantEditing: false,
  errors: undefined,
  paginator: {},
  loading: false,
  productMetadata: {
    statuses: [{ value: 1, name: 'active' }, { value: 2, name: 'inactive' }],
    searchBy: [{ value: 'id', name: 'id' }, { value: 'title', name: 'title' }]
  }
};

let updatedCurrentProductVariants;

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        errors: INITIAL_STATE.errors,
        currentProductAlsoBoughtProducts: INITIAL_STATE.currentProductAlsoBoughtProducts,
        currentProductSimilarProducts: INITIAL_STATE.currentProductSimilarProducts,
        currentProduct: INITIAL_STATE.currentProduct,
        currentProductVariants: INITIAL_STATE.currentProductVariants,
        loadingCurrentProductVariants: INITIAL_STATE.loadingCurrentProductVariants,
        loadingCurrentProduct: INITIAL_STATE.loadingCurrentProduct,
        currentProductVariantEditing: INITIAL_STATE.currentProductVariantEditing,
        variantEanList: INITIAL_STATE.variantEanList
      };
    case GET_PRODUCTS_REQUEST:
      return { ...state, products: [], paginator: {}, loading: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.content.items,
        errors: undefined,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_PRODUCTS_FAILURE:
      return { ...state, loading: false };
    case SET_SELECTED_PRODUCTS_COLLECTION:
      return { ...state, selectedCollection: action.payload };
    case GET_PRODUCT_REQUEST:
      return { ...state, loadingCurrentProduct: true };
    case GET_PRODUCT_FAILURE:
      return { ...state, loadingCurrentProduct: false };
    case GET_PRODUCT_SUCCESS:
    case EDIT_PRODUCT_SUCCESS:
      return { ...state, currentProduct: action.payload.content, loadingCurrentProduct: false };
    case GET_VARIANTS_REQUEST:
      return { ...state, loadingCurrentProductVariants: true };
    case GET_VARIANTS_FAILURE:
      return { ...state, loadingCurrentProductVariants: false };
    case GET_VARIANTS_SUCCESS:
      return {
        ...state,
        currentProductVariants: action.payload.content.items,
        loadingCurrentProductVariants: false
      };
    case EDIT_VARIANT_REQUEST:
      return { ...state, currentProductVariantEditing: true };
    case EDIT_VARIANT_SUCCESS:
    case EDIT_VARIANT_FAILURE:
      return { ...state, currentProductVariantEditing: false };
    case GET_VARIANT_IMAGES_SUCCESS:
      updatedCurrentProductVariants = state.currentProductVariants.map((item: any) => {
        if (action.payload.variantId === item.id) {
          return { ...item, images: action.payload.content.items };
        }
        return item;
      });
      return { ...state, currentProductVariants: updatedCurrentProductVariants };
    case GET_PRODUCT_VALIDATION_ERRORS_REQUEST:
      return { ...state, errors: undefined };
    case GET_PRODUCT_VALIDATION_ERRORS_SUCCESS:
      return { ...state, errors: action.payload.content.errors };
    // case GET_EAN_LIST_REQUEST:
    //   return { ...state, variantEanList: INITIAL_STATE.variantEanList }
    case GET_EAN_LIST_SUCCESS:
      return { ...state, variantEanList: action.payload.content.items };
    // case GET_ALSO_BOUGHT_PRODUCTS_REQUEST:
    //   return {
    //     ...state,
    //     currentProductAlsoBoughtProducts: INITIAL_STATE.currentProductAlsoBoughtProducts
    //   };
    case GET_ALSO_BOUGHT_PRODUCTS_SUCCESS:
      return {
        ...state,
        currentProductAlsoBoughtProducts: action.payload.content.items
      };
    case GET_SIMILAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        currentProductSimilarProducts: action.payload.content.items
      };
    default:
      return state;
  }
}
