import { AnyActionType } from 'types/index';

import {
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
  GET_TERM_FAILURE,
  EDIT_TERM_REQUEST,
  EDIT_TERM_SUCCESS,
  EDIT_TERM_FAILURE,
  ADD_TERM_REQUEST,
  ADD_TERM_SUCCESS,
  ADD_TERM_FAILURE
} from './actions';

const INITIAL_STATE: any = {
  terms: [],
  currentTerm: {},
  loadingCurrentTerm: false,
  error: null,
  paginator: {},
  loading: false
};

export default function(state = INITIAL_STATE, action: AnyActionType) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        currentTerm: INITIAL_STATE.currentTerm,
        loadingCurrentTerm: INITIAL_STATE.loadingCurrentTerm
      };
    case EDIT_TERM_REQUEST:
    case ADD_TERM_REQUEST:
      return { ...state, loadingCurrentTerm: true };
    case EDIT_TERM_FAILURE:
    case ADD_TERM_FAILURE:
    case ADD_TERM_SUCCESS:
      return { ...state, loadingCurrentTerm: false };
    case GET_TERMS_REQUEST:
      return { ...state, terms: [], paginator: {}, loading: true };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        terms: action.payload.content.items,
        error: null,
        paginator: action.payload.content.paginator ? action.payload.content.paginator : {}
      };
    case GET_TERMS_FAILURE:
      return { ...state, loading: false };
    case GET_TERM_REQUEST:
      return { ...state, loadingCurrentTerm: true };
    case GET_TERM_FAILURE:
      return { ...state, loadingCurrentTerm: false };
    case GET_TERM_SUCCESS:
    case EDIT_TERM_SUCCESS:
      return { ...state, currentTerm: action.payload.content, loadingCurrentTerm: false };
    default:
      return state;
  }
}
