import { CurrentProductType, ProductVariantType } from 'types/product';
import { uiFilterType, apiFilterType } from './List/types';

export const priceFromServer = (price: number) => (price / 100).toFixed(2);
export const priceToServer = (price: number) => parseInt((price * 100).toFixed(0), 10);

export const productsFromServer = (data: { content: { items: CurrentProductType[] } }) => {
  if (data.content && data.content.items) {
    const updatedItems = data.content.items.map((item: CurrentProductType) => {
      if (item.primaryVariant && item.primaryVariant.price) {
        return {
          ...item,
          primaryVariant: {
            ...item.primaryVariant,
            price: priceFromServer(item.primaryVariant.price),
            strikedPrice:
              item.primaryVariant.strikedPrice && priceFromServer(item.primaryVariant.strikedPrice)
          }
        };
      }
      return item;
    });
    return { ...data, content: { ...data.content, items: updatedItems } };
  }
  return data;
};

export const variantsFromServer = (data: { content: { items: ProductVariantType[] } }) => {
  if (data.content && data.content.items) {
    const updatedItems = data.content.items.map((item: ProductVariantType) => {
      if (item && item.price) {
        return {
          ...item,
          price: priceFromServer(item.price),
          strikedPrice: item.strikedPrice && priceFromServer(item.strikedPrice)
        };
      }
      return item;
    });
    return { ...data, content: { ...data.content, items: updatedItems } };
  }
  return data;
};

export const productFromServer = (data: { content: CurrentProductType }) => {
  if (data.content && data.content.primaryVariant && data.content.primaryVariant.price) {
    return {
      ...data,
      content: {
        ...data.content,
        primaryVariant: {
          ...data.content.primaryVariant,
          price: priceFromServer(data.content.primaryVariant.price)
        }
      }
    };
  }
  return data;
};
export const productToServer = (product: CurrentProductType) => product;

export const variantToServer = (variant: ProductVariantType) => ({
  ...variant,
  price: priceToServer(variant.price),
  strikedPrice: variant.strikedPrice && priceToServer(variant.strikedPrice)
});

export const filterToApi = (filter: uiFilterType): apiFilterType => {
  const {
    page,
    perPage,
    status,
    categoryId,
    searchBy,
    searchText,
    orderBy,
    orderDirection,
    priceFrom,
    priceTo,
    published,
    weeklyOffer,
    hasStrikedPrice,
    productGroupId
  } = filter;
  const updatedFilter: apiFilterType = {
    page: typeof page === 'number' ? page : parseInt(page, 10),
    perPage: typeof perPage === 'number' ? perPage : parseInt(perPage, 10),
    searchBy,
    searchText,
    orderBy,
    orderDirection
  };

  if (status !== undefined) {
    updatedFilter.status = typeof status === 'number' ? [status] : [parseInt(status, 10)];
  }

  if (categoryId !== undefined) {
    updatedFilter.categoryId =
      typeof categoryId === 'number' ? categoryId : parseInt(categoryId, 10);
  }

  if (priceFrom !== undefined) {
    updatedFilter.priceFrom = typeof priceFrom === 'number' ? priceFrom : parseInt(priceFrom, 10);
  }

  if (priceTo !== undefined) {
    updatedFilter.priceTo = typeof priceTo === 'number' ? priceTo : parseInt(priceTo, 10);
  }


  if (productGroupId !== undefined) { 
    updatedFilter.productGroupId = typeof productGroupId === 'number' ? productGroupId : parseInt(productGroupId, 10);
  }

  if (published !== undefined) {
    updatedFilter.published = typeof published === 'boolean' ? published : published === 'true';
  }

  if (weeklyOffer !== undefined) {
    updatedFilter.weeklyOffer = weeklyOffer === 'true' ? true : undefined;
  }

  if (hasStrikedPrice !== undefined) {
    updatedFilter.hasStrikedPrice = hasStrikedPrice === 'true';
  }
  
  return updatedFilter;
};
