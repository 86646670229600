import React, { ReactNode, PureComponent } from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import * as actions from './actions';

export interface IFormModalContainerProps {
  title?: string;
  footer?: ReactNode;
  onCancel?: Function;
  toggleModal: Function;
  renderChildren: Function;
  id: string;
  formModalReducer: {
    isOpened: boolean;
    loading: boolean;
    id: string;
  };
}

class FormModalContainer extends PureComponent<IFormModalContainerProps> {
  render() {
    const {
      title,
      footer,
      toggleModal,
      onCancel,
      formModalReducer: { isOpened, loading },
      formModalReducer,
      id,
      renderChildren
    } = this.props;
    const visible = isOpened && formModalReducer.id === id;
    return (
      <Modal
        title={title || false}
        visible={visible}
        destroyOnClose
        footer={footer || false}
        onCancel={() => {
          toggleModal({
            isOpened: false
          });
          if (onCancel) {
            onCancel();
          }
        }}
      >
        {renderChildren({ loading })}
      </Modal>
    );
  }
}

const mapState2Props = (state: any) => ({
  formModalReducer: state.formModalReducer
});

export default connect(
  mapState2Props,
  {
    toggleModal: actions.toggleModal
  }
)(FormModalContainer);
