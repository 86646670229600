import { PimImportType, PimImportErrorType } from 'types/pimimport';
import { CallApi } from '@factorymarket/mg-react-components';
import { filterToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_PIMIMPORTS_REQUEST = 'GET_PIMIMPORTS_REQUEST';
export const GET_PIMIMPORTS_SUCCESS = 'GET_PIMIMPORTS_SUCCESS';
export const GET_PIMIMPORTS_FAILURE = 'GET_PIMIMPORTS_FAILURE';

export const getPimImports = ({ filter = { page: 1, perPage: 30 } }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/pim-product-imports?json=${JSON.stringify(filter && filterToApi(filter))}`,
    types: [GET_PIMIMPORTS_REQUEST, GET_PIMIMPORTS_SUCCESS, GET_PIMIMPORTS_FAILURE]
  });

export const GET_PIMIMPORT_REQUEST = 'GET_PIMIMPORT_REQUEST';
export const GET_PIMIMPORT_SUCCESS = 'GET_PIMIMPORT_SUCCESS';
export const GET_PIMIMPORT_FAILURE = 'GET_PIMIMPORT_FAILURE';

export const getPimImport = ({ id }: { id: number | string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/pim-product-import/${id}`,
    types: [GET_PIMIMPORT_REQUEST, GET_PIMIMPORT_SUCCESS, GET_PIMIMPORT_FAILURE]
  });

export const GET_PIMIMPORT_ERROR_REQUEST = 'GET_PIMIMPORT_ERROR_REQUEST';
export const GET_PIMIMPORT_ERROR_SUCCESS = 'GET_PIMIMPORT_ERROR_SUCCESS';
export const GET_PIMIMPORT_ERROR_FAILURE = 'GET_PIMIMPORT_ERROR_FAILURE';

export const getPimImportErrors = ({ id, level, filter = { page: 1, perPage: 100 } }: { id: number; level: number; filter?: uiFilterType; }) =>
    callApiAction({
        method: 'get',
        url: `/employee-area/pim-product-import-errors/${id}/${level}?json=${JSON.stringify(filterToApi(filter))}`,
        types: [GET_PIMIMPORT_ERROR_REQUEST, GET_PIMIMPORT_ERROR_SUCCESS, GET_PIMIMPORT_ERROR_FAILURE]
    });

export const PUT_PIMIMPORT_RESET_REQUEST = 'PUT_PIMIMPORT_RESET_REQUEST';
export const PUT_PIMIMPORT_RESET_SUCCESS = 'PUT_PIMIMPORT_RESET_SUCCESS';
export const PUT_PIMIMPORT_RESET_FAILURE = 'PUT_PIMIMPORT_RESET_FAILURE';

export const resetPimImport = ({ id }: { id: number; }) =>
    callApiAction({
        method: 'put',
        url: `/employee-area/pim-product-import/${id}?json={status:3}`,
        types: [
            PUT_PIMIMPORT_RESET_REQUEST,
            {
                type: PUT_PIMIMPORT_RESET_SUCCESS,
                meta: { notification: { type: 'success', message: 'Status successfully updated!' } }
            },
            PUT_PIMIMPORT_RESET_FAILURE
        ]
    });
