import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAILURE,
  SET_FORGOT_PASSWORD_DATA
} from './actions';

const INITIAL_STATE = {
  loading: false,
  restoreToken: undefined,
  restoreEmail: undefined
};

export default function(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESTORE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAILURE:
    case RESTORE_PASSWORD_SUCCESS:
    case RESTORE_PASSWORD_FAILURE:
      return { ...state, loading: false };
    case SET_FORGOT_PASSWORD_DATA:
      return {
        ...state,
        restoreToken: action.payload.restoreToken,
        restoreEmail: action.payload.restoreEmail
      };
    default:
      return state;
  }
}
