import { CallApi } from '@factorymarket/mg-react-components';
import { CurrentBannerType, BannerMediaType } from 'types/banner';
import { bannerRequestToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE';

export const getBanners = ({ filter }: { filter?: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/banner/list?json=${JSON.stringify({
      ...filter,
      id: filter && filter.id && parseInt(filter.id, 10)
    })}`,
    types: [GET_BANNERS_REQUEST, GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE]
  });

export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';

export const getBanner = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/banner/${id}`,
    types: [GET_BANNER_REQUEST, GET_BANNER_SUCCESS, GET_BANNER_FAILURE]
  });

export const EDIT_BANNER_REQUEST = 'EDIT_BANNER_REQUEST';
export const EDIT_BANNER_SUCCESS = 'EDIT_BANNER_SUCCESS';
export const EDIT_BANNER_FAILURE = 'EDIT_BANNER_FAILURE';

export const editBanner = ({
  bannerId,
  formData,
  filter
}: {
  bannerId: string;
  formData: CurrentBannerType;
  filter?: uiFilterType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/banner/${bannerId}`,
    types: [
      EDIT_BANNER_REQUEST,
      {
        type: EDIT_BANNER_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Banner successfully updated!' },
          filter
        }
      },
      EDIT_BANNER_FAILURE
    ],
    body: bannerRequestToApi(formData)
  });

export const ADD_BANNER_REQUEST = 'ADD_BANNER_REQUEST';
export const ADD_BANNER_SUCCESS = 'ADD_BANNER_SUCCESS';
export const ADD_BANNER_FAILURE = 'ADD_BANNER_FAILURE';

export const addBanner = ({ formData }: { formData: CurrentBannerType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/banner`,
    types: [
      ADD_BANNER_REQUEST,
      {
        type: ADD_BANNER_SUCCESS,
        meta: { notification: { type: 'success', message: 'Banner successfully added!' } }
      },
      ADD_BANNER_FAILURE
    ],
    body: bannerRequestToApi(formData)
  });

export const SET_SELECTED_BANNERS_COLLECTION = 'SET_SELECTED_BANNERS_COLLECTION';

export const setSelectedBannersCollection = (collection: number[]) => ({
  type: SET_SELECTED_BANNERS_COLLECTION,
  payload: collection
});

export const ADD_BANNER_MEDIA_REQUEST = 'ADD_BANNER_MEDIA_REQUEST';
export const ADD_BANNER_MEDIA_SUCCESS = 'ADD_BANNER_MEDIA_SUCCESS';
export const ADD_BANNER_MEDIA_FAILURE = 'ADD_BANNER_MEDIA_FAILURE';

export const addBannerMedia = ({ formData }: { formData: BannerMediaType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/banner-media`,
    types: [
      ADD_BANNER_MEDIA_REQUEST,
      {
        type: ADD_BANNER_MEDIA_SUCCESS,
        meta: { notification: { type: 'success', message: 'Banner Media successfully added!' } }
      },
      ADD_BANNER_MEDIA_FAILURE
    ],
    body: bannerRequestToApi(formData)
  });

export const EDIT_BANNER_MEDIA_REQUEST = 'EDIT_BANNER_MEDIA_REQUEST';
export const EDIT_BANNER_MEDIA_SUCCESS = 'EDIT_BANNER_MEDIA_SUCCESS';
export const EDIT_BANNER_MEDIA_FAILURE = 'EDIT_BANNER_MEDIA_FAILURE';

export const editBannerMedia = ({ formData, id }: { formData: BannerMediaType; id: number }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/banner-media/${id}`,
    types: [
      EDIT_BANNER_MEDIA_REQUEST,
      {
        type: EDIT_BANNER_MEDIA_SUCCESS,
        meta: { notification: { type: 'success', message: 'Banner Media successfully updated!' } }
      },
      EDIT_BANNER_MEDIA_FAILURE
    ],
    body: bannerRequestToApi(formData)
  });

export const DELETE_BANNER_MEDIA_MEDIA_REQUEST = 'DELETE_BANNER_MEDIA_MEDIA_REQUEST';
export const DELETE_BANNER_MEDIA_MEDIA_SUCCESS = 'DELETE_BANNER_MEDIA_MEDIA_SUCCESS';
export const DELETE_BANNER_MEDIA_MEDIA_FAILURE = 'DELETE_BANNER_MEDIA_MEDIA_FAILURE';

export const deleteBannerMedia = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/banner-media/${id}`,
    types: [
      DELETE_BANNER_MEDIA_MEDIA_REQUEST,
      DELETE_BANNER_MEDIA_MEDIA_SUCCESS,
      DELETE_BANNER_MEDIA_MEDIA_FAILURE
    ]
  });
