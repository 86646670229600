import {
  GET_TOKEN_REQUEST,
  // GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE
} from 'redux/actions/auth';

import {
  // GET_ME_REQUEST,
  GET_ME_SUCCESS,
  GET_ME_FAILURE
} from 'redux/actions/users';

const INITIAL_STATE = {
  loading: false
};

export default function(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case GET_TOKEN_REQUEST:
      return { ...state, loading: true };
    case GET_TOKEN_FAILURE:
    case GET_ME_SUCCESS:
    case GET_ME_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
