import { CreateLoyaltyTermType, EditLoyaltyTermType, LoyaltyTermType } from 'types/term';
import { CallApi } from '@factorymarket/mg-react-components';
import { filterToApi } from './adapter';
import { uiFilterType } from './types';

const { callApiAction } = CallApi;

export const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

export const getTerms = ({ filter }: { filter: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/terms?json=${JSON.stringify(filterToApi(filter))}`,
    types: [GET_TERMS_REQUEST, GET_TERMS_SUCCESS, GET_TERMS_FAILURE]
  });

export const ADD_TERM_REQUEST = 'ADD_TERM_REQUEST';
export const ADD_TERM_SUCCESS = 'ADD_TERM_SUCCESS';
export const ADD_TERM_FAILURE = 'ADD_TERM_FAILURE';

export const addTerm = ({ formData }: { formData: CreateLoyaltyTermType }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/loyalty/terms`,
    types: [
      ADD_TERM_REQUEST,
      {
        type: ADD_TERM_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Term successfully created!' }
        }
      },
      ADD_TERM_FAILURE
    ],
    body: formData
  });

export const GET_TERM_REQUEST = 'GET_TERM_REQUEST';
export const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS';
export const GET_TERM_FAILURE = 'GET_TERM_FAILURE';

export const getTerm = ({ id }: { id: number | string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/loyalty/terms/${id}`,
    types: [GET_TERM_REQUEST, GET_TERM_SUCCESS, GET_TERM_FAILURE]
  });

export const EDIT_TERM_REQUEST = 'EDIT_TERM_REQUEST';
export const EDIT_TERM_SUCCESS = 'EDIT_TERM_SUCCESS';
export const EDIT_TERM_FAILURE = 'EDIT_TERM_FAILURE';

export const editTerm = ({ id, formData }: { id: number; formData: EditLoyaltyTermType }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/loyalty/terms/${id}`,
    types: [
      EDIT_TERM_REQUEST,
      {
        type: EDIT_TERM_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Term successfully updated!' }
        }
      },
      EDIT_TERM_FAILURE
    ],
    body: formData
  });

export const DELETE_TERM_REQUEST = 'DELETE_TERM_REQUEST';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
export const DELETE_TERM_FAILURE = 'DELETE_TERM_FAILURE';

export const deleteTerm = ({ id, filter }: { id: number; filter: uiFilterType }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/loyalty/terms/${id}`,
    types: [
      DELETE_TERM_REQUEST,
      {
        type: DELETE_TERM_SUCCESS,
        meta: {
          filter,
          notification: { type: 'success', message: 'Term successfully deleted!' }
        }
      },
      DELETE_TERM_FAILURE
    ]
  });
