import { take, put, race } from 'redux-saga/effects';
import {
  getReviews,
  EDIT_REVIEW_SUCCESS,
  EDIT_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS
} from './actions';

export function* productsReviewsSaga() {
  while (true) {
    const { editRequest, deleteSuccess } = yield race({
      editRequest: take(EDIT_REVIEW_REQUEST),
      deleteSuccess: take(DELETE_REVIEW_SUCCESS)
    });
    if (editRequest) {
      yield take(EDIT_REVIEW_SUCCESS);
      yield put(getReviews({ filter: editRequest.meta.filter }));
    }
    if (deleteSuccess) {
      yield put(getReviews({ filter: deleteSuccess.meta.filter }));
    }
  }
}

export default { productsReviewsSaga };
