import { AppSettingEditType } from 'types/settings';
import { CallApi } from '@factorymarket/mg-react-components';

const { callApiAction } = CallApi;

export const GET_APP_SETTINGS_REQUEST = 'GET_APP_SETTINGS_REQUEST';
export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
export const GET_APP_SETTINGS_FAILURE = 'GET_APP_SETTINGS_FAILURE';

export const getAppSettings = () =>
  callApiAction({
    method: 'get',
    url: `/employee-area/app-settings/list`,
    types: [GET_APP_SETTINGS_REQUEST, GET_APP_SETTINGS_SUCCESS, GET_APP_SETTINGS_FAILURE]
  });

export const EDIT_APP_SETTINGS_REQUEST = 'EDIT_APP_SETTINGS_REQUEST';
export const EDIT_APP_SETTINGS_SUCCESS = 'EDIT_APP_SETTINGS_SUCCESS';
export const EDIT_APP_SETTINGS_FAILURE = 'EDIT_APP_SETTINGS_FAILURE';

export const editAppSettings = ({
  sectionId,
  key,
  formData
}: {
  sectionId: string;
  key: string;
  formData: AppSettingEditType;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/app-settings/${sectionId}/${key}`,
    types: [
      EDIT_APP_SETTINGS_REQUEST,
      {
        type: EDIT_APP_SETTINGS_SUCCESS,
        meta: {
          notification: { type: 'success', message: 'Setting successfully updated!' }
        }
      },
      EDIT_APP_SETTINGS_FAILURE
    ],
    body: formData
  });
