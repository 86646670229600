import { take, put } from 'redux-saga/effects';
import { getStores, EDIT_STORE_SUCCESS, EDIT_STORE_REQUEST } from './actions';

export function* storesSaga() {
  while (true) {
    const editRequest = yield take(EDIT_STORE_REQUEST);
    yield take(EDIT_STORE_SUCCESS);
    yield put(getStores({ filter: editRequest.meta.filter }));
  }
}

export default storesSaga;
