import { CurrentStoreType } from 'types/store';
import { ImageDataType } from 'types/upload';
import { CallApi } from '@factorymarket/mg-react-components';
import { uiFilterType } from './types';
import { filterToApi } from './adapter';

const { callApiAction } = CallApi;

export const GET_STORES_REQUEST = 'GET_STORES_REQUEST';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';

export const getStores = ({ filter }: { filter: uiFilterType }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/shop/list?json=${JSON.stringify({ ...filterToApi(filter) })}`,
    types: [GET_STORES_REQUEST, GET_STORES_SUCCESS, GET_STORES_FAILURE]
  });

export const GET_STORE_REQUEST = 'GET_STORE_REQUEST';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';

export const getStore = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/shop/${id}`,
    types: [GET_STORE_REQUEST, GET_STORE_SUCCESS, GET_STORE_FAILURE]
  });

export const EDIT_STORE_REQUEST = 'EDIT_STORE_REQUEST';
export const EDIT_STORE_SUCCESS = 'EDIT_STORE_SUCCESS';
export const EDIT_STORE_FAILURE = 'EDIT_STORE_FAILURE';

export const editStore = ({
  id,
  formData,
  filter
}: {
  id: string;
  formData: CurrentStoreType;
  filter?: object;
}) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/shop/${id}`,
    types: [
      {
        type: EDIT_STORE_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: EDIT_STORE_SUCCESS,
        meta: { notification: { type: 'success', message: 'Store successfully updated!' } }
      },
      EDIT_STORE_FAILURE
    ],
    body: formData
  });

export const ADD_STORE_REQUEST = 'ADD_STORE_REQUEST';
export const ADD_STORE_SUCCESS = 'ADD_STORE_SUCCESS';
export const ADD_STORE_FAILURE = 'ADD_STORE_FAILURE';

export const addStore = ({ formData, filter }: { formData: CurrentStoreType; filter?: object }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/shop`,
    types: [
      {
        type: ADD_STORE_REQUEST,
        meta: {
          filter
        }
      },
      {
        type: ADD_STORE_SUCCESS,
        meta: { notification: { type: 'success', message: 'Store successfully added!' } }
      },
      ADD_STORE_FAILURE
    ],
    body: formData
  });

// IMAGES

export const UPLOAD_IMAGE_FOR_ADD_STORE_FORM_SUCCESS = 'UPLOAD_IMAGE_FOR_ADD_STORE_FORM_SUCCESS';
export const SET_MAIN_IMAGE_FOR_ADD_STORE_FORM_SUCCESS =
  'SET_MAIN_IMAGE_FOR_ADD_STORE_FORM_SUCCESS';

export const uploadImageForAddStoreForm = ({
  upload,
  main = false
}: {
  upload: ImageDataType;
  main: boolean;
}) => ({
  type: main ? SET_MAIN_IMAGE_FOR_ADD_STORE_FORM_SUCCESS : UPLOAD_IMAGE_FOR_ADD_STORE_FORM_SUCCESS,
  payload: upload
});

export const ADD_IMAGE_TO_STORE_REQUEST = 'ADD_IMAGE_TO_STORE_REQUEST';
export const ADD_IMAGE_TO_STORE_SUCCESS = 'ADD_IMAGE_TO_STORE_SUCCESS';
export const ADD_IMAGE_TO_STORE_FAILURE = 'ADD_IMAGE_TO_STORE_FAILURE';

export const addImageToStore = ({ formData, id }: { formData: any; id: number }) =>
  callApiAction({
    method: 'post',
    url: `/employee-area/shop/${id}/image`,
    types: [
      ADD_IMAGE_TO_STORE_REQUEST,
      {
        type: ADD_IMAGE_TO_STORE_SUCCESS,
        meta: {
          // notification: { type: 'success', message: 'Image successfully added!' },
          uploadId: formData.uploadId
        }
      },
      {
        type: ADD_IMAGE_TO_STORE_FAILURE,
        meta: {
          uploadId: formData.uploadId
        }
      }
    ],
    body: formData
  });

export const GET_STORE_IMAGES_REQUEST = 'GET_STORE_IMAGES_REQUEST';
export const GET_STORE_IMAGES_SUCCESS = 'GET_STORE_IMAGES_SUCCESS';
export const GET_STORE_IMAGES_FAILURE = 'GET_STORE_IMAGES_FAILURE';

export const getStoreImages = ({ id }: { id?: string }) =>
  callApiAction({
    method: 'get',
    url: `/employee-area/shop/${id}/image`,
    types: [GET_STORE_IMAGES_REQUEST, GET_STORE_IMAGES_SUCCESS, GET_STORE_IMAGES_FAILURE]
  });

export const OPEN_VARIANT_IMAGES = 'OPEN_VARIANT_IMAGES';

export const openVariantImages = (id: number) => ({
  type: OPEN_VARIANT_IMAGES,
  payload: id
});

export const EDIT_STORE_IMAGE_REQUEST = 'EDIT_STORE_IMAGE_REQUEST';
export const EDIT_STORE_IMAGE_SUCCESS = 'EDIT_STORE_IMAGE_SUCCESS';
export const EDIT_STORE_IMAGE_FAILURE = 'EDIT_STORE_IMAGE_FAILURE';

export const editStoreImage = ({ formData, id }: { formData: any; id: number }) =>
  callApiAction({
    method: 'put',
    url: `/employee-area/shop-image/${id}`,
    types: [
      EDIT_STORE_IMAGE_REQUEST,
      {
        type: EDIT_STORE_IMAGE_SUCCESS
        // meta: { notification: { type: 'success', message: 'Image successfully updated!' } }
      },
      EDIT_STORE_IMAGE_FAILURE
    ],
    body: formData
  });

export const DELETE_STORE_IMAGE_REQUEST = 'DELETE_STORE_IMAGE_REQUEST';
export const DELETE_STORE_IMAGE_SUCCESS = 'DELETE_STORE_IMAGE_SUCCESS';
export const DELETE_STORE_IMAGE_FAILURE = 'DELETE_STORE_IMAGE_FAILURE';

export const deleteStoreImage = ({ id }: { id: number }) =>
  callApiAction({
    method: 'delete',
    url: `/employee-area/shop-image/${id}`,
    types: [
      DELETE_STORE_IMAGE_REQUEST,
      {
        type: DELETE_STORE_IMAGE_SUCCESS,
        meta: { notification: { type: 'success', message: 'Image successfully deleted!' } }
      },
      DELETE_STORE_IMAGE_FAILURE
    ]
  });
export const SET_SELECTED_STORES_COLLECTION = 'SET_SELECTED_STORES_COLLECTION';

export const setSelectedStoresCollection = (collection: number[]) => ({
  type: SET_SELECTED_STORES_COLLECTION,
  payload: collection
});
