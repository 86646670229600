import { take, put } from 'redux-saga/effects';
import { getDashboards, GET_DASHBOARDS_SUCCESS } from './actions';

export function* dashboardSaga() {
  yield take('@@router/LOCATION_CHANGE');
  yield put(getDashboards());
  yield take(GET_DASHBOARDS_SUCCESS);
}

export default { dashboardSaga };
