import Container from './container';
import universalPageReducer from './reducer';
import universalPageSaga from './saga';

export const UniversalPageContainer = {
  Container,
  Saga: universalPageSaga,
  Reducer: universalPageReducer
};

// you can optionally also set a default export for your module
export default UniversalPageContainer;
