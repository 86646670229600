import { take, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { ADD_CATEGORY_SUCCESS } from '../actions';

export function* categoryAddSaga() {
  yield take(ADD_CATEGORY_SUCCESS);
  yield put(push('/categories/list'));
}

export default {
  categoryAddSaga
};
