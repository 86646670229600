import { GET_UNIVERSAL_PAGE_DATA_SUCCESS } from './actions';

export interface universalPageContainerReducerState {
  [key: string]: Object;
}

export const INITIAL_STATE: universalPageContainerReducerState = {};

export default function(state: universalPageContainerReducerState = INITIAL_STATE, action: any) {
  const updatedState = { ...state };
  switch (action.type) {
    case GET_UNIVERSAL_PAGE_DATA_SUCCESS:
      updatedState[action.meta.resource.key] = action.payload.content;
      return updatedState;
    default:
      return state;
  }
}
